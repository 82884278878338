import React, { useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import * as BiIcons from "react-icons/bi";
import "./table.css";
import GlobalPrintAndExport from "./GlobalPrintAndExport";
export const MySearch = ({ onSearch, searchStatus, statusList }) => {
  let input;
  let status;

  useEffect(() => {
    onSearch("");
  }, [statusList]);

  return (
    <div className="flex flex-row gap-2 ">
      <div className="flex flex-row w-full ">
        <div className="flex items-center border-y border-l border-neutralgray bg-white relative pl-2 rounded-l-md">
          <BiIcons.BiSearch className="text-neutralgray" />
        </div>
        <input
          id="tableSearch"
          className="border-y w-full border-r border-neutralgray rounded-y-md rounded-r-md py-1 px-1.5 placeholder:text-darkgray placeholder:text-xs placeholder:font-normal focus:outline-none"
          placeholder="Search.."
          ref={(n) => (input = n)}
          type="text"
          onChange={() => onSearch(input.value)}
        />
      </div>

      {searchStatus && (
        <select
          ref={(n) => (status = n)}
          onChange={() => onSearch(status.value)}
          className="border-neutralgray rounded-md border text-xs px-2 focus:outline-none cursor-pointer capitalize text-darkgray"
        >
          <option value="">Filter by status</option>
          {statusList?.map(({ label, value }) => (
            <option value={value} key={value} className=" text-customgray">
              {label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export const DataTable = (props) => {
  const {
    rows,
    columns,
    btnName,
    title,
    setOpenModal,
    setModalType,
    csvtitle,
    hideSearchInputs,
    TabPanel,
    parentTitle,
    sizePerPage,
    showPrintAndExport,
    ...rest
  } = props;

  const copyProps = { ...props };

  return (
    <ToolkitProvider
      keyField="dataField"
      data={rows}
      columns={columns}
      exportCSV={{
        fileName: csvtitle,
      }}
      search
    >
      {(props) => (
        <div className="">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row px-3 md:p-0 items-center justify-between">
              <div className="flex flex-col">
                <div className="md:hidden text-lg text-angularlightblue">
                  {parentTitle}
                </div>
                <span className="text-neutraldarkgray font-normal text-sm md:text-base">
                  {title}
                </span>
              </div>
              {showPrintAndExport && <GlobalPrintAndExport props={copyProps} />}
            </div>

            <div className="bg-white rounded-xl shadow-xl">
              {TabPanel && <TabPanel />}
              <div className="flex flex-col md:flex-row items-center gap-2 py-4 px-8 justify-between">
                {!hideSearchInputs && (
                  <div className="w-full md:w-fit">
                    <MySearch {...props.searchProps} {...rest} />
                  </div>
                )}
                {btnName && (
                  <button
                    className="flex flex-row h-8 min-w-full md:min-w-fit justify-center items-center bg-gradient-to-t from-[#D6504E] to-[#FBA24B] rounded-lg py-1 px-3 gap-2 cursor-pointer"
                    onClick={() => {
                      setOpenModal(true);
                      setModalType("Add");
                    }}
                  >
                    <span className="text-xs font-normal text-white">
                      Add {btnName}
                    </span>
                  </button>
                )}
              </div>

              <div className="p-4 md:mx-4">
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory({
                    page: 1,
                    sizePerPage: sizePerPage ?? 8,
                    alwaysShowAllBtns: true,
                    showTotal: true,
                    withFirstAndLast: false,
                    sizePerPageRenderer: () => (
                      <div
                        className="dataTables_length"
                        id="datatable-basic_length"
                      ></div>
                    ),
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};
