import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { alertModalStore } from "../../Zustand/AlertModalStore/alertModalStore";
import shallow from "zustand/shallow";

export const ShowSuccessAlert = () => {
  const { successModal, closeSuccessModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const { isOpen, title, message, closeNameBtn } = successModal;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative z-20">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-sm transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row items-center gap-2 py-3 mx-6 border-b border-successgreen"
                >
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.35424 2.57436C3.9237 2.73424 2.77864 3.8633 2.61312 5.27844C2.16851 9.07987 2.16851 12.9202 2.61312 16.7216C2.77864 18.1367 3.9237 19.2658 5.35424 19.4257C9.07527 19.8416 12.9248 19.8416 16.6458 19.4257C18.0764 19.2658 19.2214 18.1367 19.387 16.7216C19.7064 13.9902 19.7963 11.2387 19.6567 8.49594C19.6531 8.42511 19.6796 8.35603 19.7297 8.30588L21.0282 7.00741C21.1785 6.85711 21.4353 6.95109 21.4512 7.16304C21.6963 10.4192 21.629 13.693 21.2493 16.9394C20.9807 19.2356 19.1371 21.0339 16.8541 21.2891C12.9947 21.7204 9.00542 21.7204 5.14598 21.2891C2.863 21.0339 1.01938 19.2356 0.750817 16.9394C0.289279 12.9933 0.289279 9.00678 0.750817 5.06062C1.01938 2.76442 2.863 0.966119 5.14598 0.710963C9.00542 0.279614 12.9947 0.279614 16.8541 0.710963C17.6582 0.80083 18.4077 1.08212 19.0532 1.50916C19.1802 1.59319 19.1928 1.77174 19.0851 1.87942L18.0815 2.88303C17.9994 2.96512 17.8721 2.97893 17.7698 2.9241C17.428 2.74098 17.0482 2.61933 16.6458 2.57436C12.9248 2.15848 9.07527 2.15848 5.35424 2.57436Z"
                      fill="#72BB71"
                    />
                    <path
                      d="M22.2879 3.53794C22.6541 3.17182 22.6541 2.57823 22.2879 2.21211C21.9218 1.846 21.3282 1.846 20.9621 2.21211L10.375 12.7992L7.28794 9.71211C6.92182 9.346 6.32823 9.346 5.96211 9.71211C5.59599 10.0782 5.59599 10.6718 5.96211 11.0379L9.71211 14.7879C10.0782 15.1541 10.6718 15.1541 11.0379 14.7879L22.2879 3.53794Z"
                      fill="#72BB71"
                    />
                  </svg>
                  <span className="text-successgreen font-normal">{title}</span>
                </div>
                <div className="p-6">
                  <span className="text-neutralblack font-normal text-sm">
                    {message}
                  </span>
                </div>
                <div className="bg-neutralwhite px-6 py-4 text-end">
                  <span
                    className="text-creamwhite bg-successgreen text-sm font-normal rounded-lg py-2 px-10 cursor-pointer"
                    onClick={() => closeSuccessModal(successModal)}
                  >
                    {closeNameBtn}
                  </span>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const ShowWarningAlert = () => {
  const { confirmModal, closeConfirmModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const {
    isOpen,
    title,
    message,
    modalAction,
    confirmNameBtn,
    closeNameBtn,
    content,
  } = confirmModal;
  const closeModal = () => {
    closeConfirmModal(confirmModal);
  };
  const submitModal = () => {
    modalAction();
    closeConfirmModal(confirmModal);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative z-20">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row items-center gap-2 py-3 mx-6 border-b border-warningyellow"
                >
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.363 10.25C10.6569 9.41459 11.2369 8.71013 12.0004 8.26142C12.7639 7.8127 13.6616 7.64867 14.5345 7.79839C15.4073 7.94811 16.199 8.40191 16.7693 9.07941C17.3397 9.75692 17.6518 10.6144 17.6505 11.5C17.6505 14 13.9005 15.25 13.9005 15.25M14.0005 20.25H14.013M26.5005 14C26.5005 20.9036 20.9041 26.5 14.0005 26.5C7.09693 26.5 1.50049 20.9036 1.50049 14C1.50049 7.09644 7.09693 1.5 14.0005 1.5C20.9041 1.5 26.5005 7.09644 26.5005 14Z"
                      stroke="#FFCE51"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="text-warningyellow font-normal text-base leading-[-0.02em]">
                    {title}
                  </span>
                </div>
                <div className="p-6">
                  <span className="text-neutralblack font-normal text-sm">
                    {message}
                  </span>
                  <p className="font-normal text-sm">{content}</p>
                </div>
                <div className="flex flex-row justify-end items-center gap-2 px-3 py-4 bg-neutralwhite cursor-pointer">
                  <span
                    className="text-neutraldarkgray text-sm font-normal bg-lightishgray text-center px-4"
                    onClick={() => closeModal()}
                  >
                    {closeNameBtn}
                  </span>
                  <span
                    className="text-neutralblack text-sm font-normal bg-warningyellow text-center rounded-lg py-2 px-4"
                    onClick={submitModal}
                  >
                    {confirmNameBtn}
                  </span>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const ShowDiscardAlert = () => {
  const { discardModal, closeDiscardModal, openDiscardModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const {
    isOpen,
    title,
    message,
    modalAction,
    confirmNameBtn,
    closeNameBtn,
    content,
  } = discardModal;
  const closeModal = () => {
    openDiscardModal({ ...discardModal, isOpen: false });
  };
  const submitModal = () => {
    modalAction();
    closeDiscardModal(discardModal);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative z-20">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row items-center gap-2 py-3 mx-6 border-b border-warningyellow"
                >
                  <svg
                    width="28"
                    height="26"
                    className="h-6 w-6"
                    viewBox="0 0 28 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0003 19.2499H14.0128M14.0003 10.4999V15.4999M7.01539 24.2499H20.9852C23.2123 24.2499 24.3258 24.2499 24.979 23.7816C25.5489 23.3729 25.9209 22.7436 26.0044 22.0473C26.1 21.2493 25.5634 20.2736 24.4901 18.3222L17.5052 5.62238C16.3624 3.54469 15.7911 2.50584 15.0361 2.16216C14.378 1.86261 13.6226 1.86261 12.9645 2.16216C12.2095 2.50584 11.6382 3.54469 10.4954 5.62238L3.51053 18.3222C2.43726 20.2736 1.90062 21.2493 1.99624 22.0473C2.07967 22.7436 2.45173 23.3729 3.02164 23.7816C3.67478 24.2499 4.78832 24.2499 7.01539 24.2499Z"
                      stroke="#FFCE51"
                      stroke-width="2.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="text-warningyellow font-normal text-base leading-[-0.02em]">
                    {title}
                  </span>
                </div>
                <div className="p-6">
                  <span className="text-neutralblack font-normal text-sm">
                    {message}
                  </span>
                  <p className="font-normal text-sm">{content}</p>
                </div>
                <div className="flex flex-row justify-end items-center gap-2 px-3 py-4 bg-neutralwhite cursor-pointer">
                  <span
                    className="text-neutraldarkgray text-sm font-normal bg-lightishgray text-center px-4"
                    onClick={() => closeModal()}
                  >
                    {closeNameBtn}
                  </span>
                  <span
                    className="text-neutralblack text-sm font-normal bg-warningyellow text-center rounded-lg py-2 px-4"
                    onClick={submitModal}
                  >
                    {confirmNameBtn}
                  </span>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const ShowArchiveAlert = () => {
  const { deleteModal, closeDeleteModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const {
    isOpen,
    title,
    message,
    modalAction,
    confirmNameBtn,
    closeNameBtn,
    isDelete,
  } = deleteModal;

  const closeModal = () => {
    closeDeleteModal(deleteModal);
  };
  const submitModal = () => {
    modalAction();
    closeDeleteModal(deleteModal);
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative z-20">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row items-center gap-2 py-3 mx-6 border-b border-archivered"
                >
                  {isDelete ? (
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.499 2.8125C11.9813 2.8125 11.5615 3.23223 11.5615 3.75V4.6875H6.24902C5.73126 4.6875 5.31152 5.10723 5.31152 5.625C5.31152 6.14277 5.73126 6.5625 6.24902 6.5625H23.749C24.2668 6.5625 24.6865 6.14277 24.6865 5.625C24.6865 5.10723 24.2668 4.6875 23.749 4.6875H18.4365V3.75C18.4365 3.23223 18.0168 2.8125 17.499 2.8125H12.499Z"
                        fill="#F45E5E"
                      />
                      <path
                        d="M12.499 13.3125C13.0168 13.3125 13.4365 13.7322 13.4365 14.25L13.4365 23C13.4365 23.5178 13.0168 23.9375 12.499 23.9375C11.9813 23.9375 11.5615 23.5178 11.5615 23L11.5615 14.25C11.5615 13.7322 11.9813 13.3125 12.499 13.3125Z"
                        fill="#F45E5E"
                      />
                      <path
                        d="M18.4365 14.25C18.4365 13.7322 18.0168 13.3125 17.499 13.3125C16.9813 13.3125 16.5615 13.7322 16.5615 14.25V23C16.5615 23.5178 16.9813 23.9375 17.499 23.9375C18.0168 23.9375 18.4365 23.5178 18.4365 23V14.25Z"
                        fill="#F45E5E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.4883 9.89647C7.54106 9.42169 7.94237 9.0625 8.42007 9.0625H21.578C22.0557 9.0625 22.457 9.42169 22.5097 9.89647L22.7599 12.1482C23.2134 16.2298 23.2134 20.3491 22.7599 24.4308L22.7353 24.6525C22.5553 26.2727 21.2996 27.5646 19.6852 27.7906C16.5762 28.2258 13.4218 28.2258 10.3129 27.7906C8.69845 27.5646 7.44277 26.2727 7.26275 24.6525L7.23812 24.4308C6.7846 20.3491 6.7846 16.2298 7.23812 12.1482L7.4883 9.89647ZM9.25917 10.9375L9.10165 12.3552C8.66342 16.2992 8.66342 20.2797 9.10165 24.2237L9.12628 24.4454C9.21166 25.2138 9.80718 25.8265 10.5728 25.9337C13.5093 26.3448 16.4887 26.3448 19.4252 25.9337C20.1909 25.8265 20.7864 25.2138 20.8718 24.4454L20.8964 24.2237C21.3346 20.2797 21.3346 16.2992 20.8964 12.3552L20.7389 10.9375H9.25917Z"
                        fill="#F45E5E"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="23"
                      path
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.9368 10.75C16.9368 11.2678 16.517 11.6875 15.9993 11.6875H5.99927C5.4815 11.6875 5.06177 11.2678 5.06177 10.75C5.06177 10.2323 5.4815 9.81253 5.99927 9.81253H15.9993C16.517 9.81253 16.9368 10.2323 16.9368 10.75Z"
                        fill="#F45E5E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.48125 7.1834L2.96716 3.12391C3.34163 2.10085 4.24986 1.36777 5.3289 1.21761C9.09104 0.694067 12.9076 0.694066 16.6697 1.21761C17.7488 1.36777 18.657 2.10086 19.0315 3.12393L20.5175 7.18379C20.7954 7.61739 20.9868 8.11306 21.0672 8.6474L21.1946 9.49471C21.6943 12.818 21.6476 16.2006 21.0563 19.5088C20.7941 20.9755 19.5897 22.0895 18.1071 22.2366L16.569 22.3893C12.8649 22.757 9.13355 22.757 5.42948 22.3893L3.8914 22.2366C2.4088 22.0895 1.20435 20.9755 0.942195 19.5088C0.350856 16.2006 0.304133 12.818 0.803875 9.49471L0.931289 8.6474C1.01167 8.1129 1.20319 7.6171 1.48125 7.1834ZM5.58734 3.07471C9.17802 2.57503 12.8206 2.57503 16.4113 3.07471C16.8039 3.12935 17.1344 3.39612 17.2707 3.76841L17.9035 5.49714C17.8468 5.48888 17.7897 5.48189 17.7322 5.47619L16.5095 5.35482C12.845 4.99111 9.15349 4.99111 5.489 5.35482L4.26626 5.47619C4.20884 5.48189 4.15179 5.48887 4.09514 5.49712L4.72791 3.76841C4.86418 3.39612 5.19468 3.12935 5.58734 3.07471ZM3.10634 8.12626L3.12966 8.13479L3.1586 8.05573C3.46578 7.66091 3.92718 7.39405 4.45145 7.34202L5.67419 7.22066C9.21552 6.86917 12.7829 6.86917 16.3243 7.22066L17.547 7.34202C18.0714 7.39406 18.5329 7.66102 18.8401 8.05598L18.8689 8.13479L18.8921 8.12628C19.056 8.35865 19.1686 8.63058 19.213 8.92622L19.3404 9.77353C19.8098 12.8948 19.7659 16.0718 19.2105 19.1789C19.096 19.8197 18.5697 20.3065 17.9219 20.3708L16.3838 20.5235C12.8029 20.8789 9.19558 20.8789 5.61467 20.5235L4.07659 20.3708C3.42877 20.3065 2.90249 19.8197 2.78794 19.1789C2.23255 16.0718 2.18867 12.8948 2.65803 9.77353L2.78544 8.92622C2.8299 8.63057 2.94243 8.35863 3.10634 8.12626Z"
                        fill="#F45E5E"
                      />
                    </svg>
                  )}

                  <span className="text-archivered font-normal text-base leading-[-0.02em]">
                    {title}
                  </span>
                </div>
                <div className="p-6">
                  <span className="text-neutralblack font-normal text-sm">
                    {message}
                  </span>
                </div>
                <div className="flex flex-row justify-end items-center gap-2 px-3 py-4 bg-neutralwhite cursor-pointer">
                  <span
                    className="text-neutraldarkgray text-sm font-normal bg-lightishgray text-center px-4"
                    onClick={() => closeModal()}
                  >
                    {closeNameBtn}
                  </span>
                  <span
                    id="proceedAction"
                    className="text-creamwhite text-sm font-normal bg-archivered text-center rounded-lg py-2 px-4"
                    onClick={() => {
                      submitModal();
                    }}
                  >
                    {confirmNameBtn}
                  </span>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const ShowResetAlert = () => {
  const { resetModal, closeResetModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const { isOpen, title, message, modalAction, confirmNameBtn, closeNameBtn } =
    resetModal;
  const closeModal = () => {
    closeResetModal(resetModal);
  };
  const submitModal = () => {
    modalAction();
    closeResetModal(resetModal);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="relative z-20">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row items-center gap-2 py-3 mx-6 border-b border-archivered"
                >
                  <svg
                    className="h-6 w-6"
                    viewBox="0 0 20 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.12566 18C8.12566 16.9645 8.96513 16.125 10.0007 16.125C11.0362 16.125 11.8757 16.9645 11.8757 18C11.8757 19.0355 11.0362 19.875 10.0007 19.875C8.96513 19.875 8.12566 19.0355 8.12566 18Z"
                      fill="#F45E5E"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.26377 3.00624C7.55422 2.8906 7.85723 2.80941 8.16658 2.76433C10.0082 2.496 11.7915 3.53329 12.4687 5.26672L12.5625 5.50674C12.6787 5.80416 12.7616 6.11353 12.8097 6.4292L13.0805 8.20709L14.9341 7.92478L14.6633 6.14689C14.5944 5.69434 14.4755 5.25081 14.3089 4.82442L14.2152 4.5844C13.2205 2.03836 10.6011 0.514801 7.89623 0.908928C7.44185 0.975134 6.99681 1.09438 6.5702 1.26424C4.03063 2.27536 2.524 4.90446 2.93557 7.60675L2.97437 7.86151C3.0433 8.31407 3.16214 8.75759 3.32873 9.18398L4.14641 11.2769L3.66956 11.315C2.31616 11.423 1.21102 12.4404 0.99163 13.7803C0.53404 16.5748 0.53404 19.4252 0.99163 22.2198C1.21102 23.5596 2.31616 24.577 3.66956 24.6851L5.53973 24.8344C8.50896 25.0714 11.4924 25.0714 14.4616 24.8344L16.3318 24.6851C17.6852 24.577 18.7903 23.5596 19.0097 22.2198C19.4673 19.4252 19.4673 16.5748 19.0097 13.7803C18.7903 12.4404 17.6852 11.423 16.3318 11.315L14.4616 11.1657C11.6789 10.9435 8.88383 10.9296 6.09961 11.1238L5.07517 8.50166C4.95897 8.20424 4.87607 7.89487 4.828 7.5792L4.7892 7.32444C4.50899 5.48463 5.53475 3.69465 7.26377 3.00624ZM14.3124 13.0347C11.4425 12.8056 8.55885 12.8056 5.68894 13.0347L3.81877 13.184C3.32511 13.2234 2.92201 13.5945 2.84199 14.0832C2.41725 16.6771 2.41725 19.3229 2.84199 21.9168C2.92201 22.4055 3.32511 22.7766 3.81878 22.816L5.68894 22.9653C8.55885 23.1944 11.4425 23.1944 14.3124 22.9653L16.1825 22.816C16.6762 22.7766 17.0793 22.4055 17.1593 21.9168C17.5841 19.3229 17.5841 16.6771 17.1593 14.0832C17.0793 13.5945 16.6762 13.2234 16.1825 13.184L14.3124 13.0347Z"
                      fill="#F45E5E"
                    />
                  </svg>
                  <span className="text-archivered font-normal text-base leading-[-0.02em]">
                    {title}
                  </span>
                </div>
                <div className="p-6">
                  <span className="text-neutralblack font-normal text-sm">
                    {message}
                  </span>
                </div>
                <div className="flex flex-row justify-end items-center gap-2 px-3 py-4 bg-neutralwhite cursor-pointer">
                  <span
                    className="text-neutraldarkgray text-sm font-normal bg-lightishgray text-center px-4"
                    onClick={() => closeModal()}
                  >
                    {closeNameBtn}
                  </span>
                  <span
                    id="alertProceed"
                    className="text-creamwhite text-sm font-normal bg-archivered text-center rounded-lg py-2 px-4"
                    onClick={() => submitModal()}
                  >
                    {confirmNameBtn}
                  </span>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};
