import create from "zustand";
import { routesGetApi } from "Api/api";

const accountObjectDetails = {
  userInfomation: [],
};

const storeAcc = async (set) => {
  await routesGetApi("/users/info").then((res) => {
    if (res.status === 200) {
      return set({
        userInfomation: res.data,
      });
    }
  });
};

const getUserInfo = (data, set) => {
  return set({ userInfomation: data });
};

const accountLoginStoreObject = (set) => ({
  ...accountObjectDetails,
  clearSession: () => set({}, true),
  storeAccDetails: () => storeAcc(set),
  storeUserInfo: (data) => getUserInfo(data, set),
});

export const accountLoginDetailsStore = create(accountLoginStoreObject);
