import * as S3 from "aws-sdk/clients/s3";

const accessKeyId = "AKIA47P2MEWE6PS6WJM4";
const secretAccessKey = "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";

export const bucket = new S3({
  accessKeyId,
  secretAccessKey,
  region: "ap-southeast-1",
});
