import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const settingsObjectDetails = {
  settings: [],
};

const apiRequest = async (set) => {
  return await routesGetApi("/settings").then((res) => {
    if (res.status === 200 || res.status === 201) {
      return set({
        settings: res.data,
      });
    }
  });
};

const storeSettings = async (set) => {
  return apiRequest(set);
};

const storeSettingsTimeout = async (set) => {
  return APIFetch(() => {
    apiRequest(set);
  });
};

const storeAllSettings = async (set, data) => {
  return set({
    settings: data,
  });
};

const settingsStoreObject = (set) => ({
  ...settingsObjectDetails,
  storeSettingsDetails: () => storeSettings(set),
  fetchSettings: () => storeSettingsTimeout(set),
  getAllSettings: (data) => storeAllSettings(set, data),
});

export const settingsStore = create(settingsStoreObject);
