import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const promoCodeObjectDetails = {
  promoCode: [],
};

const apiRequest = async (set) => {
  await routesGetApi("/promocode").then((res) => {
    if (res.status === 200) {
      return set({
        promoCode: res.data,
      });
    }
  });
};

const storePromoCode = async (set) => {
  return apiRequest(set);
};

const storePromoCodeTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const storeAllPromoCode = async (set, data) => {
  return set({
    promoCode: data,
  });
};

const promoCodeStoreObject = (set) => ({
  ...promoCodeObjectDetails,
  storePromoCodeDetails: () => storePromoCode(set),
  fetchPromoCode: () => storePromoCodeTimeout(set),
  getAllPromoCode: (data) => storeAllPromoCode(set, data),
});

export const promoCodeStore = create(promoCodeStoreObject);
