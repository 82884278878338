export default function FullnameFormat({ props, isMiddleName }) {
  const { firstName, middleName, lastName, suffix } = props ?? {};
  function checkMiddleName() {
    if (middleName !== "" && middleName) {
      if (isMiddleName) return middleName;
      else return middleName?.charAt(0) + ".";
    } else return "";
  }
  const fullName = `${firstName} ${checkMiddleName()} ${lastName} ${
    suffix ?? ""
  }`;

  return <div className="capitalize">{fullName}</div>;
}
