import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const bookingObjectDetails = {
  booking: [],
  onGoingBooking: [],
};

const apiRequest = async (set) => {
  await routesGetApi("/bookings").then((res) => {
    if (res.status === 200) {
      return set({
        booking: res.data,
      });
    }
  });
  await routesGetApi("/bookings/?status=ongoing").then((res) => {
    if (res.status === 200) {
      return set({
        onGoingBooking: res.data,
      });
    }
  });
};

const storeBooking = async (set) => {
  return apiRequest(set);
};

const storeBookingTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const storeAllBooking = async (set, data) => {
  return set({
    booking: data,
  });
};

const storeAllOnGoingBooking = async (set, data) => {
  return set({
    onGoingBooking: data,
  });
};

const bookingStoreObject = (set) => ({
  ...bookingObjectDetails,
  storeBookingDetails: () => storeBooking(set),
  fetchBooking: () => storeBookingTimeout(set),
  getAllBooking: (data) => storeAllBooking(set, data),
  getAllOnGoingBooking: (data) => storeAllOnGoingBooking(set, data),
});

export const bookingStore = create(bookingStoreObject);
