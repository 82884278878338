import React from "react";

function GlobalRequiredField({ fieldType, value, isEmpty, message }) {
  if (
    fieldType === "text" ||
    fieldType === "date" ||
    fieldType === "number" ||
    fieldType === "email"
  ) {
    if (!value || value === "")
      return (
        <span className="text-xs text-archivered">
          {isEmpty && "*Required"} {message && isEmpty ? `(${message})` : null}
        </span>
      );
  } else {
    if (value === "select")
      return (
        <span className="text-xs text-archivered">
          {isEmpty && "*Required"}
        </span>
      );
  }
}

export default GlobalRequiredField;
