import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import defaultProfile from "Assets/Image/defaultProfile.png";
import * as HiIcons from "react-icons/hi";
import * as RiIcons from "react-icons/ri";
import shallow from "zustand/shallow";
import { menuStore } from "../../Zustand/Menu/menuStore";
import { accountLoginDetailsStore } from "../../Zustand/UserInformationStore/accountLoginDetailsStore";
import { alertModalStore } from "../../Zustand/AlertModalStore/alertModalStore";
import { clearAndLogout } from "Utils/ClearLocalStorage";

const NavBar = ({ title }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openConfirmModal } = alertModalStore((state) => state, shallow);
  const { isOpenMenu, storeMenuUpdate } = menuStore((state) => state, shallow);
  const { discardModal, openDiscardModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const { userInfomation } = accountLoginDetailsStore(
    (state) => state,
    shallow
  );

  const { image } = userInfomation ?? {};

  const openAccount = () => {
    const logout = () => {
      return openConfirmModal({
        title: "Manage Account",
        message: "Are you sure you want to logout?",
        confirmNameBtn: "Logout",
        closeNameBtn: "Cancel",
        modalAction: () => clearAndLogout(),
      });
    };

    const checkSettingsChanges = (path) => {
      if (pathname === "/admin/settings" && discardModal?.isChange)
        return openDiscardModal({
          ...discardModal,
          isOpen: true,
          modalAction: async () => {
            path ? navigate(path) : clearAndLogout();
          },
        });
      else return path ? navigate(path) : logout();
    };

    const menuList = [
      {
        _id: 1,
        onClickFunc: () => checkSettingsChanges("/admin/profile"),
        color: "text-neutralblack hover:text-neutralblack/50",
        icon: <RiIcons.RiUserLine />,
        label: "Profile",
      },
      {
        _id: 2,
        onClickFunc: () => checkSettingsChanges(),
        color: "text-archivered hover:text-archivered/50",
        icon: <RiIcons.RiLogoutBoxLine />,
        label: "Sign out",
      },
    ];

    return (
      <Menu as="div" className="relative inline-block text-left ">
        <div>
          <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HiIcons.HiChevronDown className="-ml-4 cursor-pointer" />
          </Menu.Button>
        </div>
        <Transition appear>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed" />
          </Transition.Child>

          <Menu.Items className="absolute right-0 py-2 px-2 w-[11.5rem] shadow-lg origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
            <div className="flex flex-col ">
              {menuList?.length > 0 &&
                menuList?.map(({ label, color, onClickFunc, icon, _id }) => (
                  <Menu.Item key={_id}>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-2 py-1.5 ${color}`}
                        onClick={onClickFunc}
                      >
                        {icon}
                        <span className=" font-xs">{label}</span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const profileLayout = () => {
    return (
      <>
        <img
          alt="Profile"
          className="w-12 h-12 rounded-full border border-orange"
          src={image ? image : defaultProfile}
        />
        {openAccount()}
      </>
    );
  };

  return (
    <div className="flex flex-row items-center justify-between my-8 mx-3 md:mx-0">
      <div className="text-neutraldarkgray text-2xl font-medium hidden md:flex">
        {title}
      </div>
      <div className="flex md:hidden">
        <button onClick={() => storeMenuUpdate(!isOpenMenu)}>
          <RiIcons.RiMenu2Line size={20} />
        </button>
      </div>
      <div className="flex flex-row items-center gap-6  relative top-0 md:top-auto">
        {profileLayout()}
      </div>
    </div>
  );
};

export default NavBar;
