import React from "react";

export default function StatusStyle(status) {
  const orderStatusChecker = () => {
    switch (status) {
      case "ongoing":
        return (
          <div className="text-white bg-darkblue capitalize w-fit px-2 rounded-full">
            On-going
          </div>
        );

      case "processing":
        return (
          <div className="text-white bg-darkgray/50  capitalize w-fit  px-2 rounded-full">
            Processing
          </div>
        );
      case "arrived":
        return (
          <div className="text-white bg-darkorange capitalize w-fit  px-2 rounded-full">
            arrived
          </div>
        );
      case "pickup":
        return (
          <div className="text-white bg-darkyellow capitalize w-fit  px-2 rounded-full">
            Picked Up
          </div>
        );
      case "delivery":
        return (
          <div className="text-white bg-darkyellow capitalize w-fit  px-2 rounded-full">
            Out for Delivery
          </div>
        );
      case "completed":
        return (
          <div className="text-white bg-darkgreen capitalize w-fit  px-2 rounded-full">
            Completed
          </div>
        );
      case "no-driver":
        return (
          <div className="text-white bg-orange   capitalize w-fit  px-2 rounded-full">
            No-Driver
          </div>
        );
      case "cancelled":
        return (
          <div className="text-white bg-darkred capitalize w-fit  px-2 rounded-full">
            Cancelled
          </div>
        );
      default:
        break;
    }
  };
  return <>{orderStatusChecker()}</>;
}
