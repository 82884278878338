import React, { useEffect, useState } from "react";
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import emptyImage from "Assets/Image/empty.png";
import shallow from "zustand/shallow";
import moment from "moment";

function DriverDocuments() {
  const { documents } = driverStore((state) => state, shallow);

  const [search, setSearch] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  useEffect(() => {
    const searchbar = () => {
      if (documents?.length > 0) {
        const filteredData = documents?.filter(({ file }) =>
          file.toUpperCase().includes(search.toUpperCase())
        );
        setFilteredDocuments(filteredData);
      }
    };

    searchbar();
  }, [search]);
  const documentList = () => {
    return (
      <div className="flex flex-col gap-2 divide-y-2">
        <div className="flex flex-col gap-2 divide-y-2 divide-slate-100">
          {filteredDocuments.length > 0 ? (
            filteredDocuments.map((data) => {
              const { file, fileType, date_added, _id } = data;
              const getFileName = file.split("/");
              return (
                <div
                  className="flex justify-between items-center pt-2"
                  key={_id}
                >
                  <div className="flex flex-col gap-1">
                    <div className="text-sm font-medium">{fileType}</div>
                    <a
                      className="text-[#929292] text-sm flex flex-row gap-1 items-center hover:text-neutralblack"
                      href={file}
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <AiIcons.AiOutlineFilePdf />{" "}
                      <span>{getFileName[getFileName?.length - 1]}</span>
                    </a>
                  </div>
                  <div className="text-sm text-neutraldark text-center">
                    Date Added: {moment(date_added).format("MM/DD/YYYY")}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex flex-col gap-2 justify-center items-center h-[65vh]">
              <img src={emptyImage} alt="empty" className="h-44 w-44" />
              <div className="flex flex-col items-center">
                <div className="text-neutralblack font-medium text-lg">
                  It looks a bit empty here!
                </div>
                <div className="text-neutraldark  text-base">
                  Driver’s documents will appear on this page.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const searchBar = () => {
    return (
      <div className="flex flex-row w-fit">
        <div className="flex items-center border-y border-l border-neutralgray bg-white relative pl-2 rounded-l-md">
          <BiIcons.BiSearch className="text-neutralgray" />
        </div>
        <input
          id="tableSearch"
          className="border-y w-full border-r border-neutralgray rounded-y-md rounded-r-md py-1 px-1.5 placeholder:text-darkgray placeholder:text-xs placeholder:font-normal focus:outline-none"
          placeholder="Search.."
          type="text"
          onChange={({ target }) => setSearch(target.value)}
        />
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-2 p-5">
      {searchBar()}
      {documentList()}
    </div>
  );
}

export default DriverDocuments;
