import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const creditObjectDetails = {
  credit: [],
};

const apiRequest = async (set) => {
  await routesGetApi("/cashin/?role=driver").then((res) => {
    if (res.status === 200) {
      return set({
        credit: res.data,
      });
    }
  });
};

const storeCredit = async (set) => {
  return apiRequest(set);
};

const storeCreditTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const storeAllCredit = async (set, data) => {
  return set({
    credit: data,
  });
};

const creditStoreObject = (set) => ({
  ...creditObjectDetails,
  storeCreditDetails: () => storeCredit(set),
  fetchCredit: () => storeCreditTimeout(set),
  getAllCredit: (data) => storeAllCredit(set, data),
});

export const creditStore = create(creditStoreObject);
