import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { settingsStore } from "../../../Zustand/AdminStore/settingsStore";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import { routesPutApi } from "Api/api";
import NavBar from "Components/NavBar/NavBar";
import { toastAlert } from "Api/middleware";
import { CustomInput } from "Components/Input/CustomInput";

function Settings() {
  const {
    openConfirmModal,
    openSuccessModal,
    openDiscardModal,
    discardModal,
    closeDiscardModal,
  } = alertModalStore((state) => state, shallow);
  const { settings, storeSettingsDetails, fetchSettings } = settingsStore(
    (state) => state,
    shallow
  );

  const formObject = {
    base_fare: 0,
    radiusKm: 0,
    surge_price: 0,
    commission_percent: 0,
    minDist_km: 0,
    base_km: 0,
  };

  const [formValues, setFormValues] = useState(formObject);
  const [driverRadius, setDriverRadius] = useState(0);
  const [btnLoading, setbtnLoading] = useState(false);

  const [bookingRadiusDistance, setBookingRadiusDistance] = useState(0);

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { radiusKm, minDist_km } = settings || {};

    setFormValues({
      ...settings,
    });

    setDriverRadius(radiusKm);
    return setBookingRadiusDistance(minDist_km);
  }, [settings]);

  const handleOnChange = (e) => {
    const {
      target: { name, type, valueAsNumber },
    } = e || {};

    return setFormValues((prev) => ({
      ...prev,
      [name]:
        type === "number"
          ? valueAsNumber >= 1
            ? valueAsNumber
            : ""
          : valueAsNumber,
    }));
  };

  const apiFunction = async () => {
    setbtnLoading(true);
    const {
      driverAvailabilityMin,
      base_fare,
      radiusKm,
      surge_price,
      _id,
      commission_percent,
      minDist_km,
      base_km,
    } = formValues || {};
    if (
      !driverAvailabilityMin ||
      !base_fare ||
      !radiusKm ||
      !surge_price ||
      !commission_percent ||
      !minDist_km ||
      !base_km
    ) {
      setbtnLoading(false);
      return toastAlert("error", "Make sure to fill all input");
    } else {
      const params = {
        ...formValues,
        radiusKm: driverRadius ?? radiusKm,
        minDist_km: bookingRadiusDistance,
      };

      return await routesPutApi(`/settings/${_id}`, params).then(
        ({ status }) => {
          if (status === 201) {
            storeSettingsDetails();
            setbtnLoading(false);
            closeDiscardModal(discardModal);
            return openSuccessModal({
              title: "Success",
              message: "Your changes has been successfully saved.",
              closeNameBtn: "Back",
            });
          } else {
            setbtnLoading(false);
          }
        }
      );
    }
  };

  const checkDataChanges = () => {
    const { base_fare, surge_price, base_km, commission_percent } =
      formValues || {};

    if (
      settings.radiusKm !== driverRadius ||
      settings.minDist_km !== bookingRadiusDistance ||
      settings.commission_percent !== commission_percent ||
      settings.base_fare !== base_fare ||
      settings.surge_price !== surge_price ||
      settings.base_km !== base_km
    ) {
      return openDiscardModal({
        title: "Confirm Discard Changes",
        message:
          "If you leave, any changes you have made will not be saved. Do you want to proceed?",
        confirmNameBtn: "Yes, Discard Changes",
        closeNameBtn: "Cancel",
        details: {
          ...formValues,
          radiusKm: driverRadius,
          minDist_km: bookingRadiusDistance,
        },
        isChange: true,
        isOpen: false,
      });
    } else {
      return openDiscardModal({
        ...discardModal,
        isChange: false,
      });
    }
  };

  useEffect(() => {
    checkDataChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, settings, driverRadius, bookingRadiusDistance]);

  const showUpdate = () => {
    return openConfirmModal({
      title: "Are You Sure",
      message: "Are you sure you want to update this?",
      confirmNameBtn: "Yes, Update",
      closeNameBtn: "Cancel",
      modalAction: () => apiFunction(),
    });
  };

  const saveButton = () => {
    return (
      <div className="flex  w-full justify-end items-center ">
        <button
          className="px-5 rounded-md text-sm font-medium py-2 gradientBg text-creamwhite"
          onClick={() => {
            showUpdate();
          }}
          disabled={btnLoading}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const radiusSettingsSection = () => {
    const limitDistanceAction = (data, setter) => {
      const totalLimitDistance = 1000000;

      if (data >= totalLimitDistance) return setter(totalLimitDistance);
      return setter(data);
    };

    return (
      <div className="max-w-3xl flex flex-col gap-3 md:gap-6">
        <div className="font-medium text-lg text-gray-400 uppercase">
          Booking Settings
        </div>
        <div className="grid grid-grid-cols-1 gap-1 ml-0 md:ml-5 ">
          <CustomInput
            name="driverRadius"
            type="number"
            label="Driver Radius"
            value={driverRadius}
            onChange={({ target: { valueAsNumber } }) =>
              limitDistanceAction(
                valueAsNumber >= 1 ? valueAsNumber : "",
                setDriverRadius
              )
            }
            step="0.01"
          />
        </div>
        <div className="grid grid-cols-1 gap-1 ml-0 md:ml-5 ">
          <CustomInput
            name="bookingRadiusDistance"
            type="number"
            label=" Minimum Booking Distance"
            value={bookingRadiusDistance}
            onChange={({ target: { valueAsNumber } }) =>
              limitDistanceAction(
                valueAsNumber >= 0 ? valueAsNumber : "",
                setBookingRadiusDistance
              )
            }
            step="0.01"
          />
        </div>
      </div>
    );
  };

  const rateSettingsSection = () => {
    const rateForm = [
      {
        _id: 1,
        value: formValues.base_fare,
        label: "Base Fare",
        fieldType: "number",
        onChange: handleOnChange,
        name: "base_fare",
        type: "number",
        grid: 1,
        min: 0,
        step: 0.01,
      },
      {
        _id: 2,
        value: formValues.base_km,
        label: "Base KM",
        fieldType: "number",
        onChange: handleOnChange,
        name: "base_km",
        type: "number",
        grid: 1,
        min: 0,
        step: 0.01,
      },
      {
        _id: 3,
        value: formValues.surge_price,
        label: "Surge Price",
        fieldType: "number",
        onChange: handleOnChange,
        name: "surge_price",
        type: "number",
        grid: 2,
        min: 0,
        step: 0.01,
      },
      {
        _id: 4,
        value: formValues.commission_percent,
        label: "Commission",
        fieldType: "number",
        onChange: handleOnChange,
        name: "commission_percent",
        type: "number",
        grid: 2,
        min: 0,
        step: 0.01,
      },
    ];

    return (
      <div className="max-w-3xl flex flex-col gap-2 pt-3 md:pt-5">
        <div className=" flex flex-col gap-3 md:gap-6">
          <div className="flex flex-col">
            <div className="font-medium text-lg text-gray-400">
              RATE SETTINGS
            </div>
            <div className="text-neutralblack text-sm">
              The rate per KM will be based in the Service Type
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            {rateForm?.map(({ _id, grid, ...rest }) => (
              <div className={`col-span-${grid}  ml-0 md:ml-5 `} key={_id}>
                <CustomInput {...rest} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar title="Settings" />
      <div className="bg-white rounded-xl shadow-xl h-fit">
        <div className=" flex flex-col gap-5 p-10 max-w-3xl">
          {radiusSettingsSection()}
          {rateSettingsSection()}
          {saveButton()}
        </div>
      </div>
    </>
  );
}

export default Settings;
