import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import shallow from "zustand/shallow";
import * as HiIcons from "react-icons/hi";
import { DataTable } from "Components/Global/GlobalTable";
import { routesDeleteApi, routesPostApi, routesPutApi } from "Api/api";
import GlobalRequiredField from "Components/Global/GlobalRequiredField";
import NavBar from "Components/NavBar/NavBar";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import { serviceStore } from "../../../Zustand/AdminStore/serviceStore";
import GlobalModal from "Components/Global/GlobalModal";
import editIcon from "Assets/ActionIcon/editIcon.png";
import deleteIcon from "Assets/ActionIcon/deleteIcon.png";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import { formatNumber } from "Utils/formatNumber";
import uploadFile from "Utils/uploadFile";
import { CustomInput } from "Components/Input/CustomInput";

function ServiceType() {
  const inputRef = useRef();
  const { openSuccessModal, openDeleteModal } = alertModalStore(
    (state) => state,
    shallow
  );
  const { openConfirmModal } = alertModalStore((state) => state, shallow);
  const { service, storeServiceDetails, fetchServiceType } = serviceStore(
    (state) => state,
    shallow
  );

  const [formValues, setFormValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const [modalType, setModalType] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchServiceType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (e) => {
    const {
      target: { name, value, type },
    } = e || {};

    return setFormValues((prev) => ({
      ...prev,
      [name]: type === "number" ? (value >= 0 ? value : "") : value,
    }));
  };

  const onChange = async (imageList) => {
    setFormValues({
      ...formValues,
      image: URL.createObjectURL(imageList.target.files[0]),
    });
    return setImages(imageList);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValues({});
    setValidate(false);
    setbtnLoading(false);
    setImages([]);
  };

  const columns = [
    {
      dataField: "image",
      text: "Image",
      formatter: (data) => {
        return (
          <div>
            {data ? (
              <img
                src={data}
                className="h-10 w-10 rounded-md shadow-sm"
                alt={data}
              />
            ) : (
              <div className="border border-lightgray/70 rounded-md p-2 h-10 w-10 shadow-sm flex justify-center items-center">
                <RiIcons.RiCarFill className="text-lightgray/70 text-xl" />
              </div>
            )}
          </div>
        );
      },
    },
    {
      dataField: "vehicleType",
      text: "Name",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md text-ellipsis overflow-hidden">
            {data}
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
            {data}
          </div>
        );
      },
    },
    {
      dataField: "capacity",
      text: "Capacity",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md text-ellipsis overflow-hidden ">
            {data}
          </div>
        );
      },
    },

    {
      dataField: "fare_rate",
      text: "Rate per KM",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="font-sans">
            ₱{formatNumber(data?.toFixed(2)) ?? "--"}
          </div>
        );
      },
    },
    {
      dataField: "serviceFare",
      text: "Service Fare",
      csvExport: true,
      formatter: (data) => {
        return (
          <div>
            <span className="font-sans">₱</span>
            {formatNumber(data?.toFixed(2))}
          </div>
        );
      },
    },
    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => menuList(row),
    },
  ];

  const menuList = (row) => {
    const actionList = [
      {
        _id: 1,
        label: "Update",
        svgIcon: editIcon,
        textColor: "text-neutralblack",
        itemFunction: (row) => {
          setFormValues(row);
          setOpenModal(true);
          return setModalType("Update");
        },
      },
      {
        _id: 2,
        label: "Delete",
        svgIcon: deleteIcon,
        textColor: "text-red-600",
        itemFunction: (row) => {
          openDeleteModal({
            title: "Delete",
            isDelete: true,
            message:
              "Are you sure you want to delete this? This action can not be undone.",
            confirmNameBtn: "Yes, Delete",
            closeNameBtn: "Cancel",
            modalAction: () => {
              archiveService(row._id);
            },
          });
        },
      },
    ];

    return (
      <Menu
        as="div"
        id="actionButton"
        className="relative inline-block text-left"
      >
        <div>
          <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
          </Menu.Button>
        </div>
        <Transition appear>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
          </Transition.Child>

          <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
            <div className="flex flex-col py-2">
              {actionList?.map((item) => {
                const { label, svgIcon, itemFunction, textColor, _id } = item;
                return (
                  <Menu.Item onClick={() => itemFunction(row)} key={_id}>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                      >
                        <img src={svgIcon} alt={svgIcon} />
                        <span className={`${textColor}`}>{label}</span>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const askConfirmation = () => {
    const { vehicleType, capacity, description, serviceFare, fare_rate } =
      formValues || {};

    if (
      !vehicleType ||
      !capacity ||
      !description ||
      !(serviceFare >= 0) ||
      !(fare_rate >= 0)
    ) {
      return setValidate(true);
    } else {
      const params = {
        fileContent: images,
        apiFunction,
        path: "serviceType_image/",
        applyValidate: true,
        setbtnLoading,
      };

      if (modalType === "Add") {
        setbtnLoading(true);
        return uploadFile({ ...params });
      } else {
        return openConfirmModal({
          title: "Are You Sure",
          message: "Are you sure you want to update this ?",
          confirmNameBtn: "Yes, Update",
          closeNameBtn: "Cancel",
          modalAction: () => uploadFile({ ...params }),
        });
      }
    }
  };

  const apiFunction = async (imageUpload) => {
    const params = {
      ...formValues,
      image: imageUpload ? imageUpload : formValues?.image,
    };

    function getApiRoute() {
      return modalType === "Add"
        ? routesPostApi("/servicetype", params)
        : routesPutApi(`/servicetype/${formValues?._id}`, params);
    }

    await getApiRoute().then((res) => {
      if (res.status === 201) {
        storeServiceDetails();
        openSuccessModal({
          title: "Success",
          message:
            modalType === "Add"
              ? "Added Successfully!"
              : "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return handleCloseModal();
      } else {
        setbtnLoading(false);
      }
    });
  };

  const archiveService = async (rowId) => {
    await routesDeleteApi(`/servicetype/${rowId}`).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return storeServiceDetails();
      }
    });
  };

  const showAddUpdateModal = () => {
    const body = () => {
      const formContent = [
        {
          _id: 1,
          value: formValues.vehicleType,
          label: "Name",
          isEmpty: validate,
          fieldType: "text",
          onChange: handleOnChange,
          name: "vehicleType",
          type: "text",
          grid: 1,
        },
        {
          _id: 2,
          value: formValues.capacity,
          label: "Capacity",
          isEmpty: validate,
          fieldType: "number",
          onChange: handleOnChange,
          name: "capacity",
          type: "number",
          placeholder: "00",
          grid: 1,
        },
        {
          _id: 3,
          value: formValues.description,
          label: "Description",
          isEmpty: validate,
          fieldType: "text",
          onChange: handleOnChange,
          name: "description",
          type: "text",
          grid: 2,
        },
        {
          _id: 4,
          value: formValues.fare_rate,
          label: "Rate per KM",
          isEmpty: validate,
          fieldType: "number",
          onChange: handleOnChange,
          name: "fare_rate",
          type: "number",
          grid: 1,
          placeholder: "00",
        },
        {
          _id: 5,
          value: formValues.serviceFare,
          label: "Service Fare",
          isEmpty: validate,
          fieldType: "number",
          onChange: handleOnChange,
          name: "serviceFare",
          type: "number",
          grid: 1,
          placeholder: "00",
        },
      ];
      return (
        <div class="grid grid-cols-2 gap-3">
          {formContent.map(({ grid, _id, ...rest }) => (
            <div className={`col-span-${grid}`} key={_id}>
              <CustomInput {...rest} />
            </div>
          ))}

          <div className="flex flex-col">
            <label className="text-neutraldarkgray text-xs">
              Image
              <GlobalRequiredField
                fieldType="text"
                isEmpty={validate}
                value={formValues.image}
              />
            </label>
            <div className="flex justify-center items-center">
              {formValues.image ? (
                <div className="flex justify-center items-center relative">
                  <img
                    src={formValues.image}
                    alt={formValues.image}
                    className="border border-orange h-60 flex justify-center items-center w-80  rounded-md z-40 "
                    onClick={() => inputRef.current.click()}
                  />
                  <div
                    className="z-40 absolute  group/item w-full h-full hover:bg-black/50 rounded-md hover:cursor-pointer "
                    onClick={() => inputRef.current.click()}
                  >
                    <div className="absolute top-0 left-0 z-40 text-white h-full w-full  group/edit invisible  group-hover/item:visible flex flex-col gap-1 justify-center items-center">
                      <BsIcons.BsCamera className="text-3xl" />
                      <div className="text-xs">Change picture</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-[#F5F5F5] h-60 flex justify-center items-center w-80  rounded-sm"
                  onClick={() => inputRef.current.click()}
                >
                  <HiIcons.HiOutlinePlus size={20} className="text-lightblue" />
                </div>
              )}

              <input
                accept="image/*"
                type="file"
                ref={inputRef}
                hidden
                onChange={(imageList) => onChange(imageList)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center text-lightblue  text-sm">
            <div>
              <span className="text-archivered">*</span> Image size must at
              least 4MB
            </div>
          </div>
        </div>
      );
    };

    const props = {
      body: body(),
      isOpen: openModal,
      width: "xl",
      header: modalType === "Add" ? "Add New TODA" : "Update TODA",
      modalType: modalType,
      closeFunction: handleCloseModal,
      closeBtnName: "Cancel",
      submitFunction: askConfirmation,
      submitBtnName: modalType === "Add" ? "Add" : "Update",
      headerStyle: "text-neutralblack",
      btnLoading: btnLoading,
    };

    return <GlobalModal props={props} />;
  };

  return (
    <>
      <NavBar title="Tricycle Operators and Drivers' Association (Philippines)" />
      {openModal && showAddUpdateModal()}
      <DataTable
        rows={service}
        columns={columns}
        title="List of TODA"
        btnName="TODA"
        csvtitle="servicetype.csv"
        setOpenModal={setOpenModal}
        setModalType={setModalType}
      />
    </>
  );
}

export default ServiceType;
