import React, { Fragment, useEffect, useState, memo } from "react";
import { Menu, Transition } from "@headlessui/react";
import shallow from "zustand/shallow";
import * as HiIcons from "react-icons/hi";
import { DataTable } from "Components/Global/GlobalTable";
import { routesGetAllApi, routesPutApi } from "Api/api";
import NavBar from "Components/NavBar/NavBar";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import { passengerStore } from "../../../Zustand/AdminStore/passengerStore";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import PassengerDetails from "./PassengerDetails";
import FullnameFormat from "Utils/FullnameFormat";
import { APIFetch } from "Utils/timeout";
import SwitchButton from "Components/ToggleButton/SwitchButton";

const Passenger = () => {
  const {
    passenger,
    storePassengerDetails,
    storePassengerBooking,
    storePassengerWallet,
    fetchPassenger,
  } = passengerStore((state) => state, shallow);
  const [openViewModal, setOpenViewModal] = useState(false);
  const { openConfirmModal, openSuccessModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    fetchPassenger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPassengerDetails = async (row) => {
    const apiList = [
      `/cashin/balance/${row._id}`,
      `/users/cashin/booking/reports/${row._id}`,
    ];
    APIFetch(async () => {
      await routesGetAllApi(apiList).then((res) => {
        const { data } = res[0] ?? {};
        const { bookings, wallet } = res[1].data ?? {};
        storePassengerBooking(bookings);
        storePassengerWallet(wallet);
        setFormValues({ ...row, ...data });
      });
      setOpenViewModal(true);
    });
  };

  const renderChangeStatus = async (row) => {
    await routesPutApi(`/users/status/${row._id}`).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });

        return storePassengerDetails();
      }
    });
  };

  const columns = [
    {
      dataField: "firstName",
      text: "Name",
      formatter: (_data, row) => {
        return (
          <label
            className="capitalize text-lightblue cursor-pointer"
            onClick={() => getPassengerDetails(row)}
          >
            <FullnameFormat props={row} />
          </label>
        );
      },
    },
    {
      dataField: "middleName",
      text: "Middle Name",
      hidden: true,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      hidden: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "contactNo",
      text: "Contact Number",
      formatter: (cell) => {
        return <span> {cell ? `+63${cell}` : "--"}</span>;
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (data, row) => {
        const togglePropsContainer = {
          data,
          onChangeFunction: () =>
            openConfirmModal({
              title: "Are You Sure",
              message:
                "Are you sure you want to update the status of this user?",
              confirmNameBtn: "Yes, Update",
              closeNameBtn: "Cancel",
              modalAction: () => {
                renderChangeStatus(row);
              },
            }),
        };

        return <SwitchButton {...togglePropsContainer} />;
      },
    },
    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => {
        return (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
              </Menu.Button>
            </div>
            <Transition appear>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
              </Transition.Child>

              <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
                <div className="flex flex-col py-2">
                  <Menu.Item>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                        onClick={() => {
                          getPassengerDetails(row);
                        }}
                      >
                        <img src={viewIcon} alt="View Icon" />
                        <span className="text-neutralblack font-normal">
                          View
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      },
    },
  ];

  const renderContent = () => {
    const props = {
      setOpenViewModal,
      formValues,
    };
    return !openViewModal ? <ShowTable /> : <PassengerDetails props={props} />;
  };

  const ShowTable = () => (
    <DataTable
      rows={passenger}
      columns={columns}
      csvtitle="passengers.csv"
      title="List of passengers"
    />
  );

  return (
    <>
      <NavBar title="Passengers" />
      {renderContent()}
    </>
  );
};

export default memo(Passenger);
