import create from "zustand";

const menuObjectDetails = {
  isOpenMenu: false,
};

const storeMenu = async (set, data) => {
  return set({
    isOpenMenu: data,
  });
};

const menuStoreObject = (set) => ({
  ...menuObjectDetails,
  storeMenuUpdate: (data) => storeMenu(set, data),
});

export const menuStore = create(menuStoreObject);
