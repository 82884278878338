import React from "react";
import emptyImage from "Assets/Image/empty.png";

export default function EmptyPage({ label, sublabel }) {
  return (
    <div className="flex flex-col gap-2 justify-center items-center h-[75vh]">
      <img src={emptyImage} alt="empty" className="h-44 w-44" />
      <div className="flex flex-col items-center">
        <div className="text-neutralblack font-medium text-lg">
          {label ? label : "It looks a bit empty here!"}
        </div>
        <div className="text-neutraldark text-base">{sublabel}</div>
      </div>
    </div>
  );
}
