import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import defaultProfile from "Assets/Image/defaultProfile.png";
import * as RiIcons from "react-icons/ri";
import { DataTable } from "Components/Global/GlobalTable";
import { walletCol } from "./TableCol";
import shallow from "zustand/shallow";

import walletIcon from "Assets/Image/walletIcon.png";
import { passengerStore } from "../../../Zustand/AdminStore/passengerStore";
import moment from "moment";
import { routesGetApi } from "Api/api";
import StatusStyle from "Components/StatusStyle/StatusStyle";
import FullnameFormat from "Utils/FullnameFormat";
import { APIFetch } from "Utils/timeout";
import ViewBooking from "Components/Booking/ViewBooking";
import { formatNumber } from "Utils/formatNumber";

function PassengerDetails({ props }) {
  const { setOpenViewModal, formValues } = props;
  const { email, address, image, contactNo, birthDate, balance } = formValues;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openViewBooking, setViewBooking] = useState(false);
  const [bookingInfo, setBookingInfo] = useState({});
  const { booking, wallet } = passengerStore((state) => state, shallow);

  const tabPanelList = [
    {
      _id: 0,
      tabTitle: "Booking History",
    },
    {
      _id: 1,
      tabTitle: "PICKAPP Wallet",
    },
  ];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const PassengerInfo = () => {
    const passengerDataInfo = [
      FullnameFormat({ props: formValues }),
      birthDate ? moment(birthDate)?.format("LL") : "-",
      `${contactNo ? "0" + contactNo : "-"}`,
      email,
      `${address ? address : "-"}`,
    ];

    return (
      <div className="flex flex-row justify-between  p-8">
        <div className="flex flex-row gap-5">
          <div className="flex justify-center">
            <img
              src={image ? image : defaultProfile}
              alt="defaultProfile"
              loading="lazy"
              className="h-36 w-36 rounded-md"
            />
          </div>
          <div className="flex flex-col gap-2 p-5 md:p-0">
            {passengerDataInfo?.map((data, index) => (
              <div
                key={data}
                className={`${
                  index === 0 ? "font-medium text-lg capitalize" : "font-normal"
                }`}
              >
                {data}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex flex-row border border-pickAppRedDefault h-fit rounded-xl p-1 w-72">
            <img src={walletIcon} alt="walletIcon" />
            <div className="flex flex-col justify-center px-5">
              <div className="text-neutralblack font-medium text-xl font-sans">
                ₱ {formatNumber(balance) ?? 0}
              </div>
              <div className="text-pickAppRedDefault font-normal text-sm">
                PickApp wallet balance
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const viewBooking = async (id) => {
    APIFetch(async () => {
      await routesGetApi(`/bookings/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setBookingInfo(data ?? {});
          setViewBooking(true);
        }
      });
    });
  };

  const bookingNoLayout = ({ _id, bookingNo }) => {
    return (
      <div
        className="text-lightblue cursor-pointer hover:text-customgray"
        onClick={() => viewBooking(_id)}
      >
        {bookingNo}
      </div>
    );
  };

  const historyCol = [
    {
      dataField: "createdAt",
      text: "Date and Time",
      formatter: (data) => moment(data).format("LLL"),
    },
    {
      dataField: "bookingNo",
      text: "Booking No.",
      formatter: (_data, row) => bookingNoLayout(row),
    },
    {
      dataField: "origin",
      text: "Pick-up Location",
      formatter: (data) => (
        <div className="w-20 lg:w-52 line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
          {data}
        </div>
      ),
    },
    {
      dataField: "destination",
      text: "Drop-off Location",
      formatter: (data) => (
        <div className="w-20 lg:w-52 line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
          {data}
        </div>
      ),
    },
    {
      dataField: "bookingFare_id.total",
      text: "Total Fare",
      formatter: (data) => (
        <span className="font-sans">
          {data ? `₱${formatNumber(data?.toFixed(2))}` : "--"}
        </span>
      ),
    },
    {
      dataField: "booking_status",
      text: "Status",
      formatter: (data) => StatusStyle(data),
    },
    {
      dataField: "_id",
      text: "",
      formatter: (data) => {
        return (
          <button onClick={() => viewBooking(data)}>
            <RiIcons.RiArrowRightSLine />
          </button>
        );
      },
    },
  ];

  const tableCol = () => {
    switch (selectedIndex) {
      case 0:
        return [...historyCol];
      case 1:
        return [...walletCol];
      default:
        break;
    }
  };

  const tableRow = () => {
    switch (selectedIndex) {
      case 0:
        return booking;
      case 1:
        return wallet;
      default:
        break;
    }
  };

  const tabGroup = () => {
    return (
      <div className="flex flex-col bg-white rounded-md h-auto p-2">
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(e) => {
            setSelectedIndex(e);
          }}
        >
          <Tab.List
            selected
            className="flex space-x-1 rounded-xl bg-opacity-20 "
          >
            {tabPanelList && tabPanelList.length > 0
              ? tabPanelList.map(({ tabTitle, _id }) => (
                  <Tab
                    key={_id}
                    className={({ selected }) =>
                      classNames(
                        "w-40 py-2 text-sm font-normal antialiased focus:outline-none",
                        selected
                          ? "bg-white text-pickAppRedDefault border-b-2 border-pickAppRedDefault "
                          : "text-lightgray hover:bg-white/[0.12] hover:text-neutraldarkgray "
                      )
                    }
                  >
                    {tabTitle ?? null}
                  </Tab>
                ))
              : null}
          </Tab.List>
          <hr />
          <Tab.Panels>
            <DataTable rows={tableRow()} columns={tableCol()} sizePerPage={4} />
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  const MainPanel = () => {
    return (
      <div className="flex flex-col">
        {PassengerInfo()}
        {tabGroup()}
      </div>
    );
  };

  const backButton = () => {
    return (
      <div>
        <button
          className="flex flex-row gap-1 items-center text-pickAppRedDefault"
          onClick={() => {
            openViewBooking ? setViewBooking(false) : setOpenViewModal(false);
          }}
        >
          <RiIcons.RiArrowLeftLine />
          <span>Back</span>
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <div>{backButton()}</div>
      <div className=" bg-white rounded-lg shadow-lg mb-4">
        {!openViewBooking ? (
          <MainPanel />
        ) : (
          <ViewBooking
            viewBookingInfo={bookingInfo}
            hideEarnings={true}
            hidePassenger={true}
            infoRow={1}
          />
        )}
      </div>
    </div>
  );
}

export default PassengerDetails;
