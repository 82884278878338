import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import moment from "moment";
import defaultProfile from "Assets/Image/defaultProfile.png";
import StatusStyle from "Components/StatusStyle/StatusStyle";
import FullnameFormat from "Utils/FullnameFormat";
import { formatNumber } from "Utils/formatNumber";

function ViewBooking({
  viewBookingInfo,
  hideEarnings,
  infoRow,
  hideDriver,
  hidePassenger,
}) {
  const {
    bookingNo,
    booking_status,
    updatedAt,
    destination,
    passenger_id,
    noteToDriver,
    origin,
    service_baseFare,
    timeCharge,
    distance,
    dist_cal,
    total,
    promoCode_id,
    paymentMethod,
    tip_amount,
    additionalFees,
    subTotal_surgeRate,
    totalFare,
    driver_id,
    cancellationReason,
  } = viewBookingInfo ?? {};

  const [openPaymentDetails, setPaymentDetails] = useState(false);

  const Head = () => {
    return (
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <div className="text-lg text-neutralblack font-medium">
            Booking No. {bookingNo}
          </div>

          <div>
            {moment(updatedAt).format("LL")} | {moment(updatedAt).format("LT")}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-sm">{StatusStyle(booking_status)}</div>
        </div>
      </div>
    );
  };

  const DeliveryInfo = () => {
    const pickUpDetails = () => {
      return (
        <div className="flex flex-col gap-1">
          <div className="text-neutralgray text-sm">Pick-up Details</div>
          <div className="text-neutralblack  capitalize">{origin}</div>
        </div>
      );
    };

    const dropOffDetails = () => {
      return (
        <div className="flex flex-col gap-1">
          <div className="text-neutralgray text-sm">Drop-off Details</div>
          <div className="text-neutralblack capitalize">{destination}</div>
        </div>
      );
    };

    const note = () => {
      return (
        <div className="flex flex-col gap-1">
          <div className="text-neutralgray text-sm">Note to Driver</div>
          <div className="text-neutralblack ">
            {noteToDriver !== "" ? noteToDriver : "-"}
          </div>
        </div>
      );
    };
    return (
      <div className="flex flex-col bg-[#FAFAFA] p-4 rounded-md gap-4">
        {pickUpDetails()}
        {dropOffDetails()}
        {note()}
      </div>
    );
  };

  const TotalandPayment = () => {
    const DriversTip = () =>
      tip_amount > 0 && (
        <div className="flex flex-row justify-between ">
          <div>Driver’s Tip</div>

          <div className="font-medium font-sans">
            ₱ {tip_amount?.toFixed(2)}
          </div>
        </div>
      );
    return (
      <div className="flex flex-col gap-1">
        {hideEarnings && <DriversTip />}
        <div
          className={`flex flex-row justify-between ${
            hideEarnings ? "font-medium" : ""
          }`}
        >
          <div>Total Fare</div>
          <div className="font-sans">
            ₱{" "}
            {formatNumber(
              hideEarnings ? total?.toFixed(2) : totalFare?.toFixed(2)
            ) ?? 0}
          </div>
        </div>
        {!hideEarnings && <DriversTip />}

        {cancellationReason ? (
          <div className="flex flex-row justify-between ">
            <div>Cancellation Reason</div>
            <div>{cancellationReason}</div>
          </div>
        ) : (
          <>
            {!hideEarnings && (
              <div className="flex flex-row justify-between font-medium text-lg text-pickAppRedDefault">
                <div>Earnings</div>
                <div className="font-sans">+ ₱ {total?.toFixed(2)}</div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const PaymentDetails = () => {
    const { code, value } = promoCode_id ?? {};
    const list = [
      {
        _id: 1,
        label: "Base Fare",
        value: service_baseFare,
      },
      {
        _id: 2,
        label: "Distance",
        value: dist_cal,
      },
      {
        _id: 3,
        label: "Time",
        value: timeCharge,
      },
    ];

    const newAdditionalFees = additionalFees?.filter((item, index, self) => {
      return index === self.findIndex((t) => t._id === item._id);
    });

    const details = () => {
      return (
        <div className="grid grid-flow-row px-2 divide-y-2">
          <div className="grid grid-flow-row divide-y-2 divide-dashed">
            <div className="grid grid-flow-row">
              {list?.length > 0 &&
                list?.map((data) => {
                  const { label, _id, value, subLabel, isSub } = data ?? {};

                  return (
                    <div
                      className="grid grid-cols-2 grid-flow-col py-1 text-sm "
                      key={_id}
                    >
                      <div>
                        {label}{" "}
                        <span className="text-lightgray font-sans">
                          {subLabel}
                        </span>
                      </div>

                      <div className="text-right font-sans">
                        {isSub && "-"} ₱ {value?.toFixed(2)}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex flex-col gap-1">
              <div className="grid grid-cols-2 grid-flow-col py-1 text-sm ">
                <div>Subtotal x Surge Rate</div>

                <div className="text-right font-sans">
                  ₱ {subTotal_surgeRate?.toFixed(2)}
                </div>
              </div>
              {promoCode_id ? (
                <div className="grid grid-cols-2 grid-flow-col py-1 text-sm ">
                  <div>
                    Promo Code{" "}
                    <span className="text-lightgray font-sans">
                      ({code} - ₱{value.toFixed(2)} OFF)
                    </span>
                  </div>

                  <div className="text-right font-sans">
                    - ₱ {promoCode_id?.value.toFixed(2)}
                  </div>
                </div>
              ) : null}
              {additionalFees?.length > 0 && (
                <div className="grid grid-rows-1  py-1 text-sm ">
                  <div>Additional Fee(s)</div>
                  {newAdditionalFees?.map(({ description, value, _id }) => {
                    return (
                      <div
                        className="grid grid-cols-2 grid-flow-col py-1 text-sm ml-4"
                        key={_id}
                      >
                        <div className="text-neutraldark text-sm">
                          {description}
                        </div>

                        <div className="text-right font-sans">
                          ₱ {value?.toFixed(2)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div />
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-3  justify-center ">
        <div
          className="flex flex-row justify-between "
          onClick={() => setPaymentDetails(!openPaymentDetails)}
        >
          <div className="text-pickAppRedDefault">Payment Details</div>
          <div className="cursor-pointer text-pickAppRedDefault">
            {openPaymentDetails ? (
              <RiIcons.RiArrowUpSLine size={20} />
            ) : (
              <RiIcons.RiArrowDownSLine size={20} />
            )}
          </div>
        </div>
        {openPaymentDetails && (
          <div className="flex flex-col gap-2 ">{details()}</div>
        )}
      </div>
    );
  };

  const PassengerInfo = () => {
    const { contactNo, email, image } = passenger_id ?? {};

    const passengerData = [
      passenger_id ? FullnameFormat({ props: passenger_id }) : "no passenger",
      contactNo ? `0${contactNo}` : null,
      email,
    ];

    return (
      <div className="flex flex-col gap-4">
        <div className="text-sm text-neutraldark2">Passenger Information</div>
        <div className="flex flex-row justify-start gap-5 items-center">
          <img
            src={image ? image : defaultProfile}
            alt="defaultProfile"
            className="h-28 w-28 rounded-md border border-pickAppRedDefault"
          />

          <div className="flex flex-col  p-5 md:p-0 w-full justify-center">
            {passengerData.map((data, index) => (
              <div
                key={data}
                className={`${
                  index === 0 ? "font-medium text-lg" : "font-normal"
                } break-all text-base`}
              >
                {data}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const DistanceAndPaymentMethod = () => {
    const arrPaymentMethod = paymentMethod.split("-");

    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div>Total Distance</div>
          <div className="font-medium">{distance} km</div>
        </div>
        <div className="flex flex-row justify-between">
          <div>Payment Method</div>
          <div className="font-medium capitalize">
            {arrPaymentMethod?.length > 1
              ? arrPaymentMethod?.map((data) => data + " ")
              : paymentMethod}
          </div>
        </div>
      </div>
    );
  };

  const DriverInfo = () => {
    const { mobileNum, email, image, vehicle_id } = driver_id ?? {};
    const { plate_no, brand, color, model } = vehicle_id ?? {};

    const driver = [
      FullnameFormat({ props: driver_id }),
      `0${mobileNum}`,
      email,
      ` ${plate_no} - ${brand} ${model} (${color})`,
    ];

    return (
      <div className="flex flex-col gap-4">
        <div className="text-sm text-neutraldark2">Driver Information</div>
        <div className="flex flex-row justify-start gap-5 items-center">
          <img
            src={image ? image : defaultProfile}
            alt="defaultProfile"
            className="h-28 w-28 rounded-md border border-pickAppRedDefault"
          />

          <div className="flex flex-col justify-center p-5 md:p-0 w-full">
            {driver_id ? (
              <>
                {driver.map((data, index) => (
                  <div
                    key={data}
                    className={`${
                      index === 0 ? "font-medium text-lg" : "font-normal"
                    } break-all text-base`}
                  >
                    {data}
                  </div>
                ))}
              </>
            ) : (
              <div className="font-medium text-base text-archivered">
                No Driver Selected
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-5 flex flex-col gap-3 bg-white rounded-lg shadow-lg">
      <Head />
      <hr />
      <div
        className={`grid grid-cols-1 gap-5 lg:grid-cols-${
          infoRow ? infoRow : 1
        }`}
      >
        {!hideDriver && <DriverInfo />}
        {!hidePassenger && <PassengerInfo />}
      </div>
      <DeliveryInfo />
      <DistanceAndPaymentMethod />
      {!cancellationReason && <PaymentDetails />}
      <TotalandPayment />
    </div>
  );
}

export default ViewBooking;
