import React from "react";
import { Switch } from "@headlessui/react";

export default function SwitchButton({ data, onChangeFunction }) {
  return (
    <div className="flex flex-row items-center gap-1">
      <Switch
        checked={data}
        onChange={onChangeFunction}
        className={`relative inline-flex h-4 w-7 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${
          data ? "border-green-500" : "border-gray-400"
        }`}
      >
        <span
          aria-hidden="true"
          className={`${
            data ? "translate-x-3 bg-green-500" : "translate-x-1 bg-gray-300"
          }
            pointer-events-none inline-block h-2 w-2 mt-0.5 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <span
        className={`${
          data ? "text-green-500" : "text-gray-300"
        } font-normal text-sm`}
      >
        {data ? "Active" : "Inactive"}
      </span>
    </div>
  );
}
