import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

export default function LoadingLottie() {
  return (
    <div className="flex flex-row  w-full items-center justify-center">
      <Player
        autoplay
        loop
        src="https://assets8.lottiefiles.com/packages/lf20_kxsd2ytq.json"
        style={{ height: "1rem", width: "2rem" }}
      />
      <span>Please wait</span>
    </div>
  );
}
