import create from "zustand";
import { routesGetApi } from "Api/api";
import moment from "moment/moment";
import { APIFetch } from "Utils/timeout";

const reportsObjectDetails = {
  reports: [],
};

const storeAllReports = async (set, data) => {
  return set({
    reports: data,
  });
};

const apiRequest = async (set) => {
  let restructDate = [];
  await routesGetApi(`/reports`).then(({ data, status }) => {
    if (status === 200) {
      data?.length > 0 &&
        data.map((data) => {
          const params = {
            ...data,
            newDate: moment(data?.date).format("LL"),
          };
          return restructDate.push(params);
        });
      return set({
        reports: restructDate,
      });
    }
  });
};

const storeReportsAction = async (set) => {
  return apiRequest(set);
};

const storeReportsTimeout = async (set) => {
  return APIFetch(() => {
    apiRequest(set);
  });
};

const reportsStoreObject = (set) => ({
  ...reportsObjectDetails,
  getReportsData: () => storeReportsAction(set),
  fetchReports: () => storeReportsTimeout(set),
  getAllReports: (data) => storeAllReports(set, data),
});

export const reportStore = create(reportsStoreObject);
