import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const withdrawalObjectDetails = {
  withdrawal: [],
};

const apiRequest = async (set) => {
  return await routesGetApi("/withdrawal").then((res) => {
    if (res.status === 200) {
      return set({
        withdrawal: res.data,
      });
    }
  });
};

const storeWithdrawal = async (set) => {
  return apiRequest(set);
};

const storeWithdrawalTimeout = async (set) => {
  return APIFetch(() => {
    apiRequest(set);
  });
};

const storeAllWithdrawal = async (set, data) => {
  return set({
    withdrawal: data,
  });
};

const withdrawalStoreObject = (set) => ({
  ...withdrawalObjectDetails,
  storeWithdrawalDetails: () => storeWithdrawal(set),
  fetchWithdrawal: () => storeWithdrawalTimeout(set),
  getAllwithdrawal: (data) => storeAllWithdrawal(set, data),
});

export const withdrawalStore = create(withdrawalStoreObject);
