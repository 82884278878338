import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import HypeMarker from 'Assets/Image/hypemarker.png';
import { driverStore } from '../../Zustand/AdminStore/driverStore';
import shallow from 'zustand/shallow';
import * as RiIcons from 'react-icons/ri';
import FullnameFormat from 'Utils/FullnameFormat';

export default function ReactGoogleMap({ center }) {
  const gkeyApi = 'AIzaSyAJYgOfW_Yr6URl6gSObpDFDcCXUaRb99M';

  const windowHeight = window.innerHeight;

  const { activeDriver, storeOnlineDriverList } = driverStore(
    (state) => state,
    shallow
  );

  const [hoverIndex, setHoverIndex] = useState({ isHover: false });

  const { locLat, locLng } = center;

  useEffect(() => {
    storeOnlineDriverList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const customMarkerIcon = {
    url: HypeMarker,
  };

  const markerToolTip = (i, location, isHover, e) => {
    setHoverIndex({
      index: i,
      isHover: isHover,
      position: {
        lat: location?.coordinates[1],
        lng: location?.coordinates[0],
      },
    });
  };

  return (
    <div
      style={{ height: windowHeight - 0.3 * windowHeight, width: '100%' }}
      className="relative"
    >
      <div className="bg-white absolute z-10 py-2 px-9 m-2 rounded-md flex flex-col gap-1 items-center">
        <div className="font-medium text-lg">{activeDriver?.length}</div>
        <div className="flex flex-row gap-1 items-center text-orange text-sm">
          <RiIcons.RiUserLocationFill />
          <div>Online Drivers</div>
        </div>
      </div>
      <LoadScript googleMapsApiKey={gkeyApi}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: hoverIndex?.position?.lat ?? locLat,
            lng: hoverIndex?.position?.lng ?? locLng,
          }}
          zoom={11}
        >
          {activeDriver?.map((item, index) => {
            const { location, _id } = item ?? {};
            return (
              <div key={_id}>
                <Marker
                  position={{
                    lat: location?.coordinates[1],
                    lng: location?.coordinates[0],
                  }}
                  onMouseOver={() => markerToolTip(index, location, true)}
                  onMouseOut={() => markerToolTip(index, location, false)}
                  icon={customMarkerIcon}
                />
                {hoverIndex.isHover && hoverIndex.index === index && (
                  <InfoWindow
                    position={{
                      lat: location?.coordinates[1],
                      lng: location?.coordinates[0],
                    }}
                    options={{
                      pixelOffset: { width: 0, height: -60 },
                    }}
                  >
                    <div className="capitalize">
                      {FullnameFormat({ props: item })}
                    </div>
                  </InfoWindow>
                )}
              </div>
            );
          })}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
