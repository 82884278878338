import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const passengerObjectDetails = {
  passenger: [],
  booking: [],
  wallet: [],
};

const apiRequest = async (set) => {
  await routesGetApi("/users/?role=passenger").then((res) => {
    if (res.status === 200) {
      return set({
        passenger: res.data,
      });
    }
  });
};

const storePassenger = async (set) => {
  return apiRequest(set);
};

const storePassengerTimeout = async (set) => {
  return APIFetch(() => apiRequest(set), 100);
};

const storeAllPassenger = async (set, data) => {
  return set({
    passenger: data,
  });
};

const storeWallet = async (set, data) => {
  return set({
    wallet: data,
  });
};

const storeBooking = async (set, data) => {
  return set({
    booking: data,
  });
};

const passengerStoreObject = (set) => ({
  ...passengerObjectDetails,
  storePassengerDetails: () => storePassenger(set),
  fetchPassenger: () => storePassengerTimeout(set),
  getAllPassenger: (data) => storeAllPassenger(set, data),
  storePassengerBooking: (data) => storeBooking(set, data),
  storePassengerWallet: (data) => storeWallet(set, data),
});

export const passengerStore = create(passengerStoreObject);
