import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import shallow from "zustand/shallow";
import * as HiIcons from "react-icons/hi";
import { DataTable } from "Components/Global/GlobalTable";
import { routesGetApi, routesPostApi, routesPutApi } from "Api/api";
import NavBar from "Components/NavBar/NavBar";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import GlobalModal from "Components/Global/GlobalModal";
import editIcon from "Assets/ActionIcon/editIcon.png";
import { promoCodeStore } from "../../../Zustand/AdminStore/promoCodeStore";
import { APIFetch } from "Utils/timeout";
import SwitchButton from "Components/ToggleButton/SwitchButton";
import { CustomInput } from "Components/Input/CustomInput";
import { formatNumber } from "Utils/formatNumber";

function PromoCode() {
  const { openSuccessModal, openConfirmModal } = alertModalStore(
    (state) => state,
    shallow
  );
  const { promoCode, storePromoCodeDetails, fetchPromoCode } = promoCodeStore(
    (state) => state,
    shallow
  );

  const [formValues, setFormValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const [modalType, setModalType] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);

  useEffect(() => {
    fetchPromoCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (e) => {
    const {
      target: { name, value, type },
    } = e || {};

    if (name === "code") {
      if (value?.length <= 15) {
        return setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      return setFormValues((prev) => ({
        ...prev,
        [name]: type === "number" ? (value >= 1 ? value : "") : value,
      }));
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValues({});
    setValidate(false);
    setbtnLoading(false);
  };

  const renderChangeStatus = async ({ _id }) => {
    await routesPutApi(`/promocode/status/${_id}`).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return storePromoCodeDetails();
      }
    });
  };

  const viewPromoCode = async ({ _id }) => {
    APIFetch(async () => {
      await routesGetApi(`/promocode/${_id}`).then(({ data, status }) => {
        if (status === 200) {
          setOpenViewModal(true);
          return setFormValues(data ?? {});
        }
      });
    });
  };

  const columns = [
    {
      dataField: "code",
      text: "Code",
      csvExport: true,
      formatter: (data, row) => {
        return (
          <div
            className="max-w-md min-w-md text-ellipsis overflow-hidden text-lightblue cursor-pointer"
            onClick={() => viewPromoCode(row)}
          >
            {data}
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
            {data}
          </div>
        );
      },
    },
    {
      dataField: "value",
      text: "Amount",
      csvExport: true,
      formatter: (data) => {
        return (
          <div>
            <span className="font-sans">₱</span>
            {data?.toFixed(2)}
          </div>
        );
      },
    },
    {
      dataField: "remaining",
      text: "Remaining",
      csvExport: true,
      formatter: (data) => {
        return <div>{data}</div>;
      },
    },
    {
      dataField: "maxUsage",
      text: "Max Usage",
      csvExport: true,
      formatter: (data) => {
        return <div>{data ?? "--"}</div>;
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (data, row) => {
        const togglePropsContainer = {
          data,
          onChangeFunction: () =>
            openConfirmModal({
              title: "Are You Sure",
              message: "Are you sure you want to update the status?",
              confirmNameBtn: "Yes, Update",
              closeNameBtn: "Cancel",
              modalAction: () => {
                renderChangeStatus(row);
              },
            }),
        };

        return <SwitchButton {...togglePropsContainer} />;
      },
    },

    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => menuList(row),
    },
  ];

  const menuList = (row) => {
    const actionList = [
      {
        _id: 1,
        label: "View",
        svgIcon: viewIcon,
        itemFunction: (row) => viewPromoCode(row),
      },
      {
        _id: 2,
        label: "Update",
        svgIcon: editIcon,
        itemFunction: (row) => {
          setFormValues(row);
          setOpenModal(true);
          return setModalType("Update");
        },
      },
    ];

    return (
      <Menu
        as="div"
        id="actionButton"
        className="relative inline-block text-left"
      >
        <div>
          <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
          </Menu.Button>
        </div>
        <Transition appear>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
          </Transition.Child>

          <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
            <div className="flex flex-col py-2">
              {actionList?.map((item, index) => {
                const { label, svgIcon, itemFunction, _id } = item;
                return (
                  <Menu.Item onClick={() => itemFunction(row)} key={_id}>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                      >
                        <img src={svgIcon} alt={svgIcon} />
                        <span
                          className={`${
                            index === 2 ? "text-red-600" : ""
                          } text-neutralblack font-normal`}
                        >
                          {label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const askConfirmation = () => {
    if (modalType === "Add") {
      return apiFunction();
    } else {
      return openConfirmModal({
        title: "Are You Sure",
        message: "Are you sure you want to update this ?",
        confirmNameBtn: "Yes, Update",
        closeNameBtn: "Cancel",
        modalAction: () => apiFunction(),
      });
    }
  };

  const apiFunction = async () => {
    setbtnLoading(true);

    const { code, description, value, maxUsage, _id } = formValues || {};

    if (!code || !description || !value || !maxUsage) {
      setbtnLoading(false);
      return setValidate(true);
    }

    function getApiRoute() {
      return modalType === "Add"
        ? routesPostApi("/promocode", formValues)
        : routesPutApi(`/promocode/${_id}`, formValues);
    }

    await getApiRoute().then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message:
            modalType === "Add"
              ? "Added Successfully!"
              : "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        handleCloseModal();
        return storePromoCodeDetails();
      } else {
        setbtnLoading(false);
      }
    });
  };

  const showAddUpdateModal = () => {
    const body = () => {
      const formContent = [
        {
          _id: 1,
          value: formValues.code,
          label: "Code",
          isEmpty: validate,
          fieldType: "text",
          onChange: handleOnChange,
          name: "code",
          type: "text",
          grid: 1,
        },
        {
          _id: 2,
          value: formValues.description,
          label: "Description",
          isEmpty: validate,
          fieldType: "text",
          onChange: handleOnChange,
          name: "description",
          type: "text",
          grid: 1,
        },
        {
          _id: 3,
          value: formValues.value,
          label: "Amount",
          isEmpty: validate,
          fieldType: "number",
          onChange: handleOnChange,
          name: "value",
          type: "number",
          placeholder: "00",
          grid: 1,
        },
        {
          _id: 4,
          value: formValues.maxUsage,
          label: "Maximum Usage",
          isEmpty: validate,
          fieldType: "number",
          onChange: handleOnChange,
          name: "maxUsage",
          type: "number",
          placeholder: "00",
          grid: 1,
        },
      ];
      return (
        <div class="grid grid-cols-1 gap-3">
          {formContent?.map((data) => (
            <CustomInput {...data} />
          ))}
        </div>
      );
    };

    const props = {
      body: body(),
      isOpen: openModal,
      width: "xl",
      header: modalType === "Add" ? "Add New Promo Code" : "Update  Promo Code",
      modalType: modalType,
      closeFunction: handleCloseModal,
      closeBtnName: "Cancel",
      submitFunction: askConfirmation,
      submitBtnName: modalType === "Add" ? "Add" : "Update",
      headerStyle: "text-neutralblack",
      btnLoading: btnLoading,
    };

    return <GlobalModal props={props} />;
  };

  const showViewModal = () => {
    const { value, remaining, maxUsage, description, code } = formValues || {};
    const contentDetails = [
      {
        _id: 1,
        label: "Amount",
        value: `₱ ${formatNumber(value?.toFixed(2))} OFF`,
      },
      {
        _id: 2,
        label: "Remaining",
        value: remaining,
      },
      {
        _id: 3,
        label: "Max usage",
        value: maxUsage,
      },
      {
        _id: 4,
        label: "Description",
        value: description,
      },
    ];
    const viewModalContent = ({ label, value, _id }) => (
      <div className="col-span-4" key={_id}>
        {value && (
          <div className="grid grid-cols-4">
            <div className="text-darkgray text-sm col-span-4 md:col-span-1">
              {label}
            </div>
            <div
              className={`text-base col-span-4 md:col-span-3 break-all ml-2 ${
                _id === 1 ? "font-sans" : ""
              }`}
            >
              {value}
            </div>
          </div>
        )}
      </div>
    );

    const body = () => {
      return (
        <div class="grid grid-cols-4 gap-4 p-2 border rounded-lg border-neutralwhite">
          {contentDetails.map((item) => viewModalContent(item))}
        </div>
      );
    };

    const closeModal = () => {
      setOpenViewModal(false);
      setFormValues({});
    };
    const props = {
      body: body(),
      isOpen: openViewModal,
      width: "2xl",
      header: `Promo Code : ${code}`,
      modalType: "View",
      hideCloseBtn: true,
      submitFunction: closeModal,
      submitBtnName: "Close",
      headerStyle: "text-neutralblack truncate",
    };

    return <GlobalModal props={props} />;
  };

  return (
    <>
      <NavBar title="Promo Code" />
      {openModal && showAddUpdateModal()}
      {openViewModal && showViewModal()}
      <DataTable
        rows={promoCode}
        columns={columns}
        title="List of promo codes"
        btnName="Promo Code"
        csvtitle="promocode.csv"
        setOpenModal={setOpenModal}
        setModalType={setModalType}
      />
    </>
  );
}

export default PromoCode;
