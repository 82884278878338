export const styleStatusColor = (status) => {
  switch (status) {
    case "approved":
      return "text-successgreen";
    case "declined":
      return "text-archivered";
    case "cancelled":
      return "text-archivered";
    case "pending":
      return "text-lightgray";
    default:
      break;
  }
};
