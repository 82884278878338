import React, { useEffect, useState } from "react";
import pickAppLogo from "Assets/Logo/pickapp_logo.png";
import * as RiIcons from "react-icons/ri";
import { routesPutApi } from "Api/api";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function AccountSetPassword() {
  const { search } = useLocation();
  const { token } = queryString.parse(search);

  const [showPassword, setShowPassword] = useState(false);
  const [isSetPassSuccess, setIsSetPassSuccess] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(false);
  useEffect(() => {
    if (isSubmitted) {
      if (
        formValues.password &&
        formValues.confirmPass &&
        formValues.password !== "" &&
        formValues.confirmPass !== ""
      ) {
        if (formValues.password === formValues.confirmPass)
          return setIsSamePassword(true);
        else return setIsSamePassword(false);
      }
    }
  }, [isSubmitted, formValues]);

  const btnChangePassword = async () => {
    setIsSubmitted(true);
    if (formValues.password === formValues.confirmPass) {
      await routesPutApi(`/auth/setpass/${token}`, {
        password: formValues.password,
      }).then((res) => {
        if (res.status === 201) {
          setFormValues({});
          setIsSetPassSuccess(true);
        }
      });
    }
  };

  const showPasswordInput = () => {
    return (
      <>
        {showPassword ? (
          <RiIcons.RiEyeLine
            className="absolute right-0 top-2.5 mx-2 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <RiIcons.RiEyeOffLine
            className="absolute right-0 top-2.5 mx-2 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </>
    );
  };

  const showConfirmPasswordInput = () => {
    return (
      <>
        {showConfirmPassword ? (
          <RiIcons.RiEyeLine
            className="absolute right-0 top-2.5 mx-2 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        ) : (
          <RiIcons.RiEyeOffLine
            className="absolute right-0 top-2.5 mx-2 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        )}
      </>
    );
  };

  const resetpasswordForm = () => {
    return (
      <div className="w-full">
        <div className="text-neutralblack font-medium text-lg pb-7">
          Set a password for your account
        </div>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex flex-col gap-0.5">
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="border border-neutralgray rounded-md py-2 focus:outline-none w-full px-2 text-sm "
                value={formValues.password}
                name="password"
                placeholder="New Password"
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
              />
              {showPasswordInput()}
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="border border-neutralgray rounded-md py-2 focus:outline-none w-full px-2  text-sm"
                value={formValues.confirmPass}
                name="confirmPass"
                placeholder="Confirm Password"
                onChange={(e) =>
                  setFormValues({ ...formValues, confirmPass: e.target.value })
                }
              />
              {showConfirmPasswordInput()}
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            {isSamePassword &&
              isSubmitted &&
              formValues.password &&
              formValues.confirmPass && (
                <div className="text-successgreen text-xs">Password match</div>
              )}
            {!isSamePassword &&
              isSubmitted &&
              formValues.password &&
              formValues.confirmPass && (
                <div className="text-archivered text-xs">
                  Password not match
                </div>
              )}
          </div>
          <div className="flex flex-col gap-3 items-center ">
            <button
              className="gradientBg text-white text-sm rounded-full w-full py-3"
              onClick={() => {
                btnChangePassword();
              }}
            >
              Set Password
            </button>
          </div>
        </div>
      </div>
    );
  };

  const passwordResetSuccess = () => {
    return (
      <div className="flex flex-col gap-5 justify-center items-center px-7">
        <div className="flex flex-row gap-1">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.35424 2.57436C3.9237 2.73424 2.77864 3.8633 2.61312 5.27844C2.16851 9.07987 2.16851 12.9202 2.61312 16.7216C2.77864 18.1367 3.9237 19.2658 5.35424 19.4257C9.07527 19.8416 12.9248 19.8416 16.6458 19.4257C18.0764 19.2658 19.2214 18.1367 19.387 16.7216C19.7064 13.9902 19.7963 11.2387 19.6567 8.49594C19.6531 8.42511 19.6796 8.35603 19.7297 8.30588L21.0282 7.00741C21.1785 6.85711 21.4353 6.95109 21.4512 7.16304C21.6963 10.4192 21.629 13.693 21.2493 16.9394C20.9807 19.2356 19.1371 21.0339 16.8541 21.2891C12.9947 21.7204 9.00542 21.7204 5.14598 21.2891C2.863 21.0339 1.01938 19.2356 0.750817 16.9394C0.289279 12.9933 0.289279 9.00678 0.750817 5.06062C1.01938 2.76442 2.863 0.966119 5.14598 0.710963C9.00542 0.279614 12.9947 0.279614 16.8541 0.710963C17.6582 0.80083 18.4077 1.08212 19.0532 1.50916C19.1802 1.59319 19.1928 1.77174 19.0851 1.87942L18.0815 2.88303C17.9994 2.96512 17.8721 2.97893 17.7698 2.9241C17.428 2.74098 17.0482 2.61933 16.6458 2.57436C12.9248 2.15848 9.07527 2.15848 5.35424 2.57436Z"
              fill="#72BB71"
            />
            <path
              d="M22.2879 3.53794C22.6541 3.17182 22.6541 2.57823 22.2879 2.21211C21.9218 1.846 21.3282 1.846 20.9621 2.21211L10.375 12.7992L7.28794 9.71211C6.92182 9.346 6.32823 9.346 5.96211 9.71211C5.59599 10.0782 5.59599 10.6718 5.96211 11.0379L9.71211 14.7879C10.0782 15.1541 10.6718 15.1541 11.0379 14.7879L22.2879 3.53794Z"
              fill="#72BB71"
            />
          </svg>
          <div className="text-successgreen">Your account is now active!</div>
        </div>
        <div className="flex flex-col gap-1 justify-center items-center">
          <div className="text-xs">
            Your account has been activated successfully
          </div>
          <div className="text-xs">You can now log in to your account.</div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-screen bg-lightyellow flex justify-center items-center p-5">
      <div className="flex flex-col gap-5 w-full justify-center items-center">
        <div className="flex justify-center">
          <img src={pickAppLogo} alt="pickAppLogo" />
        </div>
        <div className="w-full max-w-lg bg-white p-5 rounded-xl">
          {isSetPassSuccess ? passwordResetSuccess() : resetpasswordForm()}
        </div>
      </div>
    </div>
  );
}

export default AccountSetPassword;
