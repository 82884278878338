import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const vehicleObjectDetails = {
  vehicle: [],
  unassignedVehicle: [],
};

const storeUnassignedVehicle = async (set, data) => {
  return set({
    unassignedVehicle: data,
  });
};

const apiRequestUnassigned = async (set) => {
  await routesGetApi("/vehicle/?unassigned=1").then((res) => {
    if (res.status === 200) {
      return set({
        unassignedVehicle: res.data,
      });
    }
  });
};

const storeUnassigned = async (set) => {
  return apiRequestUnassigned(set);
};

const storeUnassignedTimeout = async (set) => {
  return APIFetch(() => {
    apiRequestUnassigned(set);
  });
};

const apiRequest = async (set) => {
  return await routesGetApi("/vehicle").then((res) => {
    if (res.status === 200) {
      return set({
        vehicle: res.data,
      });
    }
  });
};

const storeVehicle = async (set) => {
  return apiRequest(set);
};

const storeVehicleTimeout = async (set) => {
  return APIFetch(() => {
    apiRequest(set);
  });
};

const storeAllVehicle = async (set, data) => {
  return set({
    vehicle: data,
  });
};

const vehicleStoreObject = (set) => ({
  ...vehicleObjectDetails,
  storeVehicleDetails: () => storeVehicle(set),
  fetchVehicle: () => storeVehicleTimeout(set),
  storeAvailableVehicle: () => storeUnassigned(set),
  fetchUnassignVehicle: () => storeUnassignedTimeout(set),
  getAllVehicle: (data) => storeAllVehicle(set, data),
  getUnassignedVehicle: (data) => storeUnassignedVehicle(set, data),
});

export const vehicleStore = create(vehicleStoreObject);
