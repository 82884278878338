import create from "zustand";
import { routesGetApi } from "Api/api";
import moment from "moment";
import { APIFetch } from "Utils/timeout";

const dasboardObjectDetails = {
  booking: 0,
  driver: 0,
  monthlynotif: [],
};

const storeAllDasboard = async (set, booking, driver, monthlynotif) => {
  return set({
    booking: booking,
    driver: driver,
    monthlynotif: monthlynotif,
  });
};

const apiRequest = async (set) => {
  const dashboardBooking = routesGetApi("/dashboard/bookings");
  const dashboardDrivers = routesGetApi("/dashboard/drivers");
  const dashboardCommision = routesGetApi(
    `/dashboard/monthlycommission?&year_no=${moment()?.year()}`
  );

  return await Promise?.all([
    dashboardBooking,
    dashboardDrivers,
    dashboardCommision,
  ]).then((values) => {
    return set({
      booking: values[0]?.data,
      driver: values[1]?.data,
      monthlynotif: values[2]?.data,
    });
  });
};

const storeDashboardDataAction = async (set) => {
  return apiRequest(set);
};

const storeDashboardTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const dasboardStoreObject = (set) => ({
  ...dasboardObjectDetails,
  storeDashboardData: () => storeDashboardDataAction(set),
  fetchDashboard: () => storeDashboardTimeout(set),
  getAllDasboard: (booking, driver, monthlynotif) =>
    storeAllDasboard(set, booking, driver, monthlynotif),
});

export const dashboardStore = create(dasboardStoreObject);
