import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import shallow from "zustand/shallow";
import * as HiIcons from "react-icons/hi";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import { DataTable } from "Components/Global/GlobalTable";
import {
  routesDeleteApi,
  routesGetAllApi,
  routesGetApi,
  routesPostApi,
  routesPostNoHoc,
  routesPutApi,
} from "Api/api";
import { bucket } from "S3/upload";
import { toastAlert } from "Api/middleware";
import GlobalRequiredField from "Components/Global/GlobalRequiredField";
import NavBar from "Components/NavBar/NavBar";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import editIcon from "Assets/ActionIcon/editIcon.png";
import deleteIcon from "Assets/ActionIcon/deleteIcon.png";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import GlobalModal from "Components/Global/GlobalModal";
import * as BiIcons from "react-icons/bi";
import { vehicleStore } from "../../../Zustand/AdminStore/vehicleStore";
import { serviceStore } from "../../../Zustand/AdminStore/serviceStore";
import ViewVehicle from "./ViewVehicle";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import moment from "moment";
import * as Io5Icons from "react-icons/io5";
import DriverDetails from "../Driver/DriverDetails";
import LoadingLottie from "Components/Lottie/LoadingLottie";
import FullnameFormat from "Utils/FullnameFormat";
import { APIFetch } from "Utils/timeout";
import { MultiSelectInput } from "Components/Input/MultiSelectInput";
import { CustomInput } from "Components/Input/CustomInput";
import uploadFile from "Utils/uploadFile";

function Vehicle() {
  const { openConfirmModal, openSuccessModal, openDeleteModal } =
    alertModalStore((state) => state, shallow);
  const { vehicle, storeVehicleDetails, storeAvailableVehicle, fetchVehicle } =
    vehicleStore((state) => state, shallow);

  const { service } = serviceStore((state) => state, shallow);
  const {
    storeDriverDetails,
    unassignedDriver,
    storeAvailableDriver,
    fetchUnassignedDriver,
  } = driverStore((state) => state, shallow);

  const [openViewModal, setOpenViewModal] = useState(false);
  const [openVehicleForm, setOpenVehicleForm] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [validate, setValidate] = useState(false);
  const inputRef = useRef();
  const inputRefUploadFile = useRef();
  const [modalType, setModalType] = useState("");
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [images, setImages] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [newFile, setNewFile] = useState("");
  const [newFileType, setNewFileType] = useState("");
  const [delDocuments, setDelDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchDocuments, setSearchDocuments] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);

  useEffect(() => {
    fetchVehicle();
    fetchUnassignedDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchValue = () => {
      if (searchDocuments) {
        if (documents?.length > 0) {
          const filtered = documents.filter(
            ({ file, fileType }) =>
              file.toLowerCase()?.includes(searchDocuments) ||
              fileType.toLowerCase()?.includes(searchDocuments)
          );
          return setFilteredDocuments(filtered);
        }
      } else {
        return setFilteredDocuments(documents);
      }
    };
    searchValue();
  }, [documents, searchDocuments]);

  const handleCloseModal = () => {
    setOpenVehicleForm(false);
    setFormValues({});
    setImages([]);
    setDelDocuments([]);
    setDocuments([]);
    setIsEmpty(false);
    setValidate(false);
    setShowDocument(false);
    setShowVehicleDetails(false);
    setbtnLoading(false);
  };

  useEffect(() => {
    restrucDriverList();
    restrucServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassignedDriver, service, modalType]);

  const restrucDriverList = () => {
    let storeDriver = [];
    let extraOption = {
      value: null,
      label: "Select Driver",
    };
    if (unassignedDriver?.length > 0) {
      unassignedDriver?.map(({ _id, firstName, middleName, lastName }) => {
        const params = {
          value: _id,
          label: `${firstName} ${
            middleName ? middleName?.charAt(0) : ""
          } ${lastName}`,
        };
        return storeDriver.push(params);
      });
      setDriverOptions([extraOption, ...storeDriver]);
    } else return setDriverOptions([extraOption]);
  };

  const restrucServiceList = () => {
    let storeService = [];
    if (service?.length > 0) {
      service?.map(({ _id, vehicleType }) => {
        const params = {
          value: _id,
          label: vehicleType,
        };
        return storeService.push(params);
      });
      setServiceOptions(storeService);
    }
  };

  const onChange = async (imageList) => {
    setFormValues({
      ...formValues,
      image: URL.createObjectURL(imageList.target.files[0]),
    });
    return setImages(imageList);
  };

  const handleOnChange = ({ target }) => {
    const { value, name } = target ?? {};
    setFormValues({ ...formValues, [name]: value });
  };

  const selectHandleOnChange = (e, name) => {
    setFormValues({ ...formValues, [name]: e });
  };

  const askConfirmation = () => {
    const params = {
      fileContent: images,
      apiFunction: apiFunction,
      path: "vehicle_image/",
      applyValidate: true,
      setbtnLoading,
    };

    if (modalType === "Add") {
      setbtnLoading(true);
      uploadFile({ ...params });
    } else {
      openConfirmModal({
        title: "Are You Sure",
        message: "Are you sure you want to update this?",
        confirmNameBtn: "Yes, Update",
        closeNameBtn: "Cancel",
        modalAction: () => {
          uploadFile({ ...params });
        },
      });
    }
  };

  const columns = [
    {
      dataField: "image",
      text: "Image",
      formatter: (data) => {
        return (
          <div>
            {data ? (
              <img
                src={data}
                className="h-10 w-10 rounded-md shadow-sm"
                alt={data}
              />
            ) : (
              <div className="border border-lightgray/70 rounded-md p-2 h-10 w-10 shadow-sm flex justify-center items-center">
                <RiIcons.RiCarFill className="text-lightgray/70 text-xl" />
              </div>
            )}
          </div>
        );
      },
    },
    {
      dataField: "plate_no",
      text: "Plate Number",
      csvExport: true,
      formatter: (data, row) => {
        return (
          <div
            onClick={() => getVehicleDetails(row)}
            className="text-lightblue cursor-pointer"
          >
            {data}
          </div>
        );
      },
    },
    {
      dataField: "driver_id",
      text: "Driver",
      csvExport: true,
      formatter: (_data, { driver_id }) => {
        return (
          <div
            onClick={() => driver_id && getDriverDetails(driver_id)}
            className={`${driver_id ? "text-lightblue cursor-pointer" : ""}`}
          >
            {driver_id ? FullnameFormat({ props: driver_id }) : "--"}
          </div>
        );
      },
    },
    {
      dataField: "serviceType_id.vehicleType",
      text: "TODA",
      csvExport: true,
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md text-ellipsis overflow-hidden">
            {data}
          </div>
        );
      },
    },
    {
      dataField: "model",
      text: "Model",
      csvExport: true,
      formatter: (data) => {
        return <div>{data}</div>;
      },
    },
    {
      dataField: "color",
      text: "Color",
      csvExport: true,
      formatter: (data) => {
        return <div>{data}</div>;
      },
    },
    {
      dataField: "brand",
      text: "Brand",
      csvExport: true,
      formatter: (data) => {
        return <div>{data}</div>;
      },
    },

    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => {
        return (
          <Menu
            as="div"
            id="actionButton"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
              </Menu.Button>
            </div>
            <Transition appear>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
              </Transition.Child>

              <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
                <div className="flex flex-col py-2">
                  {actionList?.map((item, index) => {
                    const { label, svgIcon, itemFunction, _id } = item;
                    return (
                      <Menu.Item onClick={() => itemFunction(row)} key={_id}>
                        {() => (
                          <div
                            className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                          >
                            <img src={svgIcon} alt={svgIcon} />
                            <span
                              className={`${
                                index === 2 ? "text-red-600" : ""
                              } text-neutralblack font-normal`}
                            >
                              {label}
                            </span>
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      },
    },
  ];

  const getVehicleDetails = async ({ _id }) => {
    function apiRequest() {
      return routesGetApi(`/vehicle/${_id}`);
    }
    APIFetch(async () => {
      await apiRequest().then(({ data, status }) => {
        if (status === 200) {
          setShowVehicleDetails(true);
          setFormValues(data);
        }
      });
    });
  };

  const actionList = [
    {
      _id: 1,
      label: "View",
      svgIcon: viewIcon,
      itemFunction: (row) => getVehicleDetails(row),
    },
    {
      _id: 2,
      label: "Update",
      svgIcon: editIcon,
      itemFunction: (row) => {
        const { serviceType_id, driver_id } = row;
        const { vehicleType, _id } = serviceType_id ?? {};
        const serviceTypeParams = {
          label: vehicleType,
          value: _id,
        };

        if (driver_id) {
          const { firstName, middleName, lastName } = driver_id ?? {};
          const params = {
            label: `${firstName} ${
              middleName ? middleName?.charAt(0) : ""
            } ${lastName}`,
            value: driver_id._id,
          };

          setFormValues({
            ...row,
            serviceType_id: serviceTypeParams,
            driver_id: params,
          });
        } else {
          setFormValues({
            ...row,
            serviceType_id: serviceTypeParams,
          });
        }

        setDocuments(row?.documents);
        setOpenVehicleForm(true);
        setModalType("Update");
      },
    },
    {
      _id: 3,
      label: "Delete",
      svgIcon: deleteIcon,
      itemFunction: (row) => {
        openDeleteModal({
          title: "Delete",
          isDelete: true,
          message:
            "Are you sure you want to delete this? This action can not be undone.",
          confirmNameBtn: "Yes, Delete",
          closeNameBtn: "Cancel",
          modalAction: () => {
            archiveVehicle(row._id);
          },
        });
      },
    },
  ];

  const apiFunction = async (s3ImagePath) => {
    const params = {
      ...formValues,
      serviceType_id: formValues?.serviceType_id?.value ?? null,
      driver_id: formValues?.driver_id?.value ?? null,
      image: s3ImagePath ? s3ImagePath : formValues.image,
      documents: documents?.filter(({ _id }) => !_id),
      del_docs: delDocuments,
    };

    function getApiRoute() {
      return modalType === "Add"
        ? routesPostApi("/vehicle", params)
        : routesPutApi(`/vehicle/${formValues?._id}`, params);
    }

    await getApiRoute().then((res) => {
      if (res.status === 201) {
        setbtnLoading(false);
        openSuccessModal({
          title: "Success",
          message:
            modalType === "Add"
              ? "Added Successfully!"
              : "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        handleCloseModal();
        storeAvailableDriver();
        storeAvailableVehicle();
        return storeVehicleDetails();
      }
    });
  };

  const archiveVehicle = async (rowId) => {
    await routesDeleteApi(`/vehicle/${rowId}`).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return storeVehicleDetails();
      }
    });
  };

  const backButton = () => {
    return (
      <div>
        <button
          className="flex flex-row gap-1 items-center text-pickAppRedDefault"
          onClick={() => handleCloseModal()}
        >
          <RiIcons.RiArrowLeftLine />
          <span>Back</span>
        </button>
      </div>
    );
  };

  const stepper = () => {
    const formTitle = modalType === "Add" ? "Add New" : "Update";
    return (
      <div className="flex flex-col">
        <div className="font-medium text-neutralblack text-lg">
          {formTitle} Vehicle
        </div>
        <div className="flex justify-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <div
              className={`p-2 border border-${
                showDocument ? "successgreen" : ""
              }  rounded-full `}
              style={{
                borderColor: showDocument ? "" : "#BE2B27",
                backgroundColor: showDocument ? "" : "lightyellow",
              }}
            >
              {showDocument ? (
                <RiIcons.RiCheckLine className="text-successgreen " />
              ) : (
                <RiIcons.RiCarFill className="text-pickAppRedDefault" />
              )}
            </div>
            <div
              className={`text-${
                showDocument ? "neutraldark delay-500" : "pickAppRedDefault"
              } text-sm`}
            >
              Vehicle Information
            </div>
          </div>
          <div className="w-1/6 align-center items-center align-middle content-center flex">
            <div
              className={`w-full ${
                showDocument
                  ? "stepBar bg-pickAppRedDefault"
                  : "bg-neutralgray/50 stepBar"
              } rounded items-center align-middle align-center flex-1`}
            >
              <div className=" text-xs leading-none py-0.5 text-center  rounded "></div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div
              className={`p-2 border ${
                showDocument
                  ? "border-pickAppRedDefault bg-[#FCF7DE] delay-700 text-pickAppRedDefault"
                  : "neutralgray/50 text-neutralgray/50"
              } rounded-full`}
            >
              {showDocument ? (
                <RiIcons.RiFileUploadFill />
              ) : (
                <RiIcons.RiFileUploadLine />
              )}
            </div>
            <div
              className={`text-${
                showDocument ? "pickAppRedDefault delay-700" : "neutralgray"
              } text-sm `}
            >
              Documents
            </div>
          </div>
        </div>
      </div>
    );
  };

  const uploadImage = () => {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex flex-col">
          <div className="text-xs text-darkgray">
            Image
            <GlobalRequiredField
              fieldType="text"
              isEmpty={validate}
              value={formValues.image}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-10">
            <div className="flex justify-center items-center">
              {formValues.image ? (
                <div className="flex justify-center items-center relative">
                  <img
                    src={formValues.image}
                    alt={formValues.image}
                    className="border border-pickAppRedDefault h-60 flex justify-center items-center w-80  rounded-md z-40 "
                    onClick={() => inputRef.current.click()}
                  />
                  <div
                    className="z-40 absolute  group/item w-full h-full hover:bg-black/50 rounded-md hover:cursor-pointer "
                    onClick={() => inputRef.current.click()}
                  >
                    <div className="absolute top-0 left-0 z-40 text-white h-full w-full  group/edit invisible  group-hover/item:visible flex flex-col gap-1 justify-center items-center">
                      <BsIcons.BsCamera className="text-3xl" />
                      <div className="text-xs">Change picture</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-[#F5F5F5] h-60 flex justify-center items-center w-80  rounded-sm"
                  onClick={() => inputRef.current.click()}
                >
                  <HiIcons.HiOutlinePlus size={20} className="text-lightblue" />
                </div>
              )}

              <input
                accept="image/*"
                type="file"
                ref={inputRef}
                hidden
                onChange={(imageList) => onChange(imageList)}
              />
            </div>
            <div className="flex justify-center items-center">
              <span className="text-archivered">*</span>{" "}
              <div className="text-sm text-lightblue">
                Upload size must at least 150 pixels x 150 pixels and with file
                extensions of .jpg or .png only.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showVehicleForm = () => {
    const vehicleForm = () => {
      const formContent = [
        {
          _id: 1,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.plate_no,
          onChange: handleOnChange,
          type: "text",
          label: "Plate Number",
          name: "plate_no",
        },
        {
          _id: 2,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.brand,
          onChange: handleOnChange,
          type: "text",
          label: "Brand",
          name: "brand",
        },
        {
          _id: 3,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.model,
          onChange: handleOnChange,
          type: "text",
          label: "Model",
          name: "model",
        },
        {
          _id: 4,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.color,
          onChange: handleOnChange,
          type: "text",
          label: "Color",
          name: "color",
        },
        {
          _id: 5,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.manufacturer,
          onChange: handleOnChange,
          type: "text",
          label: "Manufacturer",
          name: "manufacturer",
        },
        {
          _id: 6,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.engineNo,
          onChange: handleOnChange,
          type: "text",
          label: "Engine Number",
          name: "engineNo",
        },
        {
          _id: 7,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.chassisNo,
          onChange: handleOnChange,
          type: "text",
          label: "Chassis Number",
          name: "chassisNo",
        },
        {
          _id: 8,
          fieldType: "text",
          isEmpty: validate,
          value: formValues.mvFileNo,
          onChange: handleOnChange,
          type: "text",
          label: "MV File Number",
          name: "mvFileNo",
        },
      ];
      return (
        <div className="flex flex-col gap-5">
          {uploadImage()}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <MultiSelectInput
              onChange={(e) => selectHandleOnChange(e, "serviceType_id")}
              label="TODA"
              value={formValues.serviceType_id}
              options={serviceOptions ?? []}
              placeholder="Select"
              errorValue={formValues?.serviceType_id?.value ?? null}
              isEmpty={validate}
              fieldType="text"
            />
            {formContent?.map((data) => (
              <CustomInput {...data} />
            ))}
            <MultiSelectInput
              onChange={(e) => selectHandleOnChange(e, "driver_id")}
              label="Driver"
              subLabel="Optional"
              value={formValues.driver_id}
              options={driverOptions ?? []}
              placeholder="Select Driver"
              fieldType="text"
            />
          </div>
        </div>
      );
    };

    const vehicleBtns = () => {
      const nextBtn = async () => {
        const {
          serviceType_id,
          brand,
          chassisNo,
          color,
          manufacturer,
          model,
          plate_no,
          mvFileNo,
          engineNo,
          image,
          _id,
        } = formValues ?? {};
        if (
          !serviceType_id ||
          !brand ||
          !chassisNo ||
          !color ||
          !manufacturer ||
          !model ||
          !plate_no ||
          !mvFileNo ||
          !engineNo ||
          !image
        ) {
          setValidate(true);
        } else {
          const params = {
            plate_no,
            mvFileNo,
            engineNo,
            chassisNo,
            _id,
          };

          await routesPostNoHoc(`/vehicle/validatedata`, params).then(
            ({ status }) => {
              if (status !== 422) {
                setShowDocument(true);
              }
            }
          );
        }
      };
      return (
        <div className="flex flex-row justify-end">
          <button
            className="gradientBg p-1 rounded-md text-white px-5 text-sm flex flex-row gap-2 items-center"
            onClick={() => nextBtn()}
          >
            Next <RiIcons.RiArrowRightLine />
          </button>
        </div>
      );
    };

    const documentBtns = () => {
      const prevFunc = () => {
        setSearchDocuments("");
        setShowDocument(false);
      };
      return (
        <div className="flex flex-row justify-end gap-2">
          <button
            className="gradientBg p-1 rounded-md text-white px-3 text-sm flex flex-row gap-2 items-center"
            onClick={() => prevFunc()}
          >
            <RiIcons.RiArrowLeftLine /> <span>Previous page</span>
          </button>
          <button
            className="gradientBg p-1 rounded-md text-white px-5 text-sm flex flex-row gap-2 items-center justify-center"
            onClick={() => askConfirmation()}
            disabled={btnLoading}
          >
            {btnLoading ? <LoadingLottie /> : " Save"}
          </button>
        </div>
      );
    };

    const removeFileBtn = (index, data) => {
      const copyDocuments = [...documents];
      copyDocuments.splice(index, 1);
      setDocuments(copyDocuments);
      openSuccessModal({
        title: "Success",
        message: "Your changes has been successfully saved.",
        closeNameBtn: "Back",
      });

      if (data?._id) {
        let delListCopy = [...delDocuments];
        delListCopy.push(data);
        return setDelDocuments(delListCopy);
      }
    };

    const documentForm = () => {
      const documentList = () => {
        return (
          <div className="flex flex-col gap-2 divide-y-2">
            {filteredDocuments?.length > 0 ? (
              filteredDocuments?.map((data, index) => {
                const { file, fileType, date_added } = data;
                const getFileName = file?.split("/");
                return (
                  <div
                    className="grid grid-cols-3 items-center pt-2"
                    key={index}
                  >
                    <div className="flex flex-col gap-1">
                      <div className="text-sm font-medium">{fileType}</div>
                      <div className="text-[#929292] text-sm flex flex-row gap-1 items-center">
                        <RiIcons.RiFilePdfLine />{" "}
                        <span>{getFileName[getFileName?.length - 1]}</span>
                      </div>
                    </div>
                    <div className="text-sm text-neutraldark text-center">
                      Date Added: {moment(date_added).format("LL")}
                    </div>
                    <div className="flex justify-end ">
                      <div
                        id="delBtn"
                        className="delBtn group relative inline-blockduration-300 "
                      >
                        <button
                          className="text-neutralgray text-lg hover:text-archivered"
                          onClick={() => {
                            openDeleteModal({
                              title: "Delete",
                              isDelete: true,
                              message:
                                "Are you sure you want to delete this? This action can not be undone.",
                              confirmNameBtn: "Yes, Delete",
                              closeNameBtn: "Cancel",
                              modalAction: () => {
                                removeFileBtn(index, data);
                              },
                            });
                          }}
                        >
                          <Io5Icons.IoTrash />
                        </button>
                        <span className="absolute hidden group-hover:flex -left-4 -top-2 -translate-y-full px-2 py-1 bg-archivered rounded-lg text-center text-creamwhite text-xs after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-4 after:border-x-transparent after:border-b-transparent after:border-t-archivered">
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-lightgray text-sm flex justify-center items-center">
                {searchDocuments ? "Empty results." : "No documents added."}
              </div>
            )}
          </div>
        );
      };
      const searchBar = () => {
        return (
          <div className="flex flex-row w-fit">
            <div className="flex items-center border-y border-l border-neutralgray bg-white relative pl-2 rounded-l-md">
              <BiIcons.BiSearch className="text-neutralgray" />
            </div>
            <input
              id="tableSearch"
              className="border-y w-full border-r border-neutralgray rounded-y-md rounded-r-md py-1 px-1.5 placeholder:text-darkgray placeholder:text-xs placeholder:font-normal focus:outline-none"
              placeholder="Search.."
              type="text"
              vale={searchDocuments}
              onChange={(e) => setSearchDocuments(e.target.value)}
            />
          </div>
        );
      };
      return (
        <div className="flex flex-col gap-5">
          <div className="flex flex-row justify-between">
            {searchBar()}
            <div>
              <button
                className="flex flex-row gap-2 items-center text-pickAppRedDefault"
                onClick={() => setAddFile(true)}
              >
                <div className="border rounded-md border-pickAppRedDefault">
                  <RiIcons.RiAddLine />
                </div>
                <span className="font-medium">Add File</span>
              </button>
            </div>
          </div>
          <div>{documentList()}</div>
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-5">
        {backButton()}
        <div className="bg-white rounded-lg p-10 gap-5 flex flex-col shadow-lg">
          {stepper()}

          {!showDocument ? vehicleForm() : documentForm()}
          {!showDocument ? vehicleBtns() : documentBtns()}
        </div>
      </div>
    );
  };

  const table = () => {
    const props = {
      setOpenViewModal,
    };
    return !openViewModal ? (
      <DataTable
        rows={vehicle}
        columns={columns}
        title="List of vehicles"
        btnName="Vehicle"
        csvtitle="vehicle.csv"
        setOpenModal={setOpenVehicleForm}
        setModalType={setModalType}
      />
    ) : (
      <DriverDetails props={props} />
    );
  };

  const onChangeDocumentUpload = async (document) => {
    return setNewFile(document);
  };

  const checkFileUpload = () => {
    const params = {
      fileContent: newFile,
      apiFunction: documentUpload,
      path: "vehicle_documents/",
      applyValidate: false,
    };

    uploadFile({ ...params });
  };

  const documentUpload = (file) => {
    let listOfDocuments = [...documents];
    listOfDocuments.push({
      fileType: newFileType,
      file: file,
      date_added: moment().format("LL"),
    });
    closeUploadFile();
    return setDocuments(listOfDocuments);
  };

  const closeUploadFile = () => {
    setNewFile("");
    setNewFileType("");
    setAddFile(false);
    setIsEmpty(false);
    return openSuccessModal({
      title: "Success",
      message: "Added Successfully!",
      closeNameBtn: "Back",
    });
  };

  const showAddUpdateModal = () => {
    const getFileName = newFile?.target?.files[0]?.name ?? null;

    const body = () => {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col  w-full">
            <label className="text-neutraldarkgray text-xs">
              File Type :{" "}
              <GlobalRequiredField
                fieldType="text"
                isEmpty={isEmpty}
                value={newFileType}
              />
            </label>
            <select
              className="customInput"
              onChange={(e) => setNewFileType(e.target.value)}
            >
              <option value="select">Select</option>
              <option value="Vehicle Document Type">
                Vehicle Document Type
              </option>
            </select>
          </div>
          <div className="flex flex-col  w-full">
            <label className="text-neutraldarkgray text-xs">
              Please choose a file :{" "}
              <GlobalRequiredField
                fieldType="text"
                isEmpty={isEmpty}
                value={newFile}
              />
            </label>{" "}
            <div className="flex flex-row relative border border-neutraldark2 rounded-lg">
              <div className="w-full   h-9  px-2  flex items-center gap-1">
                {getFileName ? (
                  <a
                    href={URL.createObjectURL(newFile.target.files[0])}
                    target="blank"
                    rel="noopener noreferrer"
                    className="flex flex-row items-center gap-2 text-lightblue underline"
                  >
                    <AiIcons.AiOutlineFilePdf />{" "}
                    <span className="w-72 truncate">{getFileName}</span>
                  </a>
                ) : (
                  <span className="text-base text-lightgray">
                    * File size must at least 4MB
                  </span>
                )}
              </div>
              <button
                className="bg-lightblue rounded-lg px-3 w-40 flex gap-2 items-center justify-center text-white"
                onClick={() => inputRefUploadFile.current.click()}
              >
                <RiIcons.RiFolderOpenLine />{" "}
                <span className="text-xs ">Select a File</span>
              </button>
              <input
                type="file"
                ref={inputRefUploadFile}
                hidden
                onChange={(imageList) => onChangeDocumentUpload(imageList)}
              />
            </div>
          </div>
        </div>
      );
    };

    const closeModal = () => {
      setNewFile("");
      setNewFileType("select");
      setAddFile(false);
      setIsEmpty(false);
    };

    const checkFile = () => {
      if (!newFile || newFileType === "select") {
        setIsEmpty(true);
      } else {
        checkFileUpload();
      }
    };

    const props = {
      body: body(),
      isOpen: addFile,
      width: "lg",
      header: "Upload Documents",
      modalType: "View",
      closeFunction: closeModal,
      closeBtnName: "Cancel",
      submitFunction: checkFile,
      submitBtnName: "Add",
      headerStyle: "text-neutralblack",
    };

    return <GlobalModal props={props} />;
  };

  const getDriverDetails = async ({ _id }) => {
    if (_id) {
      setOpenViewModal(true);
      storeDriverDetails(_id);
    }
  };

  return (
    <>
      <NavBar title="Vehicle" />
      {showAddUpdateModal()}
      {!showVehicleDetails ? (
        <>{openVehicleForm ? showVehicleForm() : table()}</>
      ) : (
        <ViewVehicle details={formValues} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
}

export default Vehicle;
