import React, { useEffect, useState } from "react";
import * as FiIcons from "react-icons/fi";
import { routesPutApi } from "Api/api";
import shallow from "zustand/shallow";
import { alertModalStore } from "../../Zustand/AlertModalStore/alertModalStore";
import GlobalRequiredField from "Components/Global/GlobalRequiredField";

const SetPassword = ({ token, setShowResetPassword }) => {
  const [formValues, setFormValues] = useState({});

  const { openSuccessModal } = alertModalStore((state) => state, shallow);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPassMatch, setIsPassMatch] = useState(false);
  const apiFunction = async (e) => {
    e.preventDefault();
    if (isPassMatch) {
      await routesPutApi(`/auth/setpass/${token}`, {
        password: formValues.password,
      }).then((res) => {
        if (res.status === 201) {
          openSuccessModal({
            title: "Success",
            message: "The password was successfully changed.",
            closeNameBtn: "Back",
          });
          setIsPassMatch(false);
          return setShowResetPassword(false);
        }
      });
    }
  };

  useEffect(() => {
    if (formValues.password && formValues.confirmPass) {
      if (formValues.password !== formValues.confirmPass) {
        setIsPassMatch(false);
      } else {
        setIsPassMatch(true);
      }
    }
  }, [formValues]);

  const showPasswordInput = () => {
    return (
      <>
        {showPassword ? (
          <FiIcons.FiEye
            className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <FiIcons.FiEyeOff
            className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </>
    );
  };

  const checkPassword = () => {
    if (
      (formValues?.password || formValues?.password === "") &&
      (formValues?.confirmPass || formValues?.confirmPass === "")
    ) {
      if (isPassMatch) {
        return "Password match";
      } else {
        return "Password not match";
      }
    }
  };

  const showConfirmPasswordInput = () => {
    return (
      <>
        {showConfirmPassword ? (
          <FiIcons.FiEye
            className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        ) : (
          <FiIcons.FiEyeOff
            className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col justify-center items-center gap-10 min-h-screen bg-LoginBG bg-cover bg-center">
      <div className="flex flex-col gap-10 border border-white bg-white rounded-2xl max-w-md w-full py-10 px-8">
        <div className="flex flex-col gap-5">
          <span className="text-xl text-neutralblack font-medium">
            Set New Password
          </span>
          <span className="text-sm text-archivered font-normal">
            You are currently using the default password to login. Please update
            your password to continue accessing this website.
          </span>
        </div>
        <form onSubmit={apiFunction} className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-0.5">
            <span className="text-neutraldarkgray font-normal text-xs px-1">
              New password{" "}
              <GlobalRequiredField
                fieldType="text"
                value={formValues.password}
              />
            </span>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="customInput"
                value={formValues.password}
                name="password"
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
              />
              {showPasswordInput()}
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <span className="text-neutraldarkgray font-normal text-xs px-1">
              Confirm password{" "}
              <GlobalRequiredField
                fieldType="text"
                value={formValues.confirmPass}
              />
            </span>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="customInput"
                value={formValues.confirmPass}
                name="confirmPass"
                onChange={(e) =>
                  setFormValues({ ...formValues, confirmPass: e.target.value })
                }
              />
              {showConfirmPasswordInput()}
            </div>
          </div>
          <div
            className={`flex flex-col text-xs ${
              isPassMatch ? "text-successgreen" : "text-archivered"
            } `}
          >
            {checkPassword()}
          </div>
          <div className="flex flex-col gap-3 items-center ">
            <button className="gradientBg text-creamwhite font-normal rounded-lg w-full py-2">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
