import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { routesGetApi } from "Api/api";

function Layout() {
  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return navigate("/login");
    } else {
      await routesGetApi("/users/info").then(({ data }) => {
        if (data.status === 200) {
          return navigate(`/${data.userRoles}/dashboard`);
        } else {
          localStorage.clear();
          return navigate(`/login`);
        }
      });
    }
  };

  return (
    <>
      <Outlet />
    </>
  );
}

export default Layout;
