import React, { useEffect, useRef, useState } from "react";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import GlobalRequiredField from "Components/Global/GlobalRequiredField";
import { vehicleStore } from "../../../../Zustand/AdminStore/vehicleStore";
import defaultProfile from "Assets/Image/defaultProfile.png";
import shallow from "zustand/shallow";
import * as AiIcons from "react-icons/ai";
import GlobalModal from "Components/Global/GlobalModal";
import * as Io5Icons from "react-icons/io5";
import { toastAlert } from "Api/middleware";
import moment from "moment";
import { alertModalStore } from "../../../../Zustand/AlertModalStore/alertModalStore";
import LoadingLottie from "Components/Lottie/LoadingLottie";
import { validateMobileNumber } from "Utils/validateMobileNumber";
import { validateEmailAddress } from "Utils/validateEmailAddress";
import uploadFile from "Utils/uploadFile";
import { CustomInput } from "Components/Input/CustomInput";
import { CustomSelect } from "Components/Input/CustomSelect";
import { CustomTextArea } from "Components/Input/CustomTextArea";
import { MultiSelectInput } from "Components/Input/MultiSelectInput";

function DriverForm({ props }) {
  const {
    modalType,
    validateFields,
    formValues,
    setFormValues,
    setImages,
    handleCloseModal,
    documents,
    setDocuments,
    setDelDocuments,
    delDocuments,
    btnLoading,
  } = props;

  const inputRefUploadFile = useRef();
  const formWarnngObject = {
    email: false,
    contactNumber: false,
  };

  const [formWarning, setFormWarning] = useState(formWarnngObject);
  const [showDocument, setShowDocument] = useState(false);
  const [validate, setValidate] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [newFile, setNewFile] = useState("");
  const [newFileType, setNewFileType] = useState("select");
  const [validateFile, setValidateFile] = useState(false);
  const { unassignedVehicle } = vehicleStore((state) => state, shallow);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const { openSuccessModal, openDeleteModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const inputRef = useRef();

  useEffect(() => {
    const filterDocs = () => {
      if (searchVal !== "") {
        if (documents?.length > 0) {
          const findData = documents.filter(
            ({ file, fileType }) =>
              file.toLowerCase()?.includes(searchVal) ||
              fileType.toLowerCase()?.includes(searchVal)
          );
          setFilteredDocuments(findData);
        }
      } else {
        setFilteredDocuments(documents);
      }
    };

    filterDocs();
  }, [searchVal, documents]);

  useEffect(() => {
    restrucVehicleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassignedVehicle, modalType, documents]);

  const fileTypes = [
    {
      label: "Select",
      value: "select",
    },
    {
      label: "Medical Certificate",
      value: "Medical Certificate",
    },
    {
      label: "Driver License",
      value: "Driver License",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const closeUploadFile = () => {
    setNewFile("");
    setValidateFile(false);
    setNewFileType("select");
    setAddFile(false);
    return openSuccessModal({
      title: "Success",
      message: "Added Successfully!",
      closeNameBtn: "Back",
    });
  };

  const restrucVehicleList = () => {
    let storeVehicle = [];
    let extraOption = {
      value: null,
      label: "Select Vehicle",
    };
    if (unassignedVehicle?.length > 0) {
      unassignedVehicle?.map(({ _id, model, plate_no }) => {
        const params = {
          value: _id,
          label: model + " - " + plate_no,
        };
        return storeVehicle.push(params);
      });

      setVehicleOptions([extraOption, ...storeVehicle]);
    } else return setVehicleOptions([extraOption]);
  };

  const onHandleChange = ({ target }) => {
    const { name, value } = target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChange = async (imageList) => {
    const file = imageList.target.files[0];
    const contentType = file.type;
    const uploadValidation =
      contentType === "image/jpeg" || contentType === "image/png";
    if (!uploadValidation)
      return toastAlert("info", "Make sure to upload correct format");

    setFormValues({ ...formValues, image: URL.createObjectURL(file) });
    return setImages(imageList);
  };

  const stepper = () => {
    const formTitle = modalType === "Add" ? "Add New" : "Update";
    return (
      <div className="flex flex-col">
        <div className="font-medium text-neutralblack text-lg">
          {formTitle} Driver
        </div>
        <div className="flex justify-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <div
              className={`p-2 border ${
                showDocument ? "border-successgreen" : ""
              } rounded-full `}
              style={{
                borderColor: showDocument ? "" : "#BE2B27",
                backgroundColor: showDocument ? "" : "lightyellow",
              }}
            >
              {showDocument ? (
                <RiIcons.RiCheckLine className="text-successgreen" />
              ) : (
                <RiIcons.RiUser3Fill className="text-pickAppRedDefault" />
              )}
            </div>
            <div
              className={`text-${
                showDocument ? "neutraldark delay-500" : "pickAppRedDefault"
              } text-sm`}
            >
              Driver Information
            </div>
          </div>
          <div class="w-1/6 align-center items-center align-middle content-center flex">
            <div
              className={`w-full ${
                showDocument
                  ? "stepBar bg-pickAppRedDefault"
                  : "bg-neutralgray/50 stepBar"
              } rounded items-center align-middle align-center flex-1`}
            >
              <div class=" text-xs leading-none py-0.5 text-center  rounded "></div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div
              className={`p-2 border ${
                showDocument
                  ? "border-pickAppRedDefault bg-[#FCF7DE] delay-700 text-pickAppRedDefault"
                  : "neutralgray/50 text-neutralgray/50"
              } rounded-full`}
            >
              {showDocument ? (
                <RiIcons.RiFileUploadFill className="text-pickAppRedDefault" />
              ) : (
                <RiIcons.RiFileUploadLine />
              )}
            </div>
            <div
              className={`text-${
                showDocument ? "pickAppRedDefault delay-700" : "neutralgray"
              } text-sm `}
            >
              Documents
            </div>
          </div>
        </div>
      </div>
    );
  };

  const imageUploadLayout = () => {
    return (
      <div className="flex flex-col  w-full justify-center gap-2">
        <div className="flex justify-center items-center">
          <img
            src={formValues?.image ? formValues?.image : defaultProfile}
            alt="uploadimg"
            className="h-36 w-48  rounded-sm"
          />
        </div>

        <div className="">
          <button
            onClick={() => inputRef.current.click()}
            className="flex flex-row gap-1 h-9 w-full rounded-md p-2 bg-lightblue text-creamwhite cursor-pointer items-center justify-center text-xs"
          >
            <span>Upload Image</span> <RiIcons.RiImageAddLine size={18} />
          </button>
          <input
            accept="image/*"
            type="file"
            ref={inputRef}
            hidden
            onChange={(imageList) => onChange(imageList)}
          />
        </div>
      </div>
    );
  };

  const vehicleForm = () => {
    const genderList = [
      {
        _id: 1,
        label: "Select",
        value: null,
      },
      {
        _id: 2,
        label: "Male",
        value: "male",
      },
      {
        _id: 3,
        label: "Female",
        value: "female",
      },
      {
        _id: 4,
        label: "Prefer not to respond",
        value: "no-response",
      },
    ];

    const formContent = [
      {
        _id: 1,
        fieldType: "text",
        isEmpty: validate,
        value: formValues.firstName,
        onChange: onHandleChange,
        type: "text",
        label: "First Name",
        name: "firstName",
        grid: "md:col-span-3",
      },
      {
        _id: 2,
        fieldType: "text",
        value: formValues.middleName,
        onChange: onHandleChange,
        type: "text",
        label: "Middle Name",
        subLabel: "Option",
        name: "middleName",
        grid: "md:col-span-3",
      },
      {
        _id: 3,
        fieldType: "text",
        isEmpty: validate,
        value: formValues.lastName,
        onChange: onHandleChange,
        type: "text",
        label: "Last Name",
        name: "lastName",
        grid: "md:col-span-3",
      },
      {
        _id: 4,
        fieldType: "text",
        value: formValues.suffix,
        onChange: onHandleChange,
        type: "text",
        label: "Suffix",
        subLabel: "Option",
        name: "suffix",
        grid: "md:col-span-3",
      },
      {
        _id: 5,
        fieldType: "text",
        isEmpty: validate,
        value: formValues.birthDate,
        onChange: onHandleChange,
        type: "date",
        label: " Date of Birth",
        name: "birthDate",
        grid: "md:col-span-6",
      },
    ];

    return (
      <div className="flex flex-col lg:flex-row gap-4 w-full">
        <div>{imageUploadLayout()}</div>
        <div class="grid grid-cols-1 gap-3 w-full">
          <div class="grid grid-cols-12 mb-4 gap-2 ">
            {formContent?.map(({ _id, grid, ...res }) => (
              <div className={`flex col-span-12 ${grid} `} key={_id}>
                <CustomInput {...res} />
              </div>
            ))}
            <div className="flex flex-col col-span-12 md:col-span-6">
              <CustomSelect
                value={formValues.gender}
                name="gender"
                onChange={onHandleChange}
                options={genderList}
                label="Gender"
                subLabel="Optional"
              />
            </div>

            <div className="flex flex-col col-span-12 md:col-span-6">
              <label className="text-neutraldarkgray text-xs">
                Email
                <GlobalRequiredField
                  fieldType="text"
                  value={formValues.email}
                  isEmpty={validate}
                />{" "}
                {formWarning.email &&
                  formValues.email &&
                  validateEmailAddress(formValues?.email) && (
                    <span className="text-xs text-archivered">
                      (Invalid Email Format)
                    </span>
                  )}
              </label>
              <input
                type="email"
                value={formValues.email}
                name="email"
                onChange={onHandleChange}
                className="customInput"
              />
            </div>
            <div className="flex flex-col col-span-12 md:col-span-6">
              <label className="text-neutraldarkgray text-xs">
                Contact Number
                <GlobalRequiredField
                  fieldType="text"
                  value={formValues.mobileNum}
                  isEmpty={validate}
                />{" "}
                {formWarning.contactNumber &&
                  formValues.mobileNum &&
                  validateMobileNumber(formValues.mobileNum) && (
                    <span className="text-xs text-archivered">
                      (Invalid Contact Number Format)
                    </span>
                  )}
              </label>
              <input
                placeholder="09#########"
                type="number"
                className="customInput"
                value={formValues.mobileNum}
                name="mobileNum"
                onChange={(e) =>
                  e.target.value.length <= 11 && onHandleChange(e)
                }
              />
            </div>
            <div className="flex flex-col col-span-12">
              <CustomTextArea
                label="Address"
                fieldType="text"
                value={formValues.address}
                isEmpty={validate}
                name="address"
                onChange={onHandleChange}
              />
            </div>
            <div className="flex flex-col col-span-12 pt-2 gap-2">
              <div className="text-darkgray text-sm">
                Vehicle Information{" "}
                <span className="text-lightgray">(Optional)</span>
              </div>
              <div className="flex flex-col col-span-12 relative">
                <MultiSelectInput
                  onChange={(e) => {
                    onHandleChange({
                      target: {
                        name: "vehicle_id",
                        value: e,
                      },
                    });
                  }}
                  label="Vehicle"
                  value={formValues.vehicle_id}
                  options={vehicleOptions ?? []}
                  placeholder="Select Vehicle"
                  errorValue={formValues.vehicle_id}
                  isEmpty={validate}
                  fieldType="select"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const vehicleBtns = () => {
    const nextBtn = () => {
      const {
        firstName,
        lastName,
        birthDate,

        email,
        address,
        mobileNum,
      } = formValues ?? {};

      const emailPattern = validateEmailAddress(email);
      const mobilePattern = validateMobileNumber(mobileNum);

      if (
        !firstName ||
        !lastName ||
        !birthDate ||
        !email ||
        !mobileNum ||
        !address ||
        emailPattern ||
        mobilePattern
      ) {
        setValidate(true);

        return setFormWarning({
          email: emailPattern,
          contactNumber: mobilePattern,
        });
      } else {
        setShowDocument(true);
      }
    };
    return (
      <div className="flex flex-row justify-end">
        <button
          className="gradientBg p-1 rounded-md text-white px-5 text-sm flex flex-row gap-2 items-center"
          onClick={() => nextBtn()}
        >
          Next <RiIcons.RiArrowRightLine />
        </button>
      </div>
    );
  };

  const showDriverForm = () => {
    const documentBtns = () => {
      return (
        <div className="flex flex-row justify-end gap-2">
          <button
            className="gradientBg p-1 rounded-md text-white px-3 text-sm flex flex-row gap-2 items-center"
            onClick={() => setShowDocument(false)}
          >
            <RiIcons.RiArrowLeftLine /> <span>Previous page</span>
          </button>
          <button
            className="gradientBg p-1 rounded-md text-white px-5 text-sm flex flex-row gap-2 items-center justify-center"
            onClick={() => validateFields()}
            disabled={btnLoading}
          >
            {btnLoading ? <LoadingLottie /> : " Save"}
          </button>
        </div>
      );
    };

    const removeFileBtn = (index, data) => {
      const copyDocuments = [...documents];
      copyDocuments.splice(index, 1);
      setDocuments(copyDocuments);
      openSuccessModal({
        title: "Success",
        message: "Your changes has been successfully saved.",
        closeNameBtn: "Back",
      });

      if (data?._id) {
        let delListCopy = [...delDocuments];
        delListCopy.push(data);
        return setDelDocuments(delListCopy);
      }
    };

    const documentForm = () => {
      const documentList = () => {
        return (
          <div className="flex flex-col gap-2 divide-y-2">
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((data, index) => {
                const { file, fileType, date_added, _id } = data;
                const getFileName = file.split("/");
                return (
                  <div className="grid grid-cols-3 items-center pt-2" key={_id}>
                    <div className="flex flex-col gap-1">
                      <div className="text-sm font-medium">{fileType}</div>
                      <div className="text-[#929292] text-sm flex flex-row gap-1 items-center">
                        <RiIcons.RiFilePdfLine />{" "}
                        <span>{getFileName[getFileName?.length - 1]}</span>
                      </div>
                    </div>
                    <div className="text-sm text-neutraldark text-center">
                      Date Added: {moment(date_added).format("LL")}
                    </div>
                    <div className="flex justify-end ">
                      <div
                        id="delBtn"
                        className="delBtn group relative inline-blockduration-300 "
                      >
                        <button
                          className="text-neutralgray text-lg hover:text-archivered"
                          onClick={() => {
                            openDeleteModal({
                              title: "Delete",
                              isDelete: true,
                              message:
                                "Are you sure you want to delete this? This action can not be undone.",
                              confirmNameBtn: "Yes, Delete",
                              closeNameBtn: "Cancel",
                              modalAction: () => {
                                removeFileBtn(index, data);
                              },
                            });
                          }}
                        >
                          <Io5Icons.IoTrash />
                        </button>
                        <span className="absolute hidden group-hover:flex -left-4 -top-2 -translate-y-full px-2 py-1 bg-archivered rounded-lg text-center text-creamwhite text-xs after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-4 after:border-x-transparent after:border-b-transparent after:border-t-archivered">
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-lightgray text-sm flex justify-center items-center">
                No documents added.
              </div>
            )}
          </div>
        );
      };
      const searchBar = () => {
        return (
          <div className="flex flex-row w-fit">
            <div className="flex items-center border-y border-l border-neutralgray bg-white relative pl-2 rounded-l-md">
              <BiIcons.BiSearch className="text-neutralgray" />
            </div>
            <input
              id="tableSearch"
              className="border-y w-full border-r border-neutralgray rounded-y-md rounded-r-md py-1 px-1.5 placeholder:text-darkgray placeholder:text-xs placeholder:font-normal focus:outline-none"
              placeholder="Search.."
              type="text"
              value={searchVal}
              onChange={({ target }) => setSearchVal(target.value)}
            />
          </div>
        );
      };
      return (
        <div className="flex flex-col gap-5">
          <div className="flex flex-row justify-between">
            {searchBar()}
            <div>
              <button
                className="flex flex-row gap-2 items-center text-pickAppRedDefault"
                onClick={() => setAddFile(true)}
              >
                <div className="border rounded-md border-pickAppRedDefault">
                  <RiIcons.RiAddLine />
                </div>
                <span className="font-medium">Add File</span>
              </button>
            </div>
          </div>
          <div>{documentList()}</div>
        </div>
      );
    };

    const backButton = () => {
      return (
        <div>
          <button
            className="flex flex-row gap-1 items-center text-pickAppRedDefault"
            onClick={() => handleCloseModal()}
          >
            <RiIcons.RiArrowLeftLine />
            <span>Back</span>
          </button>
        </div>
      );
    };

    const onChangeDocumentUpload = async (document) => {
      if (document?.bubbles) {
        return setNewFile(document);
      }
    };

    const documentUpload = (file) => {
      let listOfDocuments = [...documents];
      listOfDocuments.push({
        fileType: newFileType,
        file: file,
        date_added: moment().format("LL"),
      });
      closeUploadFile();
      return setDocuments(listOfDocuments);
    };

    const showAddUpdateModal = () => {
      const getFileName = newFile?.target?.files[0]?.name ?? null;

      const body = () => {
        return (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col  w-full">
              <label className="text-neutraldarkgray text-xs">
                File Type:{" "}
                <GlobalRequiredField
                  fieldType="select"
                  value={newFileType}
                  isEmpty={validateFile}
                />
              </label>
              <select
                className="customInput"
                onChange={(e) => setNewFileType(e.target.value)}
              >
                {fileTypes?.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col  w-full">
              <label className="text-neutraldarkgray text-xs">
                Please choose a file :{" "}
                <GlobalRequiredField
                  fieldType="text"
                  value={newFile}
                  isEmpty={validateFile}
                />
              </label>{" "}
              <div className="flex flex-row relative border border-neutraldark2 rounded-lg">
                <div className="w-full   h-9  px-2  flex items-center gap-1">
                  {getFileName ? (
                    <a
                      href={URL.createObjectURL(newFile.target.files[0])}
                      target="blank"
                      rel="noopener noreferrer"
                      className="flex flex-row items-center gap-2 text-lightblue underline"
                    >
                      <AiIcons.AiOutlineFilePdf />{" "}
                      <span className="w-72 truncate">{getFileName}</span>
                    </a>
                  ) : (
                    <span className="text-base text-lightgray">
                      * File size must at least 4MB
                    </span>
                  )}
                </div>
                <button
                  className="bg-lightblue rounded-lg px-3 w-40 flex gap-2 items-center justify-center text-white"
                  onClick={() => inputRefUploadFile.current.click()}
                >
                  <RiIcons.RiFolderOpenLine />{" "}
                  <span className="text-xs ">Select a File</span>
                </button>
                <input
                  type="file"
                  ref={inputRefUploadFile}
                  hidden
                  onChange={(imageList) => onChangeDocumentUpload(imageList)}
                />
              </div>
            </div>
          </div>
        );
      };
      const closeModal = () => {
        setAddFile(false);
        setNewFile("");
        setValidateFile(false);
        setNewFileType("select");
      };

      const validateFileUpload = () => {
        if (newFileType === "" || newFileType === "select" || newFile === "") {
          setValidateFile(true);
        } else {
          const params = {
            fileContent: newFile,
            apiFunction: documentUpload,
            path: "driver_documents/",
            applyValidate: false,
          };

          uploadFile({ ...params });
        }
      };
      const props = {
        body: body(),
        isOpen: addFile,
        width: "lg",
        header: "Upload Documents",
        modalType: "View",
        closeFunction: closeModal,
        closeBtnName: "Cancel",
        submitFunction: validateFileUpload,
        submitBtnName: "Add",
        headerStyle: "text-neutralblack",
      };

      return <GlobalModal props={props} />;
    };

    return (
      <div className="flex flex-col gap-5">
        {backButton()}
        <div className="bg-white rounded-lg p-10 gap-5 flex flex-col shadow-lg">
          {stepper()}
          {!showDocument ? vehicleForm() : documentForm()}
          {!showDocument ? vehicleBtns() : documentBtns()}
          {showAddUpdateModal()}
        </div>
      </div>
    );
  };

  return <div>{showDriverForm()}</div>;
}

export default DriverForm;
