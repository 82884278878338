import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition, Tab } from "@headlessui/react";
import shallow from "zustand/shallow";
import * as HiIcons from "react-icons/hi";
import * as RiIcons from "react-icons/ri";
import defaultProfile from "Assets/Image/defaultProfile.png";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import { routesGetApi } from "Api/api";
import { DataTable } from "Components/Global/GlobalTable";
import { bookingStore } from "../../../Zustand/AdminStore/bookingStore";
import NavBar from "Components/NavBar/NavBar";
import GlobalModal from "Components/Global/GlobalModal";
import StatusStyle from "Components/StatusStyle/StatusStyle";
import moment from "moment";
import ViewBooking from "Components/Booking/ViewBooking";
import { APIFetch } from "Utils/timeout";
import FullnameFormat from "Utils/FullnameFormat";
import { allBookingStatus, onGoingBookingStatus } from "Utils/bookingStatus";

const Booking = () => {
  const { booking, onGoingBooking, fetchBooking } = bookingStore(
    (state) => state,
    shallow
  );

  const [openDriverDetails, setOpenDriverdetails] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [driverDetails, setDriverDetails] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openViewModal, setOpenViewModal] = useState(false);

  useEffect(() => {
    fetchBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabPanelList = [
    {
      id: 0,
      tabTitle: "On-going",
    },
    {
      id: 1,
      tabTitle: "All",
    },
  ];

  const getBookingDetails = async (data) => {
    APIFetch(async () => {
      await routesGetApi(`/bookings/${data}`).then(({ status, data }) => {
        if (status === 200) {
          setOpenViewModal(true);
          setFormValues(data);
        }
      });
    });
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const TabPanel = () => {
    return (
      <div className="flex flex-col bg-white rounded-md h-auto p-2">
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(e) => {
            setSelectedIndex(e);
          }}
        >
          <Tab.List
            selected
            className="flex space-x-1 rounded-xl bg-opacity-20 "
          >
            {tabPanelList && tabPanelList.length > 0
              ? tabPanelList.map(({ tabTitle, id }) => (
                  <Tab
                    key={id}
                    className={({ selected }) =>
                      classNames(
                        "w-36 py-2 text-sm font-medium antialiased",
                        selected
                          ? "bg-white text-pickAppRedDefault border-b-2 border-pickAppRedDefault "
                          : "text-lightgray hover:bg-white/[0.12] hover:text-neutraldarkgray "
                      )
                    }
                  >
                    {tabTitle ?? null}
                  </Tab>
                ))
              : null}
          </Tab.List>
          <hr />
        </Tab.Group>
      </div>
    );
  };

  const driverData = async ({ _id }) => {
    APIFetch(async () => {
      await routesGetApi(`/drivers/${_id}`).then(({ data, status }) => {
        if (status === 200) {
          setDriverDetails(data);
          setOpenDriverdetails(true);
        }
      });
    });
  };

  const driver = (data) => {
    return (
      <button className="text-lightblue" onClick={() => driverData(data)}>
        {FullnameFormat({ props: data })}
      </button>
    );
  };

  const column = [
    {
      dataField: "createdAt",
      text: "Date and Time",
      formatter: (data) => moment(data).format("LLL"),
    },
    {
      dataField: "bookingNo",
      text: "Booking No.",
      formatter: (data, row) => (
        <div
          className="text-lightblue cursor-pointer"
          onClick={() => {
            getBookingDetails(row?._id);
          }}
        >
          {data}
        </div>
      ),
    },
    {
      dataField: "origin",
      text: "Pick-up Location",
      formatter: (data) => (
        <div className="line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
          {data}
        </div>
      ),
    },
    {
      dataField: "destination",
      text: "Drop-off Location",
      formatter: (data) => (
        <div className="line-clamp-2 break-all transition ease-in-out duration-700 hover:scale-95 hover:line-clamp-none">
          {data}
        </div>
      ),
    },
    {
      dataField: "driver_id",
      text: "Driver",
      headerStyle: () => {
        return { width: 120 };
      },
      formatter: (data) => (data ? driver(data) : "--"),
    },
    {
      dataField: "booking_status",
      text: "Status",
      headerStyle: () => {
        return { width: 120 };
      },
      formatter: (data) => StatusStyle(data),
    },
    {
      dataField: "_id",
      text: "",
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (data) => {
        return (
          <Menu
            as="div"
            id="actionButton"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-[#32498D]" />
              </Menu.Button>
            </div>
            <Transition appear>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
              </Transition.Child>

              <Menu.Items className="absolute right-0 py-2 w-60 shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
                <div className="flex flex-col py-2">
                  <Menu.Item>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 `}
                        onClick={() => {
                          getBookingDetails(data);
                        }}
                      >
                        <img src={viewIcon} alt="View Icon" />
                        <span className="text-neutralblack">
                          View Booking Details
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      },
    },
  ];

  const showViewModal = () => {
    const body = () => {
      const { mobileNum, email, address, vehicle_id, image } =
        driverDetails ?? {};
      const {
        brand,
        chassisNo,
        color,
        engineNo,
        model,
        mvFileNo,
        plate_no,
        serviceType_id,
        manufacturer,
      } = vehicle_id ?? {};

      const driverData = [
        FullnameFormat({ props: driverDetails }),
        mobileNum,
        email,
        address,
      ];

      const vehicle = [
        {
          _id: 1,
          name: "Vehicle Type",
          value: serviceType_id?.vehicleType,
        },
        {
          _id: 2,
          name: "Plate Number",
          value: plate_no,
        },
        {
          _id: 3,
          name: "Brand",
          value: brand,
        },
        {
          _id: 4,
          name: "Model",
          value: model,
        },
        {
          _id: 5,
          name: "Color",
          value: color,
        },
        {
          _id: 6,
          name: "Manufacturer",
          value: manufacturer,
        },
        {
          _id: 7,
          name: "Engine Number",
          value: engineNo,
        },
        {
          _id: 8,
          name: "Chassis Number",
          value: chassisNo,
        },
        {
          _id: 9,
          name: "MV File Number",
          value: mvFileNo,
        },
      ];
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col md:flex-row justify-start gap-5 ">
            <div className="flex justify-center">
              <img
                src={image ?? defaultProfile}
                alt="defaultProfile"
                className="h-36 w-36 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2 p-5 md:p-0">
              {driverData.map((data, index) => (
                <div
                  key={data}
                  className={`${
                    index === 0 ? "font-medium text-lg" : "font-normal"
                  }`}
                >
                  {data ?? "--"}
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="flex flex-col gap-1">
            <div className="text-orange">Vehicle Information</div>
            <div className="p-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {vehicle.map(({ name, value, _id }) => (
                  <div className="grid grid-cols-2" key={_id}>
                    <div className="text-neutraldark">{name}</div>
                    <div className="text-neutralblack font-medium">
                      {value ?? "--"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    };
    const closeModal = () => {
      setOpenDriverdetails(false);
    };
    const props = {
      body: body(),
      isOpen: openDriverDetails,
      width: "3xl",
      hideHeader: true,
      modalType: "View",
      hideCloseBtn: true,
      submitFunction: closeModal,
      submitBtnName: "Close",
      headerStyle: "text-neutralblack",
    };

    return <GlobalModal props={props} />;
  };

  const BackButton = () => {
    return (
      <div>
        <button
          className="flex flex-row gap-1 items-center text-pickAppRedDefault"
          onClick={() => setOpenViewModal(false)}
        >
          <RiIcons.RiArrowLeftLine />
          <span>Back</span>
        </button>
      </div>
    );
  };

  return (
    <>
      <NavBar title="Booking" />
      {showViewModal()}
      {!openViewModal ? (
        <DataTable
          TabPanel={TabPanel}
          rows={selectedIndex === 0 ? onGoingBooking : booking}
          columns={column}
          title="List of on-going bookings"
          csvtitle="booking.csv"
          statusList={
            selectedIndex === 0 ? onGoingBookingStatus : allBookingStatus
          }
          searchStatus={true}
        />
      ) : (
        <div className="flex flex-col gap-5 mb-5">
          <BackButton />
          <ViewBooking viewBookingInfo={formValues} infoRow={2} />
        </div>
      )}
    </>
  );
};

export default Booking;
