import React, { useState } from "react";
import Backdrop from "./Backdrop";
import backend from "Api/backend";
import NoConnnection from "Layout/NoConnnection";

const withLoading = (WrappedComponent) => (props) => {
  const [show, setShow] = useState(false);

  backend.interceptors.request.use(
    function (config) {
      setShow(true);
      return config;
    },
    function (error) {
      setShow(false);
      return Promise.reject(error);
    }
  );

  backend.interceptors.response.use(
    function (response) {
      setShow(false);

      return response;
    },
    function (error) {
      setShow(false);
      return Promise.reject(error);
    }
  );

  const isOnline = navigator.onLine ? true : false;

  return (
    <div>
      {isOnline ? (
        <>
          <Backdrop show={show} />
          <WrappedComponent {...props} />
        </>
      ) : (
        <NoConnnection />
      )}
    </div>
  );
};

export default withLoading;
