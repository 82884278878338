import create from "zustand";
import { routesGetAllApi, routesGetApi } from "Api/api";
import axios from "axios";
import { APIFetch } from "Utils/timeout";

const driverObjectDetails = {
  driver: [],
  unassignedDriver: [],
  driverDetails: [],
  booking: [],
  documents: [],
  wallet: [],
  credit: [],
  activeDriver: [],
  feedback: [],
};

const storeUnassignedDriver = async (set, data) => {
  return set({
    unassignedDriver: data,
  });
};

const apiRequestUnassigned = async (set) => {
  return await routesGetApi("/drivers/?unassigned=1").then((res) => {
    if (res.status === 200) {
      return set({
        unassignedDriver: res.data,
      });
    }
  });
};

const storeUnassigned = async (set) => {
  return apiRequestUnassigned(set);
};

const storeUnassignedDriverTimeout = async (set) => {
  return APIFetch(() => apiRequestUnassigned(set));
};

const apiRequest = async (set) => {
  return await routesGetApi("/drivers").then(async (res) => {
    if (res.status === 200) {
      return await set({
        driver: res.data,
      });
    }
  });
};

const storeDriver = async (set) => {
  return apiRequest(set);
};

const storeDriverTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const storeViewDriver = async (set, _id) => {
  const routeList = [
    `/drivers/${_id}`,
    `/drivers/booking/reports/${_id}`,
    `/drivers/wallet/reports/${_id}`,
    `/drivers/credit/reports/${_id}`,
    `/drivers/rating/reports/${_id}`,
  ];

  APIFetch(
    async () =>
      await routesGetAllApi(routeList).then(
        axios.spread((res1, res2, res3, res4, res5) => {
          return set({
            documents: res1.data?.documents,
            driverDetails: res1.data,
            booking: res2.data,
            wallet: res3.data,
            credit: res4.data,
            feedback: res5?.data?.ratings,
          });
        })
      )
  );
};

const storeAllDriver = async (set, data) => {
  return set({
    driver: data,
  });
};

const storeAllActiveDrivers = async (set, data) => {
  return set({
    activeDriver: data,
  });
};

const storeActiveDriverAction = async (set) => {
  const apiRequest = async () => {
    await routesGetApi("/drivers/online").then(({ status, data }) => {
      if (status === 200) {
        return set({
          activeDriver: data,
        });
      }
    });
  };

  return APIFetch(() => apiRequest());
};

const driverStoreObject = (set) => ({
  ...driverObjectDetails,
  storeDriverList: () => storeDriver(set),
  fetchDriver: () => storeDriverTimeout(set),
  storeOnlineDriverList: () => storeActiveDriverAction(set),
  storeDriverDetails: (_id) => storeViewDriver(set, _id),
  getAllDriver: (data) => storeAllDriver(set, data),
  getAllActiveDrivers: (data) => storeAllActiveDrivers(set, data),
  storeAvailableDriver: () => storeUnassigned(set),
  fetchUnassignedDriver: () => storeUnassignedDriverTimeout(set),
  getUnassignedDriver: (data) => storeUnassignedDriver(set, data),
});

export const driverStore = create(driverStoreObject);
