import { Menu, Transition } from "@headlessui/react";
import { DataTable } from "Components/Global/GlobalTable";
import React, { Fragment, useEffect, useState } from "react";
import {
  routesGetApi,
  routesGetNoHoc,
  routesPostApi,
  routesPutApi,
} from "Api/api";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import shallow from "zustand/shallow";
import NavBar from "Components/NavBar/NavBar";
import * as HiIcons from "react-icons/hi";
import DriverDetails from "./DriverDetails";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import editIcon from "Assets/ActionIcon/editIcon.png";
import resetIcon from "Assets/ActionIcon/unlockIcon.png";
import DriverForm from "./Form/DriverForm";
import moment from "moment";
import { vehicleStore } from "../../../Zustand/AdminStore/vehicleStore";
import SwitchButton from "Components/ToggleButton/SwitchButton";
import uploadFile from "Utils/uploadFile";
import ViewVehicle from "../Vehicle/ViewVehicle";

const Driver = () => {
  const { openConfirmModal, openSuccessModal, openResetModal } =
    alertModalStore((state) => state, shallow);
  const { storeAvailableVehicle, fetchUnassignVehicle } = vehicleStore(
    (state) => state,
    shallow
  );
  const {
    storeDriverList,
    driver,
    storeDriverDetails,
    fetchDriver,
    storeAvailableDriver,
  } = driverStore((state) => state, shallow);

  const [btnLoading, setbtnLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [modalType, setModalType] = useState("");
  const [images, setImages] = useState("");
  const [documents, setDocuments] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [delDocuments, setDelDocuments] = useState([]);

  useEffect(() => {
    fetchDriver();
    fetchUnassignVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameFormat = (row) => {
    const { firstName, middleName, lastName, suffix } = row;
    return (
      <div className="text-lightblue max-w-md ">
        <div
          className="cursor-pointer hover:text-gray-800 truncate capitalize"
          onClick={() => getDriverDetails(row)}
        >
          {firstName} {middleName ? middleName?.charAt(0) + "." : ""} {lastName}{" "}
          {suffix ?? ""}
        </div>
      </div>
    );
  };

  const emailFormat = (data) => {
    return (
      <div className="max-w-md min-w-md text-ellipsis overflow-hidden">
        {data}
      </div>
    );
  };

  const mobileNumFormat = (data) => {
    return (
      <div className="max-w-md min-w-md text-ellipsis overflow-hidden">
        +63{data}
      </div>
    );
  };

  const openVehicle = async ({ _id }) => {
    await routesGetApi(`/vehicle/${_id}`).then(({ data, status }) => {
      if (status === 200) {
        setShowVehicleDetails(true);
        return setFormValues(data);
      }
    });
  };

  const columns = [
    {
      dataField: "firstName",
      text: "Name",
      formatter: (_data, row) => nameFormat(row),
    },
    {
      dataField: "vehicle_id",
      text: "Vehicle",
      formatter: (data, row) => {
        const { plate_no, brand, model, color } = row?.vehicle_id ?? {};
        return (
          <>
            {data ? (
              <div
                className="text-lightblue text-ellipsis max-w-md cursor-pointer hover:text-customgray"
                onClick={() => openVehicle(data)}
              >
                {plate_no} - {brand} {model} ({color})
              </div>
            ) : (
              "--"
            )}
          </>
        );
      },
    },

    {
      dataField: "email",
      text: "Email",
      formatter: (data) => emailFormat(data),
    },
    {
      dataField: "mobileNum",
      text: "Contact Number",
      formatter: (data) => mobileNumFormat(data),
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (data, row) => {
        const togglePropsContainer = {
          data,
          onChangeFunction: () =>
            openConfirmModal({
              title: "Are You Sure",
              message: "Are you sure you want to update the status?",
              confirmNameBtn: "Yes, Update",
              closeNameBtn: "Cancel",
              modalAction: () => {
                renderChangeStatus(row);
              },
            }),
        };

        return <SwitchButton {...togglePropsContainer} />;
      },
    },
    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => menuList(row),
    },
  ];

  const menuList = (row) => {
    return (
      <Menu
        as="div"
        id="actionButton"
        className="relative inline-block text-left"
      >
        <div>
          <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
          </Menu.Button>
        </div>
        <Transition appear>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
          </Transition.Child>

          <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
            <div className="flex flex-col py-2">
              {actionList?.map((item, index) => {
                const { label, svgIcon, itemFunction } = item;
                return (
                  <Menu.Item onClick={() => itemFunction(row)} key={label}>
                    {() => (
                      <div
                        className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                      >
                        <img src={svgIcon} alt={svgIcon} />
                        <span
                          className={`${
                            index === 3 ? "text-red-600" : ""
                          } text-neutralblack font-normal`}
                        >
                          {label}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderChangeStatus = async (row) => {
    await routesPutApi(`/drivers/status/${row._id}`).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return storeDriverList();
      }
    });
  };

  const getDriverDetails = async ({ _id }) => {
    if (_id) {
      setOpenViewModal(true);
      storeDriverDetails(_id);
    }
  };

  const actionList = [
    {
      _id: 1,
      label: "View",
      svgIcon: viewIcon,
      itemFunction: (row) => getDriverDetails(row),
    },
    {
      _id: 2,
      label: "Update",
      svgIcon: editIcon,
      itemFunction: async (row) => {
        await routesGetNoHoc(`/drivers/${row._id}`).then(({ data, status }) => {
          if (status === 200) {
            const { vehicle_id } = data ?? {};
            if (vehicle_id) {
              const params = {
                mobileNum: "0" + data.mobileNum,
                vehicle_id: {
                  value: data.vehicle_id._id,
                  label: data.vehicle_id.model,
                },
                birthDate: moment(data.birthDate).format("YYYY-MM-DD"),
              };
              setFormValues({ ...data, ...params });
            } else {
              const params = {
                mobileNum: "0" + data.mobileNum,
                birthDate: moment(data.birthDate).format("YYYY-MM-DD"),
              };
              setFormValues({ ...data, ...params });
            }
            setDocuments(data?.documents);
            setOpenModal(true);
            setModalType("Update");
          }
        });
      },
    },
    {
      _id: 3,
      label: "Reset Password",
      svgIcon: resetIcon,
      itemFunction: ({ _id }) => {
        openResetModal({
          title: "Reset Password",
          message:
            "Once you proceed, the system will set a default password for this driver account.",
          confirmNameBtn: "Reset Password",
          closeNameBtn: "Cancel",
          modalAction: async () => {
            await routesPutApi(`/drivers/resetdriverpass/${_id}`).then(
              (res) => {
                if (res.status === 201) {
                  openSuccessModal({
                    title: "Success",
                    message:
                      "The password has been set to default successfully. ",
                    closeNameBtn: "Back",
                  });
                  return storeDriverList();
                }
              }
            );
          },
        });
      },
    },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValues({});
    setDocuments([]);
    setDelDocuments([]);
    setShowVehicleDetails(false);
    setbtnLoading(false);
  };

  const apiFunction = async (s3Image) => {
    const params = {
      ...formValues,
      gender: formValues?.gender === "Select" ? null : formValues.gender,
      vehicle_id: formValues?.vehicle_id?.value ?? null,
      image: s3Image ? s3Image : formValues.image,
      documents: documents.filter(({ _id }) => !_id),
      del_documents: delDocuments,
    };

    function getApiRoute() {
      return modalType === "Add"
        ? routesPostApi("/auth/driver/register", params)
        : routesPutApi(`/drivers/admin/${formValues?._id}`, params);
    }

    await getApiRoute().then((res) => {
      if (res.status === 201) {
        setbtnLoading(false);
        openSuccessModal({
          title: "Success",
          message:
            modalType === "Add"
              ? "Added Successfully!"
              : "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });

        handleCloseModal();
        storeAvailableDriver();
        storeAvailableVehicle();
        return storeDriverList();
      } else {
        setbtnLoading(false);
      }
    });
  };

  const validateFields = () => {
    const params = {
      fileContent: images,
      apiFunction: apiFunction,
      path: "driver_image/",
      applyValidate: true,
      setbtnLoading,
    };

    if (modalType === "Add") {
      setbtnLoading(true);
      uploadFile({ ...params });
    } else {
      openConfirmModal({
        title: "Are You Sure",
        message: "Are you sure you want to update this?",
        confirmNameBtn: "Yes, Update",
        closeNameBtn: "Cancel",
        modalAction: () => {
          uploadFile({ ...params });
        },
      });
    }
  };

  const renderContent = () => {
    const props = {
      setOpenViewModal,
    };
    return !openViewModal ? <ShowTable /> : <DriverDetails props={props} />;
  };

  const ShowTable = () => (
    <DataTable
      rows={driver}
      columns={columns}
      title="List of registered drivers"
      csvtitle="driver.csv"
      setOpenModal={setOpenModal}
      btnName="Driver"
      setModalType={setModalType}
    />
  );

  const propsContainer = {
    handleCloseModal,
    modalType,
    setOpenModal,
    openModal,
    formValues,
    setFormValues,
    validateFields,
    images,
    setImages,
    setDocuments,
    documents,
    setDelDocuments,
    delDocuments,
    btnLoading,
  };

  return (
    <>
      <NavBar title="Driver" />
      {showVehicleDetails ? (
        <ViewVehicle details={formValues} handleCloseModal={handleCloseModal} />
      ) : openModal ? (
        <DriverForm props={propsContainer} />
      ) : (
        renderContent()
      )}
    </>
  );
};

export default Driver;
