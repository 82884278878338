import React, { useEffect, useRef, useState } from "react";
import NavBar from "Components/NavBar/NavBar";
import defaultProfile from "Assets/Image/defaultProfile.png";
import * as RiIcons from "react-icons/ri";
import ProfileVerificationLayout from "./ProfileVerificationLayout";
import { accountLoginDetailsStore } from "../../../Zustand/UserInformationStore/accountLoginDetailsStore";
import shallow from "zustand/shallow";
import moment from "moment";
import { routesPostApi, routesPutApi } from "Api/api";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import GlobalRequiredField from "Components/Global/GlobalRequiredField";
import LoadingLottie from "Components/Lottie/LoadingLottie";
import { validateMobileNumber } from "Utils/validateMobileNumber";
import { validateEmailAddress } from "Utils/validateEmailAddress";
import uploadFile from "Utils/uploadFile";

function Profile() {
  const pictureUpload = useRef();
  const [formValues, setFormValues] = useState({});
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [validate, setValidate] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [formWarning, setFormWarning] = useState({
    email: false,
    contactNumber: false,
  });
  const { openSuccessModal } = alertModalStore((state) => state, shallow);
  const { userInfomation, storeAccDetails } = accountLoginDetailsStore(
    (state) => state,
    shallow
  );
  const [images, setImages] = useState("");

  const {
    firstName,
    middleName,
    lastName,
    birthDate,
    contactNo,
    email,
    gender,
    image,
    suffix,
    _id,
  } = formValues ?? {};

  useEffect(() => {
    setFormValues({
      ...userInfomation,
      contactNo: "0" + userInfomation?.contactNo,
      birthDate: moment(userInfomation?.birthDate)?.format("YYYY-MM-DD"),
    });
  }, [userInfomation]);

  const uploadAdminImage = (e) => {
    setFormValues({
      ...formValues,
      image: URL.createObjectURL(e.target.files[0]),
    });
    setImages(e);
  };

  const askConfirmation = () => {
    const emailPattern = validateEmailAddress(email);
    const mobilePattern = validateMobileNumber(contactNo);

    setFormWarning({
      email: emailPattern,
      contactNumber: mobilePattern,
    });

    if (
      !firstName ||
      !lastName ||
      !birthDate ||
      mobilePattern ||
      emailPattern
    ) {
      return setValidate(true);
    } else {
      setbtnLoading(true);
      const params = {
        fileContent: images,
        apiFunction: apiFunction,
        path: "admin_image/",
        applyValidate: true,
        setbtnLoading,
      };

      uploadFile({ ...params });
    }
  };

  const apiFunction = async (s3Image) => {
    setFormValues({
      ...formValues,
      newImage: s3Image ? s3Image : image,
    });
    if (userInfomation.email !== email) {
      await routesPostApi("/auth/mailotp", { newEmail: email })
        .then(({ status }) => {
          if (status !== 422) return setIsSaveChanges(true);
        })
        .finally(() => setbtnLoading(false));
    } else {
      const params = {
        ...formValues,
        gender: formValues.gender === "Select" ? null : formValues.gender,
        image: s3Image ?? image,
      };

      await routesPutApi(`/users/${_id}`, params).then(({ status }) => {
        if (status === 201) {
          setbtnLoading(false);
          storeAccDetails();
          return openSuccessModal({
            title: "Success",
            message: "Your changes has been successfully saved.",
            closeNameBtn: "Back",
          });
        } else {
          setbtnLoading(false);
        }
      });
    }
  };

  const uploadPictureLayout = () => {
    return (
      <div className="flex flex-col gap-2 items-center lg:items-start">
        <img
          src={image ? image : defaultProfile}
          alt="defaultProfile"
          className="h-44 w-52 rounded-lg shadow-lg"
        />
        <div className="w-52 lg:w-full">
          <button
            onClick={() => pictureUpload.current.click()}
            className="flex flex-row gap-1 bg-white text-orange shadow-md p-2 rounded-md w-full items-center justify-center "
          >
            <RiIcons.RiImageAddLine size={20} />
            <span className="font-medium text-sm">Upload Image</span>
          </button>

          <input
            type="file"
            ref={pictureUpload}
            hidden
            onChange={(e) => uploadAdminImage(e)}
          />
        </div>
      </div>
    );
  };

  const onChangeFormVal = (e, maxChar) => {
    const { name, value } = e.target;
    if (maxChar) {
      value.length <= maxChar &&
        setFormValues({
          ...formValues,
          [name]: value,
        });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const formLayout = () => {
    const formArr = [
      {
        label: "First Name",
        type: "text",
        name: "firstName",
        isRequired: true,
        column: 1,
        value: firstName,
      },
      {
        label: "Middle Name",
        type: "text",
        name: "middleName",
        isRequired: false,
        column: 1,
        value: middleName,
      },
      {
        label: "Last Name",
        type: "text",
        name: "lastName",
        isRequired: true,
        column: 1,
        value: lastName,
      },
      {
        label: "Suffix",
        subLabel: "(Optional)",
        type: "text",
        name: "suffix",
        isRequired: false,
        column: 1,
        value: suffix,
      },
      {
        label: "Gender",
        type: "select",
        name: "gender",
        value: gender,
        options: [
          {
            value: null,
            optionLabel: "Select ",
          },
          {
            value: "male",
            optionLabel: "Male",
          },
          {
            value: "female",
            optionLabel: "Female",
          },
        ],
        isRequired: true,
        column: 1,
      },
      {
        label: "Date of Birth",
        type: "date",
        name: "birthDate",
        isRequired: true,
        column: 1,
        value: birthDate,
      },
      {
        label: "Contact Number",
        type: "number",
        name: "contactNo",
        isRequired: true,
        errValidation: formWarning.contactNumber,
        column: 1,
        value: contactNo,
        maxChar: 11,
        errMessage: "Invalid Contact Number Format",
      },
      {
        label: "Email",
        type: "text",
        name: "email",
        isRequired: true,
        errValidation: formWarning.email,
        column: 2,
        value: email,
        errMessage: "Invalid Email Format",
      },
    ];

    const messagePop = (errMessage, isRequired, value, type, errValidation) => {
      return errMessage && errValidation ? (
        <span className="text-xs text-archivered">({errMessage})</span>
      ) : (
        isRequired && (
          <GlobalRequiredField
            fieldType={type}
            value={value}
            isEmpty={validate}
          />
        )
      );
    };
    return (
      <div className="grid grid-cols-3 w-full gap-2">
        {formArr?.map((data) => {
          const {
            label,
            type,
            isRequired,
            errValidation,
            column,
            options,
            name,
            value,
            errMessage,
            maxChar,
            subLabel,
          } = data ?? {};
          return (
            <div className={`flex flex-col col-span-${column}`} key={name}>
              <label className="text-neutraldarkgray text-xs">
                {label}{" "}
                {subLabel && <span className="text-lightgray">{subLabel}</span>}
                {messagePop(errMessage, isRequired, value, type, errValidation)}
              </label>
              {type === "select" ? (
                <select
                  name={name}
                  value={value}
                  onChange={(e) => onChangeFormVal(e)}
                  className="customInput"
                >
                  {options?.map(({ value, optionLabel }) => (
                    <option value={value} key={value}>
                      {optionLabel}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  name={name}
                  type={type}
                  value={value}
                  onChange={(e) => onChangeFormVal(e, maxChar)}
                  className="customInput"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const btnSaveChanges = () => {
    return (
      <div className="flex justify-end">
        <button
          className="gradientBg text-white p-2 px-4 rounded-lg text-sm "
          onClick={() => askConfirmation()}
          disabled={btnLoading}
        >
          {btnLoading ? <LoadingLottie /> : " Save Changes"}
        </button>
      </div>
    );
  };

  const profileForm = () => {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex justify-center lg:justify-start flex-col lg:flex-row w-full pt-5 gap-10">
          {uploadPictureLayout()}
          {formLayout()}
        </div>
        {btnSaveChanges()}
      </div>
    );
  };

  const closeOptUI = () => {
    storeAccDetails();
    setbtnLoading(false);
    return setIsSaveChanges(false);
  };

  const propsContainer = {
    ...formValues,
  };

  return (
    <div>
      <NavBar />
      <div className="bg-white rounded-lg p-10 gap-2 shadow-lg ">
        <div className="flex flex-col">
          <div className="text-neutralblack font-medium text-xl">Profile</div>
          <div className="text-darkgray">Manage your profile</div>
        </div>
        {!isSaveChanges ? (
          profileForm()
        ) : (
          <ProfileVerificationLayout
            props={propsContainer}
            closeOptUI={closeOptUI}
          />
        )}
      </div>
    </div>
  );
}

export default Profile;
