import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import * as Io5 from "react-icons/io5";
import GlobalModal from "Components/Global/GlobalModal";
import moment from "moment";
import VehicleDocuments from "./VehicleDocuments";
import FullnameFormat from "Utils/FullnameFormat";

function ViewVehicle({ details, handleCloseModal }) {
  const {
    brand,
    chassisNo,
    color,
    createdAt,
    image,
    manufacturer,
    model,
    plate_no,
    serviceType_id,
    documents,
    driver_id,
    mvFileNo,
    engineNo,
  } = details ?? {};

  const [viewVehicle, setViewVehicle] = useState(false);
  const BackButton = () => {
    return (
      <div>
        <button
          className="flex flex-row gap-1 items-center text-pickAppRedDefault"
          onClick={() => handleCloseModal()}
        >
          <RiIcons.RiArrowLeftLine />
          <span>Back</span>
        </button>
      </div>
    );
  };
  const driverDetails = () => {
    const { createdAt, gender, email, birthDate, mobileNum, address } =
      driver_id ?? {};

    const details = [
      {
        _id: 1,
        label: "Full Name",
        value: FullnameFormat({ props: driver_id, isMiddleName: true }),
      },
      {
        _id: 2,
        label: "Date Registered",
        value: moment(createdAt)?.format("LL"),
      },
      {
        _id: 3,
        label: "Gender",
        value: gender === "no-response" ? "Prefer not to respond" : gender,
      },
      {
        _id: 4,
        label: "Email Address",
        value: email,
      },
      {
        _id: 5,
        label: "Birthdate",
        value: moment(birthDate).format("LL"),
      },
      {
        _id: 6,
        label: "Contact Number",
        value: `0${mobileNum}`,
      },
      {
        _id: 7,
        label: "Address",
        value: address,
      },
    ];
    return (
      <div className="flex flex-col gap-2 ">
        <div className="flex flex-col gap-1">
          <div className="text-neutralblack text-sm">Driver Information</div>
          <div className="grid grid-col grid-cols-2 gap-2">
            {details.map(({ label, value, _id }) => (
              <div className="grid grid-cols-12" key={_id}>
                <div className="text-neutraldark2 col-span-4">{label}</div>
                <div className={`col-span-8 ${_id === 3 ? "capitalize" : ""}`}>
                  {value ?? "-"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const vehicleDetails = () => {
    const details = [
      {
        _id: 1,
        label: "Vehicle Type",
        value: serviceType_id?.vehicleType,
      },
      {
        _id: 2,
        label: "Plate No.",
        value: plate_no,
      },

      {
        _id: 3,
        label: "Brand",
        value: brand,
      },
      {
        _id: 4,
        label: "Manufacturer",
        value: manufacturer,
      },
      {
        _id: 5,
        label: "Color",
        value: color,
      },
      {
        _id: 6,
        label: "Model",
        value: model,
      },
      {
        _id: 7,
        label: "Engine No.",
        value: engineNo,
      },

      {
        _id: 8,
        label: "Chassis Number",
        value: chassisNo,
      },
      {
        _id: 9,
        label: "MV File Number",
        value: mvFileNo,
      },
    ];
    return (
      <div className="flex flex-col gap-1 ">
        <div className="text-neutralblack text-sm">Vehicle Information</div>
        <div className="grid grid-col grid-cols-2 gap-2">
          {details.map(({ label, value, _id }) => (
            <div className="grid grid-cols-12" key={_id}>
              <div className="text-neutraldark2 col-span-4">{label}</div>
              <div className="col-span-8">{value ?? "--"}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const VehicleAndDriverInfo = () => {
    return (
      <div className="flex flex-col bg-white p-5 rounded-lg shadow-md gap-4">
        <div className="flex flex-row justify-between">
          <div className="font-medium text-lg uppercase ">
            {plate_no} - {brand} {model} ({color})
          </div>
          <div>
            <button
              className="flex flex-row gap-1 items-center"
              onClick={() => setViewVehicle(true)}
            >
              <Io5.IoImagesOutline className="text-pickAppRedDefault" />{" "}
              <span className="text-pickAppRedDefault underline">
                View Vehicle Image
              </span>
            </button>
          </div>
        </div>
        {driver_id && (
          <div className="flex flex-col gap-3">
            {driverDetails()} <hr />
          </div>
        )}

        <div>{vehicleDetails()}</div>
      </div>
    );
  };

  const DocumentList = () => {
    return (
      <div className="flex flex-col gap-2 bg-white p-5 rounded-lg shadow-md">
        <div className="text-sm font-medium">Documents</div>
        <VehicleDocuments documents={documents} />
      </div>
    );
  };

  const ShowViewModal = () => {
    const getFileName = image?.split("/");
    const body = () => {
      return (
        <div className="flex flex-col gap-5">
          <div>
            <img src={image} className="w-full h-[320px]" alt={model} />
          </div>
          <div className="flex flex-col text-sm">
            <div>{getFileName[getFileName?.length - 1]}</div>
            <div>Added: {moment(createdAt).format("LLL")}</div>
          </div>
        </div>
      );
    };

    const closeModal = () => {
      setViewVehicle(false);
    };
    const props = {
      body: body(),
      isOpen: viewVehicle,
      width: "xl",
      hideHeader: true,
      modalType: "View",
      hideCloseBtn: true,
      submitFunction: closeModal,
      submitBtnName: "Close",
    };

    return <GlobalModal props={props} />;
  };
  return (
    <div className="flex flex-col gap-5">
      {image && <ShowViewModal />}
      <BackButton /> <VehicleAndDriverInfo /> <DocumentList />
    </div>
  );
}

export default ViewVehicle;
