import { DataTable } from "Components/Global/GlobalTable";
import React, { useEffect, useState } from "react";

import { routesGetApi } from "Api/api";
import * as RiIcons from "react-icons/ri";
import shallow from "zustand/shallow";
import NavBar from "Components/NavBar/NavBar";

import GlobalBoostrapTable from "Components/Global/GlobalBoostrapTable";
import ViewReports from "./ViewReports";
import { reportStore } from "../../../Zustand/AdminStore/reportStore";
import moment from "moment";
import GlobalPrintAndExport from "Components/Global/GlobalPrintAndExport";
import { APIFetch } from "Utils/timeout";

function Reports() {
  const { reports, fetchReports } = reportStore((state) => state, shallow);

  const [openViewModal, setOpenViewModal] = useState(false);

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewReportsDetails = async (date) => {
    APIFetch(async () => {
      await routesGetApi(`/reports/?date=${date}`).then(({ data, status }) => {
        if (status === 200) {
          setOpenViewModal(true);
          return setFormValues(data[0] ?? {});
        }
      });
    });
  };

  const columns = [
    {
      dataField: "newDate",
      text: "Date",

      formatter: (data, row) => {
        return (
          <div className="text-lightblue max-w-md ">
            <div
              className="cursor-pointer hover:text-gray-800 truncate normal-case"
              onClick={() => viewReportsDetails(row?.date)}
            >
              {moment(data).format("LL")}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "totalCommission",
      text: "Total Commission",
      headerStyle: () => {
        return { textAlign: "right", width: "200px" };
      },
      formatter: (data) => {
        return (
          <div className=" text-ellipsis overflow-hidden text-center font-sans">
            ₱{data?.toFixed(2)}
          </div>
        );
      },
    },
  ];

  const showViewModal = () => {
    const backButton = () => {
      return (
        <div>
          <button
            className="flex flex-row gap-1 items-center text-pickAppRedDefault"
            onClick={() => setOpenViewModal(false)}
          >
            <RiIcons.RiArrowLeftLine />
            <span>Back</span>
          </button>
        </div>
      );
    };
    const ViewCSVheaders = [
      { label: moment(formValues?.date).format("LL"), key: "commission" },
      { label: "Driver", key: "driver" },
      { label: "No. of Bookings", key: "noOfBooking" },
      { label: "Total Commission per Driver", key: "commissionPerDriver" },
    ];

    const ViewCSVdata = [
      {
        commission: `Total Commission : ₱${formValues?.totalCommission.toFixed(
          0
        )}`,
      },
      ...formValues?.data,
    ];

    const viewColumns = [
      {
        dataField: "driver",
        text: "Driver",

        formatter: (data, row) => {
          return (
            <div className=" max-w-md ">
              <div className=" truncate normal-case">{data}</div>
            </div>
          );
        },
      },
      {
        dataField: "noOfBooking",
        text: "No. of Bookings",
        headerStyle: () => {
          return { textAlign: "center" };
        },
        formatter: (data) => {
          return <div className=" text-center">{data}</div>;
        },
      },
      {
        dataField: "commissionPerDriver",
        text: "Total Commission per Driver",
        headerStyle: () => {
          return { textAlign: "right", width: "250px" };
        },
        formatter: (data) => {
          return (
            <div className=" text-ellipsis overflow-hidden text-center font-sans">
              ₱{data?.toFixed(2)}
            </div>
          );
        },
      },
    ];

    const printLayout = () => {
      const { data, date, totalCommission } = formValues ?? {};
      return (
        <div className="flex flex-col gap-5 p-5">
          <div className="flex flex-row justify-start">
            <div className="flex flex-col gap-1">
              <div className="text-xl font-medium">
                {moment(date)?.format("LL")}{" "}
              </div>
              <div>
                Total Commission :{" "}
                <span className="font-sans font-medium">
                  ₱{totalCommission?.toFixed(0)}
                </span>{" "}
              </div>
            </div>
          </div>
          <GlobalBoostrapTable
            rows={data}
            columns={viewColumns}
            forPrinting={true}
          />
        </div>
      );
    };

    const propsContainer = {
      csvtitle: formValues?.date,
      CSVheaders: ViewCSVheaders,
      CSVdata: ViewCSVdata,
      showPrintAndExport: true,
      printLayout: () => printLayout(),
    };
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-row justify-between">
          <div>{backButton()}</div>
          <div>
            <GlobalPrintAndExport props={propsContainer} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg">
          <ViewReports formValues={formValues} />
        </div>
      </div>
    );
  };

  const CSVheaders = [
    { label: "Date", key: "newDate" },
    { label: "Total Commission", key: "totalCommission" },
  ];

  const CSVdata = [...reports];

  return (
    <>
      <NavBar title="Reports" />
      {openViewModal ? (
        showViewModal()
      ) : (
        <DataTable
          rows={reports}
          columns={columns}
          printCol={columns}
          title="List of reports"
          showPrintAndExport={true}
          printTitle={"List of reports"}
          CSVheaders={CSVheaders}
          CSVdata={CSVdata}
          csvtitle="reports.csv"
        />
      )}
    </>
  );
}

export default Reports;
