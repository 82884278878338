import React, { useEffect, useState, memo } from "react";
import NavBar from "Components/NavBar/NavBar";
import { BarChart } from "Components/Chart/BarChart";
import { dashboardStore } from "../../../Zustand/AdminStore/dashboardStore";
import shallow from "zustand/shallow";
import bookingIcon from "Assets/Image/bell.png";
import driverIcon from "Assets/Image/user.png";

function AdminDashboard() {
  const [greet, setGreet] = useState("Morning");

  const [months, setMonths] = useState("select");
  const { booking, driver, fetchDashboard } = dashboardStore(
    (state) => state,
    shallow
  );

  useEffect(() => {
    const d = new Date();
    let hour = d.getHours();
    if (hour <= 11) {
      setGreet("Morning");
    } else if (hour > 11 || hour < 6) {
      setGreet("Afternoon");
    } else {
      setGreet("Evening");
    }

    fetchDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Dashboardheader = () => {
    return (
      <div className="flex flex-row justify-start py-5 items-center bg-white drop-shadow-md rounded-[20px] ">
        <div className="flex flex-col gap-2 px-10">
          <div className="font-medium text-neutralblack text-2xl">
            Good {greet} Admin
          </div>
          <div className="font-normal text-neutralgray text-base">
            Hope you have a good day
          </div>
        </div>
      </div>
    );
  };

  const activeBookingLayout = () => {
    return (
      <div className="flex flex-row w-full bg-lightpink">
        <div className="flex flex-col p-5 justify-center h-full w-full">
          <div className="text-neutraldarkgray font-normal">Total No. of </div>
          <div className="text-neutraldarkgray font-medium text-lg lg:text-2xl">
            Active Bookings
          </div>
          <div
            id="bookingCount"
            className="text-pickAppPrimaryColor font-medium text-6xl pt-5"
          >
            {booking > 0 ? booking : 0}
          </div>
        </div>
        <div className="hidden md:flex p-5">
          <img
            src={bookingIcon}
            alt="bookingIcon"
            className="h-20 w-20 lg:h-32 lg:w-[10rem]"
          />
        </div>
      </div>
    );
  };

  const activeUserLayout = () => {
    return (
      <div className="flex flex-row w-full bg-lightpink">
        <div className="flex flex-col p-5 justify-center h-full w-full">
          <div className="text-neutraldarkgray font-normal">Total No. of </div>
          <div className="text-neutraldarkgray font-medium text-lg lg:text-2xl">
            Active Drivers
          </div>
          <div
            id="driverCount"
            className="text-pickAppPrimaryColor font-medium text-6xl pt-5"
          >
            {driver > 0 ? driver : 0}
          </div>
        </div>
        <div className="p-5 hidden md:flex ">
          <img
            src={driverIcon}
            alt="driverIcon"
            className="h-20 w-20 lg:h-32 lg:w-[12rem]"
          />
        </div>
      </div>
    );
  };

  const totalCommissionGraph = () => {
    const labels = [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      <div className="flex flex-col bg-white drop-shadow-md rounded-[20px] ">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col p-5 justify-center">
            <div className="text-neutraldarkgray font-normal">Total</div>
            <div className="text-neutraldarkgray font-medium text-2xl">
              Commissions
            </div>
          </div>
          <div className="px-5">
            <select
              value={months}
              onChange={(e) => setMonths(e.target.value)}
              className="focus:outline-none text-lightgray"
            >
              <option value="select">Months</option>
              {labels?.map((data) => {
                return (
                  <option value={data} key={data}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <BarChart seletedMonths={months} />
      </div>
    );
  };

  return (
    <>
      <NavBar title="Dashboard" />
      <div className="bg-white bg-cover rounded-xl p-10">
        <div className="flex flex-col gap-5">
          {Dashboardheader()}
          <div className="flex flex-row items-center gap-5 h-full">
            <div className="flex flex-row justify-between gap-2 bg-lightyellow drop-shadow-sm rounded-[20px] w-full h-full">
              {activeBookingLayout()}
            </div>
            <div className="flex flex-row justify-between gap-2 bg-lightyellow drop-shadow-sm rounded-[20px] w-full h-full">
              {activeUserLayout()}
            </div>
          </div>
          <div>{totalCommissionGraph()}</div>
        </div>
      </div>
    </>
  );
}

export default memo(AdminDashboard);
