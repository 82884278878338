import React from "react";
import GlobalRequiredField from "../Global/GlobalRequiredField";

export const CustomSelect = (props) => {
  const { label, fieldType, isEmpty, value, subLabel, options, ...rest } =
    props ?? {};
  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-neutraldarkgray text-xs">
          {label}{" "}
          {subLabel && <span className="text-neutralgray">({subLabel})</span>}
          <GlobalRequiredField
            fieldType={fieldType}
            value={value}
            isEmpty={isEmpty}
          />
        </label>
      )}

      <select {...rest} value={value} className="customInput">
        {options?.length > 0 &&
          options?.map(({ label, value, _id }) => (
            <option value={value} key={_id}>
              {label}
            </option>
          ))}
      </select>
    </div>
  );
};
