export const onGoingBookingStatus = [
  {
    value: "processing",
    label: "Processing",
  },
  {
    value: "ongoing",
    label: "On-going",
  },
  {
    value: "arrived",
    label: "Arrived",
  },
  {
    value: "pickup",
    label: "Picked Up",
  },
];

export const allBookingStatus = [
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "no-driver",
    label: "No Driver",
  },
];
