import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
const Backdrop = ({ show }) => {
  return (
    <>
      {show ? (
        <div className="bg-LoginBG flex justify-center absolute min-h-full bg-center bg-cover w-full z-40 items-center">
          <Player
            autoplay
            loop
            src="https://assets8.lottiefiles.com/packages/lf20_jF5XGwtT9D.json"
            style={{ height: "10rem", width: "10rem" }}
          />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(Backdrop);
