import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const cashinObjectDetails = {
  cashin: [],
};

const apiRequest = async (set) => {
  return await routesGetApi("/cashin/?role=passenger").then((res) => {
    if (res.status === 200) {
      return set({
        cashin: res.data,
      });
    }
  });
};

const storeCashIn = async (set) => {
  return apiRequest(set);
};

const storeCashInTimeout = async (set) => {
  return APIFetch(() => apiRequest(set));
};

const storeAllCasin = async (set, data) => {
  return set({
    cashin: data,
  });
};

const cashinStoreObject = (set) => ({
  ...cashinObjectDetails,
  storeCashInDetails: () => storeCashIn(set),
  fetchCashin: () => storeCashInTimeout(set),
  getAllCashin: (data) => storeAllCasin(set, data),
});

export const cashInStore = create(cashinStoreObject);
