export const statusList = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "declined",
    label: "Declined",
  },
  {
    value: "approved",
    label: "Approved",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];
