import React, { useEffect, useState, memo } from "react";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { PrivateRoutes } from "routes";
import { routesGetAllApi } from "Api/api";

import {
  ShowArchiveAlert,
  ShowDiscardAlert,
  ShowResetAlert,
  ShowSuccessAlert,
  ShowWarningAlert,
} from "../Global/GlobalAlert";

import { accountLoginDetailsStore } from "../../Zustand/UserInformationStore/accountLoginDetailsStore";
import { menuStore } from "../../Zustand/Menu/menuStore";
import { settingsStore } from "../../Zustand/AdminStore/settingsStore";
import { withdrawalStore } from "../../Zustand/AdminStore/withdrawalStore";
import { alertModalStore } from "../../Zustand/AlertModalStore/alertModalStore";
import { creditStore } from "../../Zustand/AdminStore/creditStore";
import { serviceStore } from "../../Zustand/AdminStore/serviceStore";
import { cashInStore } from "../../Zustand/AdminStore/cashinStore";
import { vehicleStore } from "../../Zustand/AdminStore/vehicleStore";
import { driverStore } from "../../Zustand/AdminStore/driverStore";
import { passengerStore } from "../../Zustand/AdminStore/passengerStore";
import { bookingStore } from "../../Zustand/AdminStore/bookingStore";
import { dashboardStore } from "../../Zustand/AdminStore/dashboardStore";
import { reportStore } from "../../Zustand/AdminStore/reportStore";
import { promoCodeStore } from "../../Zustand/AdminStore/promoCodeStore";

import pickAppLogo from "Assets/Logo/pickapp_logo.png";
import * as RiIcons from "react-icons/ri";

import shallow from "zustand/shallow";
import moment from "moment";
import { clearAndLogout } from "Utils/ClearLocalStorage";
import { APIFetch } from "Utils/timeout";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { storeUserInfo } = accountLoginDetailsStore((state) => state, shallow);

  const { openConfirmModal, discardModal, openDiscardModal } = alertModalStore(
    (state) => state,
    shallow
  );

  const { getAllPassenger } = passengerStore((state) => state, shallow);
  const { getAllDasboard } = dashboardStore((state) => state, shallow);
  const { getAllSettings } = settingsStore((state) => state, shallow);
  const { getAllService } = serviceStore((state) => state, shallow);
  const { getAllReports } = reportStore((state) => state, shallow);
  const { getAllwithdrawal } = withdrawalStore((state) => state, shallow);
  const { getAllCredit } = creditStore((state) => state, shallow);
  const { getAllCashin } = cashInStore((state) => state, shallow);
  const { getAllPromoCode } = promoCodeStore((state) => state, shallow);
  const { getAllVehicle, getUnassignedVehicle } = vehicleStore(
    (state) => state,
    shallow
  );
  const { getAllDriver, getAllActiveDrivers, getUnassignedDriver } =
    driverStore((state) => state, shallow);
  const { getAllBooking, getAllOnGoingBooking } = bookingStore(
    (state) => state,
    shallow
  );

  const [userRole, setUserRole] = useState(null);
  const { isOpenMenu, storeMenuUpdate } = menuStore((state) => state, shallow);

  const title = pathname.split("/");
  const routesList = [
    "/users/info",
    "/dashboard/bookings",
    "/dashboard/drivers",
    `/dashboard/monthlycommission?&year_no=${moment()?.year()}`,
    "/users/?role=passenger",
    "/settings",
    "/drivers",
    "/drivers/?unassigned=1",
    "/drivers/online",
    "/servicetype",
    "/withdrawal",
    "/cashin/?role=passenger",
    "/cashin/?role=driver",
    "/vehicle",
    "/vehicle/?unassigned=1",
    "/bookings",
    "/bookings/?status=ongoing",
    "/reports",
    "/promocode",
  ];

  function apiResponseStorage(ApiName, response) {
    const matchApi = routesList.findIndex((data) => data === ApiName);
    return response[matchApi]?.data ?? [];
  }

  const handleResize = () => {
    if (window?.innerWidth > 770) {
      return storeMenuUpdate(false);
    } else {
      return;
    }
  };
  useEffect(() => {
    window?.addEventListener("resize", handleResize);

    return async () => {
      window?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const redirectChecker = () => {
    return openConfirmModal({
      title: "Manage Account",
      message: "Are you sure you want to logout?",
      confirmNameBtn: "Logout",
      closeNameBtn: "Cancel",
      modalAction: () => clearAndLogout(),
    });
  };

  const adminStores = (res) => {
    getAllDasboard(
      apiResponseStorage("/dashboard/bookings", res),
      apiResponseStorage("/dashboard/drivers", res),
      apiResponseStorage(
        `/dashboard/monthlycommission?&year_no=${moment()?.year()}`,
        res
      )
    );
    getAllPassenger(apiResponseStorage("/users/?role=passenger", res));
    getAllSettings(apiResponseStorage("/settings", res));
    getAllwithdrawal(apiResponseStorage("/withdrawal", res));
    getAllCredit(apiResponseStorage("/cashin/?role=driver", res));
    getAllService(apiResponseStorage("/servicetype", res));
    getAllCashin(apiResponseStorage("/cashin/?role=passenger", res));
    getAllVehicle(apiResponseStorage("/vehicle", res));
    getUnassignedVehicle(apiResponseStorage("/vehicle/?unassigned=1", res));
    getAllDriver(apiResponseStorage("/drivers", res));
    getUnassignedDriver(apiResponseStorage("/drivers/?unassigned=1", res));
    getAllBooking(apiResponseStorage("/bookings", res));
    getAllOnGoingBooking(apiResponseStorage("/bookings/?status=ongoing", res));
    getAllActiveDrivers(apiResponseStorage("/drivers/online", res));
    getAllReports(apiResponseStorage("/reports", res));
    getAllPromoCode(apiResponseStorage("/promocode", res));
  };

  const fetchList = async () => {
    await routesGetAllApi(routesList).then((res) => {
      if (res?.status !== 403) {
        if (res.status !== 401) {
          setUserRole(res[0].data?.userRoles);
          storeUserInfo(apiResponseStorage("/users/info", res));
          if (res[0]?.status !== 200) {
            redirectChecker();
          } else {
            adminStores(res);
          }
        } else {
          redirectChecker();
        }
      } else {
        redirectChecker();
      }
    });
  };

  useEffect(() => {
    APIFetch(async () => fetchList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredNavRoutes = PrivateRoutes.filter((item) => {
    return item.roleAccess.indexOf(userRole) >= 0;
  });

  const showDiscardModal = async ({ path }) => {
    return openDiscardModal({
      ...discardModal,
      isOpen: true,
      modalAction: async () => {
        navigate(path);
      },
    });
  };
  const Navigation = (item, index, currentRoute) => {
    return (
      !item.isdropdown && (
        <div key={index} id={title}>
          <Link Link to={item.path}>
            <div
              className={`${
                currentRoute
                  ? "text-white bg-gradient-to-t from-pickAppPrimaryColor to-pickAppSecondaryColor "
                  : "transition-transform hover:scale-105 text-customgray"
              } flex flex-row items-center gap-3  p-3 rounded-md w-full`}
            >
              {currentRoute ? item.activesvg : item.svg}
              <span className="font-normal lg:text-base text-xs">
                {item.title}{" "}
                {item?.subTitle && (
                  <span
                    className={`${
                      currentRoute ? "text-white" : "text-neutralgray"
                    }`}
                  >
                    {item?.subTitle}
                  </span>
                )}
              </span>
            </div>
          </Link>
        </div>
      )
    );
  };

  const NavigationDiscardModal = (item, index, currentRoute) => {
    return (
      <div key={index} id={title} onClick={() => showDiscardModal(item)}>
        <div>
          <div
            className={`${
              currentRoute
                ? "text-white bg-gradient-to-t from-[#D6504E] to-[#FBA24B] "
                : "transition-transform hover:scale-105 text-customgray"
            } flex flex-row items-center gap-3  p-3 rounded-md w-full cursor-pointer`}
          >
            {currentRoute ? item.activesvg : item.svg}
            <span className="font-normal lg:text-base text-xs">
              {item.title}{" "}
              {item?.subTitle && (
                <span
                  className={`${
                    currentRoute ? "text-white" : "text-neutralgray"
                  }`}
                >
                  {item?.subTitle}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const SidebarNavigate = (item, index) => {
    const currentRoute = item.path === pathname?.slice(1);

    return item.showonsidebar ? (
      <>
        {discardModal?.isChange && pathname === "/admin/settings"
          ? NavigationDiscardModal(item, index, currentRoute)
          : Navigation(item, index, currentRoute)}
      </>
    ) : null;
  };

  const renderMainComponent = (
    <div className="flex md:basis-4/5 lg:basis-4/5  w-full h-screen flex-col md:px-8 bg-lightyellow  overflow-y-auto">
      <div>{title[1] === userRole && <Outlet />}</div>
    </div>
  );

  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <ShowWarningAlert />
      <ShowResetAlert />
      <ShowSuccessAlert />
      <ShowArchiveAlert />
      <ShowDiscardAlert />

      <div
        className={`${
          isOpenMenu ? "md:flex absolute w-[70%] z-50 shadow-2xl" : "hidden "
        } md:flex md:basis-1/5 lg:basis-1/5  shadow-xl bg-color bg-white w-full`}
      >
        <div className="flex flex-col w-full h-screen">
          <div className="flex flex-row md:justify-start justify-between py-10 text-4xl font-semibold px-10 text-white">
            <img src={pickAppLogo} alt="pickAppLogo" />
            <button
              className=" flex md:hidden bg-white text-neutralblack rounded-md"
              onClick={() => storeMenuUpdate(false)}
            >
              <RiIcons.RiArrowLeftLine />
            </button>
          </div>
          <div className="flex flex-col gap-2 w-full px-2 lg:px-10 overflow-y-scroll scrollbar-hidden">
            <div className="h-[85vh] flex flex-col justify-between ">
              <div>
                {filteredNavRoutes.length > 0 &&
                  filteredNavRoutes?.map(SidebarNavigate)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderMainComponent}
    </div>
  );
};

export default memo(Sidebar);
