import { Menu, Transition } from "@headlessui/react";
import { DataTable } from "Components/Global/GlobalTable";
import React, { Fragment, useEffect, useState, memo } from "react";
import { routesGetApi, routesPutApi } from "Api/api";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import shallow from "zustand/shallow";
import NavBar from "Components/NavBar/NavBar";
import * as HiIcons from "react-icons/hi";
import { withdrawalStore } from "../../../Zustand/AdminStore/withdrawalStore";
import moment from "moment";
import GlobalModal from "Components/Global/GlobalModal";
import viewIcon from "Assets/ActionIcon/viewIcon.png";
import { styleStatusColor } from "Utils/styleStatusColor";
import { APIFetch } from "Utils/timeout";
import { statusList } from "Utils/status";
import { formatNumber } from "Utils/formatNumber";
import { CustomInput } from "Components/Input/CustomInput";
import FullnameFormat from "Utils/FullnameFormat";

function WithdrawRequest() {
  const { openConfirmModal, openSuccessModal } = alertModalStore(
    (state) => state,
    shallow
  );
  const { withdrawal, storeWithdrawalDetails, fetchWithdrawal } =
    withdrawalStore((state) => state, shallow);

  const [openViewModal, setOpenViewModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [validate, setValidate] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [btnLoading, setbtnLoading] = useState(false);

  useEffect(() => {
    fetchWithdrawal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormValues({});
    setValidate(false);
    setbtnLoading(false);
  };

  const viewWithdrawal = async (row) => {
    APIFetch(async () => {
      await routesGetApi(`/withdrawal/${row?._id}`).then(({ data, status }) => {
        if (status === 200) {
          setOpenViewModal(true);
          return setFormValues(data[0] ?? {});
        }
      });
    });
  };

  const name = (data) => {
    return (
      <div className="capitalize text-ellipsis overflow-hidden min-w-md">
        {FullnameFormat({ props: data })}
      </div>
    );
  };

  const columns = [
    {
      dataField: "transaction_id",
      text: "Transaction ID",
      formatter: (data, row) => {
        return (
          <div className="text-lightblue max-w-md ">
            <div
              className="cursor-pointer hover:text-gray-800 truncate normal-case"
              onClick={() => viewWithdrawal(row)}
            >
              {data}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Date and Time",
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md text-ellipsis overflow-hidden">
            {moment(data).format("LLL")}
          </div>
        );
      },
    },
    {
      dataField: "driver_id",
      text: "Name",
      formatter: (data) => name(data),
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (data) => {
        return (
          <div className="max-w-md min-w-md text-ellipsis overflow-hidden font-sans">
            ₱ {formatNumber(data?.toFixed(2))}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (data) => {
        return (
          <div
            className={`${styleStatusColor(
              data
            )} max-w-md min-w-md text-ellipsis overflow-hidden capitalize`}
          >
            {data}
          </div>
        );
      },
    },
    {
      dataField: "_id",
      text: "",
      hidden: false,
      csvExport: false,
      headerStyle: () => {
        return { width: 55 };
      },
      formatter: (_data, row) => {
        return (
          <Menu
            as="div"
            id="actionButton"
            className="relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center flex-row w-full rounded-md bg-opacity-20 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <HiIcons.HiOutlineDotsVertical className="h-5 w-5 text-gray" />
              </Menu.Button>
            </div>
            <Transition appear>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[1px]" />
              </Transition.Child>

              <Menu.Items className="absolute right-0 py-2 w-[11.5rem] shadow-2xl origin-top-right divide-y z-30 divide-gray-100 rounded-lg bg-white focus:outline-none">
                {actionMenu(row)}
              </Menu.Items>
            </Transition>
          </Menu>
        );
      },
    },
  ];

  const actionMenu = (row) => {
    const { status } = row ?? {};
    return status === "pending" ? (
      <div className="flex flex-col py-2">
        {actionList?.map((item) => {
          const { label, itemFunction, textColor } = item;
          return (
            <Menu.Item onClick={() => itemFunction(row)} key={label}>
              {() => (
                <div
                  className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
                >
                  <span className={`${textColor} font-normal`}>{label}</span>
                </div>
              )}
            </Menu.Item>
          );
        })}
      </div>
    ) : (
      <Menu.Item>
        {() => (
          <div
            className={`cursor-pointer flex flex-row items-center gap-2 px-4 py-1.5 hover:bg-blue-200`}
            onClick={() => {
              viewWithdrawal(row);
            }}
          >
            <img src={viewIcon} alt="View Icon" />
            <span className="text-neutralblack font-normal">View</span>
          </div>
        )}
      </Menu.Item>
    );
  };

  const actionList = [
    {
      label: "Approve",
      textColor: "text-successgreen",
      itemFunction: (row) => {
        setOpenModal(true);
        setFormValues(row);
      },
    },
    {
      label: "Decline",
      textColor: "text-archivered",
      itemFunction: (row) => {
        askDeclinedModal(row);
      },
    },
  ];

  const askDeclinedModal = (row) => {
    return openConfirmModal({
      title: "Are You Sure",
      message: "Are you sure you want to update the status?",
      confirmNameBtn: "Yes, Update",
      closeNameBtn: "Cancel",
      modalAction: () => {
        apiFunction("declined", true, row?._id);
      },
    });
  };

  const askConfirmation = (status, isDeclined) => {
    setbtnLoading(true);
    if (!formValues?.gcash_refid) {
      setbtnLoading(false);
      return setValidate(true);
    }
    return apiFunction(status, isDeclined);
  };

  const apiFunction = async (status, isDeclined, _id) => {
    const withdrawalId = isDeclined ? _id : formValues?._id;
    const params = {
      gcash_refid: formValues?.gcash_refid,
      status: status,
    };

    isDeclined && delete params.gcash_refid;

    await routesPutApi(`/withdrawal/${withdrawalId}`, params).then((res) => {
      if (res.status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        handleCloseModal();
        return storeWithdrawalDetails();
      } else {
        setbtnLoading(false);
      }
    });
  };

  const validateFields = () => {
    openConfirmModal({
      title: "Are You Sure",
      message: "Are you sure you want to update the status?",
      confirmNameBtn: "Yes, Update",
      closeNameBtn: "Cancel",
      modalAction: () => {
        askConfirmation("approved", false);
      },
    });
  };

  const showAddUpdateModal = () => {
    const body = () => {
      return (
        <div class="grid grid-cols-1 gap-3">
          <CustomInput
            value={formValues.gcash_refid}
            label="GCASH Reference No."
            isEmpty={validate}
            fieldType="text"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                gcash_refid: e.target.value,
              })
            }
            name="gcash_refid"
            type="text"
          />
        </div>
      );
    };
    const props = {
      body: body(),
      isOpen: openModal,
      width: "lg",
      header: "Approve Withdrawal Request",
      closeFunction: handleCloseModal,
      closeBtnName: "Cancel",
      submitFunction: validateFields,
      submitBtnName: "Approve",
      headerStyle: "text-neutralblack",
      btnLoading,
    };

    return <GlobalModal props={props} />;
  };

  const viewModalContent = ({ label, value, _id }) =>
    value && (
      <div className="grid grid-cols-3 gap-2" key={_id}>
        <div className="text-darkgray text-sm col-span-1">{label}</div>
        <div
          className={`${
            _id === 4 ? "font-sans" : ""
          } text-base  col-span-2 break-all`}
        >
          {value}
        </div>
      </div>
    );

  const showViewModal = () => {
    const { createdAt, fullName, amount, mobileNum, email, gcash_refid } =
      formValues || {};

    const contentDetails = [
      {
        _id: 1,
        label: "Name",
        value: fullName,
      },
      {
        _id: 2,
        label: "Contact Number",
        value: "0" + mobileNum,
      },
      {
        _id: 3,
        label: "Email Address",
        value: email,
      },
      {
        _id: 4,
        label: "Amount Requested",
        value: "₱ " + formatNumber(amount?.toFixed(2)),
      },
      {
        _id: 5,
        label: "Date Requested",
        value: moment(createdAt).format("LLL"),
      },
      {
        _id: 6,
        label: "GCASH Reference No.",
        value: gcash_refid,
      },
    ];

    const body = () => {
      return (
        <div class="grid grid-cols-1 gap-4 px-2 py-5 border rounded-lg border-neutralwhite">
          {contentDetails.map((item) => viewModalContent(item))}
        </div>
      );
    };
    const closeModal = () => {
      setOpenViewModal(false);
    };
    const props = {
      body: body(),
      isOpen: openViewModal,
      width: "2xl",
      header: `Transaction Id : ${formValues?.transaction_id}`,
      modalType: "View",
      hideCloseBtn: true,
      submitFunction: closeModal,
      submitBtnName: "Close",
      headerStyle: "text-neutralblack truncate",
    };

    return <GlobalModal props={props} />;
  };

  return (
    <>
      <NavBar title="Withdraw Request" />
      {openViewModal && showViewModal()}
      {openModal && showAddUpdateModal()}
      <DataTable
        rows={withdrawal}
        columns={columns}
        title="List of withdrawal request"
        csvtitle="withdrawal.csv"
        setOpenModal={setOpenModal}
        searchStatus={true}
        statusList={statusList}
      />
    </>
  );
}

export default memo(WithdrawRequest);
