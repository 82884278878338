import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import imgError from "Assets/Image/404img.png";
import * as HiIcons from "react-icons/hi";

function Error404Page() {
  const navigate = useNavigate();

  const navagator = () => {
    return navigate(-1);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-10 min-h-screen bg-LoginBG bg-cover bg-center">
      <div className="flex flex-col  justify-center items-center gap-1 p-5">
        <div className="text-8xl text-creamwhite">404</div>
        <img src={imgError} alt="imgError" />
        <h2 className="font-medium text-creamwhite text-3xl">Page Not Found</h2>
        <div className="text-creamwhite text-sm mb-2">
          Sorry, the page you are looking for could not be found.
        </div>
        <button
          onClick={() => navagator()}
          className="w-full bg-lightblue text-creamwhite rounded-md p-2 flex justify-center items-center"
        >
          <span>Go Back</span> <HiIcons.HiArrowSmRight />
        </button>
      </div>
    </div>
  );
}

export default memo(Error404Page);
