import React, { forwardRef, useRef } from "react";
import ReactToPrint from "react-to-print";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import BootstrapTable from "react-bootstrap-table-next";

function GlobalPrintAndExport({ props }) {
  const componentRef = useRef();

  const {
    rows,

    csvtitle,
    printCol,

    showPrintAndExport,

    CSVheaders,
    CSVdata,
    printTitle,
    printLayout,
  } = props;

  const renderExportBtn = () => {
    return (
      <CSVLink
        data={CSVdata}
        headers={CSVheaders}
        filename={csvtitle}
        className="flex flex-row items-center bg-neutralwhite shadow-md rounded-md py-1.5 px-3 gap-2 cursor-pointer transition-transform hover:scale-105"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.94856 14.3561C8.30389 14.3561 7.76612 13.8634 7.70982 13.2212C7.59212 11.8785 7.56233 10.5297 7.62055 9.18365C7.55028 9.17895 7.48002 9.17403 7.40977 9.16891L6.16847 9.07843C5.3751 9.0206 4.93159 8.13596 5.35992 7.46564C6.27341 6.03604 7.94777 4.436 9.32564 3.44622C9.72882 3.1566 10.2719 3.1566 10.675 3.44622C12.0529 4.436 13.7273 6.03604 14.6408 7.46563C15.0691 8.13596 14.6256 9.0206 13.8322 9.07843L12.5909 9.16891C12.5207 9.17403 12.4504 9.17895 12.3801 9.18365C12.4383 10.5297 12.4086 11.8785 12.2909 13.2212C12.2346 13.8634 11.6968 14.3561 11.0521 14.3561H8.94856ZM8.90156 8.63549C8.8068 10.1248 8.8245 11.6193 8.95453 13.1061H11.0461C11.1762 11.6193 11.1939 10.1248 11.0991 8.63549C11.0885 8.46852 11.1453 8.30425 11.2567 8.17947C11.3682 8.05468 11.525 7.97979 11.6921 7.97156C11.9616 7.95829 12.2309 7.94184 12.5 7.92222L13.4015 7.85651C12.6019 6.67876 11.6007 5.65021 10.4431 4.81867L10.0003 4.50064L9.5576 4.81867C8.40001 5.65022 7.39876 6.67876 6.59914 7.85651L7.50065 7.92222C7.7698 7.94184 8.03913 7.95829 8.30857 7.97156C8.47568 7.97979 8.63251 8.05468 8.74397 8.17947C8.85542 8.30425 8.91218 8.46852 8.90156 8.63549Z"
            fill="#EF9935"
          />
          <path
            d="M4.79199 14.6667C4.79199 14.3215 4.51217 14.0417 4.16699 14.0417C3.82181 14.0417 3.54199 14.3215 3.54199 14.6667V16.3334C3.54199 17.1388 4.19491 17.7917 5.00033 17.7917H15.0003C15.8057 17.7917 16.4587 17.1388 16.4587 16.3334V14.6667C16.4587 14.3215 16.1788 14.0417 15.8337 14.0417C15.4885 14.0417 15.2087 14.3215 15.2087 14.6667V16.3334C15.2087 16.4484 15.1154 16.5417 15.0003 16.5417H5.00033C4.88527 16.5417 4.79199 16.4484 4.79199 16.3334V14.6667Z"
            fill="#EF9935"
          />
          <defs>
            <radialGradient
              id="paint0_angular_44_62"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(2.95895 5.64664) rotate(75.5207) scale(14.3501 57.499)"
            >
              <stop offset="0.015625" stopColor="#343E5A" />
              <stop offset="0.938107" stopColor="#32498D" />
            </radialGradient>
            <radialGradient
              id="paint1_angular_44_62"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(2.95895 5.64664) rotate(75.5207) scale(14.3501 57.499)"
            >
              <stop offset="0.015625" stopColor="#343E5A" />
              <stop offset="0.938107" stopColor="#32498D" />
            </radialGradient>
          </defs>
        </svg>
        <span className="text-orange font-normal text-sm md:flex hidden">
          Export
        </span>
      </CSVLink>
    );
  };

  const renderPrintBtn = () => {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <div className="flex flex-row items-center bg-neutralwhite shadow-md rounded-md py-1.5 px-3 gap-2 cursor-pointer transition-transform hover:scale-105">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7916 9.66668C14.2163 9.66668 13.75 10.1331 13.75 10.7083C13.75 11.2836 14.2163 11.75 14.7916 11.75C15.3669 11.75 15.8333 11.2836 15.8333 10.7083C15.8333 10.1331 15.3669 9.66668 14.7916 9.66668Z"
                  fill="#EF9935"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.47758 7.26279L5.2083 7.07042V3.76086C5.2083 3.22479 5.61668 2.77698 6.15048 2.72771C8.71136 2.49132 11.2886 2.49132 13.8495 2.72771C14.3833 2.77698 14.7916 3.22479 14.7916 3.76086V7.07042L16.5224 7.26279C17.161 7.33376 17.6878 7.79495 17.8426 8.41854C18.3141 10.3176 18.3141 12.3031 17.8426 14.2021L17.6899 14.8173C17.6207 15.096 17.3705 15.2917 17.0833 15.2917H14.7916V17.2358C14.7916 17.7542 14.3988 18.1883 13.883 18.2398C11.3008 18.4981 8.69917 18.4981 6.1169 18.2398C5.6011 18.1883 5.2083 17.7542 5.2083 17.2358V15.2917H2.91666C2.62949 15.2917 2.37927 15.096 2.31007 14.8173L2.15734 14.2021C1.68584 12.3031 1.68584 10.3176 2.15734 8.41854C2.31217 7.79495 2.83899 7.33376 3.47758 7.26279ZM13.5416 6.94966C11.1845 6.75637 8.81546 6.75637 6.4583 6.94966V3.95507C8.81491 3.74886 11.185 3.74886 13.5416 3.95507V6.94966ZM13.5416 14.2514C13.5401 14.1198 13.4558 14.0064 13.3361 13.9641C11.1164 13.7673 8.88362 13.7673 6.66392 13.9641C6.54311 14.0068 6.45832 14.1219 6.45832 14.255V14.6667L6.4583 14.6719V17.0171C8.8142 17.239 11.1857 17.239 13.5416 17.0171V14.2514ZM16.5945 14.0417H14.7916V13.7642C14.7916 13.2458 14.3988 12.8118 13.883 12.7602C13.7702 12.7489 13.6573 12.7381 13.5444 12.7278C13.4971 12.7182 13.4489 12.7107 13.4001 12.7055C11.1399 12.462 8.86009 12.462 6.5999 12.7055C6.55105 12.7107 6.50292 12.7182 6.45562 12.7278C6.34268 12.7381 6.22977 12.7489 6.1169 12.7602C5.6011 12.8118 5.2083 13.2458 5.2083 13.7642V14.0417H3.40546L3.3705 13.9009C2.94811 12.1997 2.94811 10.421 3.3705 8.71976C3.39925 8.60396 3.49708 8.51832 3.61567 8.50514L5.35094 8.31226C8.44079 7.96884 11.5592 7.96884 14.649 8.31226L16.3843 8.50514C16.5029 8.51832 16.6007 8.60396 16.6295 8.71976C17.0519 10.421 17.0519 12.1997 16.6295 13.9009L16.5945 14.0417Z"
                  fill="#EF9935"
                />
                <defs>
                  <radialGradient
                    id="paint0_angular_44_4731"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1.06377 5.18726) rotate(73.2756) scale(15.8234 72.1779)"
                  >
                    <stop offset="0.015625" stopColor="#343E5A" />
                    <stop offset="0.938107" stopColor="#32498D" />
                  </radialGradient>
                  <radialGradient
                    id="paint1_angular_44_4731"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1.06377 5.18726) rotate(73.2756) scale(15.8234 72.1779)"
                  >
                    <stop offset="0.015625" stopColor="#343E5A" />
                    <stop offset="0.938107" stopColor="#32498D" />
                  </radialGradient>
                </defs>
              </svg>
              <span className="text-orange font-normal text-sm md:flex hidden ">
                Print
              </span>
            </div>
          )}
          content={() => componentRef?.current}
        />
      </>
    );
  };

  const ToPrint = forwardRef(({ props }, ref) => {
    return (
      <div ref={ref}>
        {printLayout ? (
          <div>{printLayout()}</div>
        ) : (
          <>
            <div className="text-center">
              <div className="p-3">{printTitle}</div>
            </div>

            <ToolkitProvider
              keyField="dataField"
              data={rows}
              columns={printCol}
            >
              {(props) => <BootstrapTable {...props.baseProps} />}
            </ToolkitProvider>
          </>
        )}
      </div>
    );
  });

  const loadPrintLayout = () => {
    return <ToPrint ref={componentRef} props={props?.baseProps} />;
  };
  return (
    <div>
      {showPrintAndExport && (
        <div className="flex flex-row items-center gap-2 md:gap-4">
          {renderExportBtn()}
          {renderPrintBtn()}
          <div className="hidden">{loadPrintLayout()}</div>
        </div>
      )}
    </div>
  );
}

export default GlobalPrintAndExport;
