import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import shallow from "zustand/shallow";
import { routesPostApi } from "Api/api";
import { toastAlert } from "Api/middleware";
import { alertModalStore } from "../../Zustand/AlertModalStore/alertModalStore";
import * as HiIcons from "react-icons/hi";

const ForgotPasswordModal = ({ forgotpassword, setForgotPassword }) => {
  const [username, setUsername] = useState("");
  const { openSuccessModal } = alertModalStore((state) => state, shallow);

  const handleCloseModal = () => {
    setForgotPassword(false);
    setUsername("");
  };

  const apiForgotPassword = async () => {
    if (username) {
      await routesPostApi("/auth/forgotpassword", {
        email: username,
        userRole: "admin",
      }).then((res) => {
        if (res.status === 200) {
          openSuccessModal({
            title: "Success",
            message:
              "We have sent a password recover instructions to your email.",
            closeNameBtn: "Back",
          });
          handleCloseModal();
        }
      });
    } else {
      toastAlert("info", "Please insert email.");
    }
  };

  return (
    <Transition appear show={forgotpassword} as={Fragment}>
      <div as="div" className="relative z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-xl transform overflow-hidden rounded-lg bg-white pt-4 text-left align-middle shadow-xl transition-all">
                <div
                  as="h3"
                  className="flex flex-row gap-2 items-center text-base font-semibold leading-[-0.02em] text-customblack py-2 px-6"
                >
                  <label className="text-[#313131] text-xl font-medium">
                    Reset Password
                  </label>
                </div>
                <div className="p-6">
                  <div class="grid grid-cols-1 gap-8">
                    <div className="text-base [#313131] ">
                      To reset your password, you need to enter your email
                      address.
                    </div>
                    <div className="flex flex-col">
                      <label className="text-[#C2C2C2] text-xs">Email</label>
                      <div className="relative">
                        <input
                          id="emailInput"
                          type="email"
                          className="customInput"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <svg
                          className="absolute right-0 top-2.5 mx-4"
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.336366 3.96098C0.102913 6.13006 0.11332 8.63171 0.438172 10.792C0.617921 11.9873 1.59348 12.9037 2.7977 13.0084L4.05623 13.1178C6.68043 13.3459 9.31949 13.3459 11.9437 13.1178L13.2022 13.0084C14.4064 12.9037 15.382 11.9873 15.5617 10.792C15.8866 8.6317 15.897 6.1302 15.6636 3.96111C15.6334 3.70964 15.5994 3.45851 15.5617 3.2078C15.382 2.01248 14.4064 1.09611 13.2022 0.991427L11.9437 0.882025C9.31948 0.653908 6.68043 0.653908 4.05623 0.882025L2.7977 0.991427C1.59348 1.09611 0.617921 2.01248 0.438172 3.2078C0.400479 3.45847 0.366543 3.70955 0.336366 3.96098ZM4.16448 2.12733C6.71665 1.90547 9.28326 1.90547 11.8354 2.12733L13.094 2.23673C13.7226 2.29137 14.2318 2.76972 14.3256 3.39368C14.3354 3.45848 14.3449 3.52331 14.3541 3.58817L9.71992 6.1627C8.65025 6.75696 7.34961 6.75696 6.27994 6.1627L1.64583 3.5882C1.65505 3.52333 1.66453 3.45849 1.67427 3.39368C1.7681 2.76972 2.27735 2.29137 2.90595 2.23673L4.16448 2.12733ZM14.507 4.93314C14.6687 6.82293 14.6082 8.72697 14.3256 10.6061C14.2318 11.2301 13.7226 11.7084 13.094 11.7631L11.8354 11.8725C9.28327 12.0943 6.71665 12.0943 4.16448 11.8725L2.90595 11.7631C2.27735 11.7084 1.7681 11.2301 1.67427 10.6061C1.3917 8.72698 1.33123 6.82295 1.49287 4.93317L5.67289 7.2554C7.12008 8.0594 8.87978 8.0594 10.327 7.2554L14.507 4.93314Z"
                            fill="#B7B7B7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div>
                      <button
                        className="w-full gradientBg text-creamwhite p-2 rounded-md"
                        onClick={apiForgotPassword}
                      >
                        Proceed
                      </button>
                    </div>
                    <button
                      className="flex flex-rows justify-center gap-2 items-center "
                      onClick={handleCloseModal}
                    >
                      <HiIcons.HiOutlineArrowNarrowLeft className="text-orange" />{" "}
                      <span className="text-lightgray hover:text-orange">
                        Back to Login
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ForgotPasswordModal;
