import React from "react";
import GlobalRequiredField from "../Global/GlobalRequiredField";

export const CustomTextArea = (props) => {
  const { label, fieldType, isEmpty, value, subLabel, ...rest } = props ?? {};
  return (
    <div className="flex flex-col">
      <label className="text-neutraldarkgray text-xs">
        {label}{" "}
        {subLabel && <span className="text-neutralgray">({subLabel})</span>}
        <GlobalRequiredField
          fieldType={fieldType}
          value={value}
          isEmpty={isEmpty}
        />
      </label>
      <textarea
        {...rest}
        value={value}
        rows="2"
        cols="40"
        className="customInput max-h-24 min-h-24"
      />
    </div>
  );
};
