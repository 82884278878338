import React from "react";
import * as AiIcons from "react-icons/ai";
import moment from "moment";

function VehicleDocuments({ documents }) {
  const documentList = () => {
    return (
      <div className="flex flex-col gap-2 divide-y-2">
        <div className="flex flex-col gap-2 divide-y-2 divide-slate-100">
          {documents?.length > 0 ? (
            documents?.map((data) => {
              const { file, fileType, date_added, _id } = data;
              const getFileName = file.split("/");
              return (
                <div
                  className="flex justify-between items-center pt-2"
                  key={_id}
                >
                  <div className="flex flex-col gap-1">
                    <div className="text-sm font-medium">{fileType}</div>
                    <a
                      className="text-[#929292] text-sm flex flex-row gap-1 items-center hover:text-neutralblack"
                      href={file}
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <AiIcons.AiOutlineFilePdf />{" "}
                      <span>{getFileName[getFileName?.length - 1]}</span>
                    </a>
                  </div>
                  <div className="text-sm text-neutraldark text-center">
                    Date Added: {moment(date_added).format("MM/DD/YYYY")}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-lightgray text-sm flex justify-center items-center">
              No documents added.
            </div>
          )}
        </div>
      </div>
    );
  };

  return <div className="flex flex-col gap-2">{documentList()}</div>;
}

export default VehicleDocuments;
