import React from "react";
import * as RiIcons from "react-icons/ri";
export default function StarRating({ rating, size }) {
  const getPoint = rating?.toString()?.split(".");
  const points = getPoint?.length > 1 ? getPoint[1]?.charAt(0) * 1 : 0;

  const subPointValue = points >= 5 ? 1 : 0;

  function createStarArr(toMap) {
    return Array(toMap);
  }

  const createHalfStar = () => {
    return points >= 5 && <RiIcons.RiStarHalfFill />;
  };

  return (
    <div
      className={`flex flex-row gap-1 text-lightorange ${
        size ? `text-${size}` : "text-xl"
      }`}
    >
      {createStarArr(parseFloat(rating?.toFixed(0) - subPointValue))
        ?.fill("star")
        ?.map((_data, index) => (
          <RiIcons.RiStarFill key={index} />
        ))}
      {createHalfStar()}
      {rating <= 5 &&
        createStarArr(5 - parseFloat(rating?.toFixed(0)))
          ?.fill("star")
          ?.map((_data, index) => <RiIcons.RiStarLine key={index} />)}
    </div>
  );
}
