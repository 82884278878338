import React, { useState } from "react";
import GlobalBoostrapTable from "Components/Global/GlobalBoostrapTable";
import * as BiIcons from "react-icons/bi";
import moment from "moment";
function ViewReports({ formValues }) {
  const { data, date, totalCommission } = formValues ?? {};

  const [search, setSearch] = useState("");
  const showViewModal = () => {
    const viewColumns = [
      {
        dataField: "driver",
        text: "Driver",
        formatter: (data) => {
          return (
            <div className=" max-w-md ">
              <div className=" truncate normal-case">{data}</div>
            </div>
          );
        },
      },
      {
        dataField: "noOfBooking",
        text: "No. of Bookings",
        headerStyle: () => {
          return { textAlign: "center" };
        },
        formatter: (data) => {
          return <div className=" text-center">{data}</div>;
        },
      },
      {
        dataField: "commissionPerDriver",
        text: "Total Commission per Driver",
        headerStyle: () => {
          return { textAlign: "right", width: "250px" };
        },
        formatter: (data) => {
          return (
            <div className=" text-ellipsis overflow-hidden text-center font-sans">
              ₱{data?.toFixed(2)}
            </div>
          );
        },
      },
    ];

    let input;

    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-medium">
              {moment(date).format("LL")}{" "}
            </div>
            <div>
              Total Commission :{" "}
              <span className="font-sans font-medium">
                ₱{totalCommission?.toFixed(0)}
              </span>{" "}
            </div>
          </div>
          <div>
            <div className="flex flex-row w-full">
              <div className="flex items-center border-y border-l border-neutralgray bg-white relative pl-2 rounded-l-md">
                <BiIcons.BiSearch className="text-neutralgray" />
              </div>
              <input
                id="tableSearch"
                className="border-y w-full border-r border-neutralgray rounded-y-md rounded-r-md py-1 px-1.5 placeholder:text-darkgray placeholder:text-xs placeholder:font-normal focus:outline-none"
                placeholder="Search.."
                ref={(n) => (input = n)}
                type="text"
                onChange={(e) => setSearch(input.value)}
              />
            </div>
          </div>
        </div>
        <GlobalBoostrapTable
          rows={data}
          columns={viewColumns}
          search={true}
          searchVal={search}
        />
      </div>
    );
  };
  return <div className="p-5">{showViewModal()}</div>;
}

export default ViewReports;
