import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { dashboardStore } from "../../Zustand/AdminStore/dashboardStore";

import shallow from "zustand/shallow";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      position: "none",
    },
    title: {
      display: false,
    },
  },
};

export function BarChart({ seletedMonths }) {
  const isSelect = seletedMonths === "select" ? false : true;

  const { monthlynotif } = dashboardStore((state) => state, shallow);

  const labels = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [month, setMonth] = useState([]);

  useEffect(() => {
    if (isSelect) {
      setMonth([seletedMonths]);
    } else {
      setMonth(labels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelect, seletedMonths]);
  const findMontlyNotif = (months) => {
    const getMonthTotalNotif = monthlynotif?.filter(
      (data) => data.month === months
    );

    if (getMonthTotalNotif?.length > 0) {
      return getMonthTotalNotif[0]?.monthlyEarnings;
    } else {
      return 0;
    }
  };

  const findSingleData = () => {
    const getMonthTotalNotif = monthlynotif?.filter(
      (data) => data.month === seletedMonths
    );

    if (getMonthTotalNotif?.length > 0) {
      return getMonthTotalNotif[0]?.monthlyEarnings;
    } else {
      return 0;
    }
  };

  const data = {
    labels: month,
    datasets: [
      {
        label: "Total Commision",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, "#D6504E");
          gradient.addColorStop(1, "#FBA24B");

          return gradient;
        },
        borderWidth: 1,
        hoverBackgroundColor: "#D6504E",
        cursor: "pointer",
        data: labels?.map((data) =>
          seletedMonths !== "select" ? findSingleData() : findMontlyNotif(data)
        ),
      },
    ],
  };

  return <Bar options={options} data={data} className="max-h-64 w-full p-5" />;
}
