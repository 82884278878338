import React, { memo } from "react";
import noNet from "Assets/Image/noconnection.png";
function NoConnnection() {
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-LoginBG bg-cover bg-center">
      <div className="flex flex-col justify-center items-center gap-3 ">
        <img src={noNet} alt="notNet" />
        <h2 className="text-creamwhite  font-medium text-3xl">
          No Internet Connection
        </h2>
        <div className="text-creamwhite  font-normal text-sm">
          Check your network and try again.
        </div>
        <button
          className="bg-lightblue text-creamwhite w-full font-normal text-sm p-2 rounded-md"
          onClick={() => refreshPage()}
        >
          Try again
        </button>
      </div>
    </div>
  );
}

export default memo(NoConnnection);
