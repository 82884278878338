import moment from "moment";
import { formatNumber } from "Utils/formatNumber";
import { formatPaymentType } from "Utils/formatPaymentType";

const typeFormat = (type) => {
  switch (type) {
    case "cashin":
      return "Cash In";
    case "fare":
      return "Fare Fee";
    default:
      break;
  }
};

export const walletCol = [
  {
    dataField: "date",
    text: "Date and Time",
    formatter: (data) => moment(data).format("LLL"),
  },
  {
    dataField: "id",
    text: "ID",
    formatter: (data) => data,
  },
  {
    dataField: "paymentMethod",
    text: "Payment Type",
    formatter: (data) => formatPaymentType(data),
  },
  {
    dataField: "amount",
    text: "Transaction",
    formatter: (data, row) => (
      <div className="capitalize flex gap-1">
        {typeFormat(row.type)}
        <span
          className={`${
            row.type === "cashin" ? "text-successgreen" : "text-archivered"
          } font-sans`}
        >{`(${
          row.type === "cashin"
            ? "+ ₱" + formatNumber(data?.toFixed(2))
            : "- ₱" + formatNumber(data?.toFixed(2))
        })`}</span>
      </div>
    ),
  },
];
