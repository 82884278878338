import NavBar from "Components/NavBar/NavBar";
import ReactGoogleMap from "Components/GoogleMap/ReactGoogleMap";

const GoogleMap = () => {
  const center = {
    locLat: 14.606879699976139,
    locLng: 121.00057608168514,
  };
  return (
    <>
      <NavBar title="Map" />
      <div className="text-neutraldark font-normal text-sm md:text-base pb-5">
        Track all pickapp drivers
      </div>
      <div className="bg-white bg-cover rounded-xl p-3">
        <div className="flex flex-col gap-5">
          <ReactGoogleMap center={center} />
        </div>
      </div>
    </>
  );
};
export default GoogleMap;
