import React, { useState } from "react";
import defaultProfile from "Assets/Image/defaultProfile.png";

import * as Io5 from "react-icons/io5";
import GlobalModal from "Components/Global/GlobalModal";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import shallow from "zustand/shallow";
import moment from "moment";
import StarRating from "Components/Global/StarRating";
import FullnameFormat from "Utils/FullnameFormat";
import { formatNumber } from "Utils/formatNumber";

function PersonalDetails() {
  const [viewVehicle, setViewVehicle] = useState(false);
  const { driverDetails } = driverStore((state) => state, shallow);

  const {
    image,
    address,
    birthDate,
    email,
    gender,
    mobileNum,
    createdAt,
    vehicle_id,
    totalCredits,
    totalEarnings,
    rating,
    totalTrips,
    acceptanceRatings,
  } = driverDetails ?? {};

  const driverLayout = () => {
    const { brand, color, plate_no } = vehicle_id ?? {};

    const vehicle = plate_no + " - " + brand + ` (${color})`;

    return (
      <div className="flex flex-col gap-2 lg:flex-row justify-center lg:justify-between items-center">
        <div className="flex flex-row gap-2 items-center">
          <img
            src={image ?? defaultProfile}
            alt="defaultProfile"
            className="h-36 w-36 rounded-md border border-pickAppRedDefault"
          />
          <div className="flex flex-col gap-2">
            <div className="font-medium capitalize">
              {FullnameFormat({ props: driverDetails })}
            </div>

            <div className="text-sm text-neutraldark">
              {vehicle_id ? vehicle : "No vehicle assigned"}
            </div>

            <div className="text-sm text-neutraldark">+63{mobileNum}</div>
            <div className="flex gap-1 items-center">
              <StarRating rating={rating ?? 0} />
              <span>{rating ?? 0}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const driverDetailsLayout = () => {
    const details = [
      {
        _id: 1,
        label: "Full Name",
        value: FullnameFormat({ props: driverDetails, isMiddleName: true }),
      },
      {
        _id: 2,
        label: "Date Registered",
        value: moment(createdAt).format("LL"),
      },
      {
        _id: 3,
        label: "Gender",
        value: gender === "no-response" ? "Prefer not to respond" : gender,
      },
      {
        _id: 4,
        label: "Email Address",
        value: email,
      },
      {
        _id: 5,
        label: "Birthdate",
        value: moment(birthDate).format("LL"),
      },
      {
        _id: 6,
        label: "Contact Number",
        value: `0${mobileNum}`,
      },
      {
        _id: 7,
        label: "Address",
        value: address,
      },
    ];
    return (
      <div className="flex flex-col gap-2 pt-4">
        <div className="flex flex-col gap-1">
          <div className="text-neutralblack text-sm">Driver Information</div>
          <div className="grid grid-col grid-cols-1 lg:grid-cols-2 gap-2">
            {details.map(({ label, value, _id }) => (
              <div className="grid grid-cols-12" key={_id}>
                <div className="text-[#929292] col-span-4">{label}</div>
                <div className={`col-span-8 ${_id === 3 ? "capitalize" : ""}`}>
                  {value && value !== "" ? value : "-"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const ratingAndBalanceLayount = () => {
    const valueStyle = "text-pickAppRedDefault font-medium text-xl";
    const labelStyle = "text-xs";
    const checkPercentage = (value) => {
      return value > 0 && value !== 100 ? value?.toFixed(2) : value;
    };
    const { acceptance, cancellation } = acceptanceRatings ?? {};
    return (
      <div className="grid md:flex grid-cols-3 md:flex-row md:justify-evenly gap-3 md:gap-1 pt-4  items-center">
        <div className="flex flex-col gap-1 items-center ">
          <div className={valueStyle}>{totalTrips}</div>
          <div className={labelStyle}>Trips</div>
        </div>
        <div className="border h-12 md:flex hidden" />
        <div className="flex flex-col gap-1 items-center">
          <div className={valueStyle}>{checkPercentage(acceptance ?? 0)}%</div>
          <div className={labelStyle}>Acceptance Rate</div>
        </div>
        <div className="border h-12 md:flex hidden" />
        <div className="flex flex-col gap-1 items-center">
          <div className={valueStyle}>
            {checkPercentage(cancellation ?? 0)}%
          </div>
          <div className={labelStyle}>Cancellation Rate</div>
        </div>
        <div className="border h-12 md:flex hidden" />
        <div className="flex flex-col gap-1 items-center">
          <div className={`${valueStyle} font-sans`}>
            ₱ {formatNumber(totalCredits?.toFixed(2))}
          </div>
          <div className={labelStyle}>Credit Balance</div>
        </div>
        <div className="border h-12 md:flex hidden" />
        <div className="flex flex-col gap-1 items-center">
          <div className={`${valueStyle} font-sans`}>
            ₱ {formatNumber(totalEarnings?.toFixed(2))}
          </div>
          <div className={labelStyle}>Total Earnings</div>
        </div>
      </div>
    );
  };

  const vehicleDetails = () => {
    const {
      brand,
      chassisNo,
      color,
      plate_no,
      serviceType_id,
      mvFileNo,
      engineNo,
      model,
      manufacturer,
    } = vehicle_id ?? {};

    const details = [
      {
        _id: 1,
        label: "Service Type",
        value: serviceType_id?.vehicleType,
      },
      {
        _id: 2,
        label: "Plate No.",
        value: plate_no,
      },
      {
        _id: 3,
        label: "Brand",
        value: brand,
      },
      {
        _id: 4,
        label: "Manufacturer",
        value: manufacturer,
      },
      {
        _id: 5,
        label: "Color",
        value: color,
      },
      {
        _id: 6,
        label: "Model",
        value: model,
      },
      {
        _id: 7,
        label: "Engine No.",
        value: engineNo,
      },
      {
        _id: 8,
        label: "Chassis Number",
        value: chassisNo,
      },
      {
        _id: 9,
        label: "MV File Number",
        value: mvFileNo,
      },
    ];
    return (
      <div className="flex flex-col gap-1 pt-4">
        <div className="text-neutralblack text-sm">Vehicle Information</div>

        <div className="flex flex-col gap-1">
          <div className="grid grid-col grid-cols-1 lg:grid-cols-2 gap-2">
            {details.map(({ label, value, _id }) => (
              <div className="grid grid-cols-12" key={_id}>
                <div className="text-[#929292] col-span-4">{label}</div>
                <div className="col-span-8">{value ?? "-"}</div>
              </div>
            ))}
          </div>
          {vehicle_id && (
            <div>
              <button
                className="flex flex-row gap-1 items-center"
                onClick={() => setViewVehicle(true)}
              >
                <Io5.IoImagesOutline className="text-orange" />{" "}
                <span className="text-orange underline">
                  View Vehicle Image
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const ShowViewModal = () => {
    const { image } = vehicle_id ?? {};

    const imageName = image?.split("/");
    const body = () => {
      return (
        <div className="flex flex-col gap-5">
          <div>
            <img src={image} alt={image} className="w-full h-[300px]" />
          </div>
          <div className="flex flex-col text-sm">
            <div>{image ? imageName[imageName?.length - 1] : ""}</div>
            <div>Added {moment(createdAt).format("LLL")}</div>
          </div>
        </div>
      );
    };

    const closeModal = () => {
      setViewVehicle(false);
    };
    const props = {
      body: body(),
      isOpen: image ? viewVehicle : false,
      width: "xl",
      hideHeader: true,
      modalType: "View",
      hideCloseBtn: true,
      submitFunction: closeModal,
      submitBtnName: "Close",
    };

    return <GlobalModal props={props} />;
  };

  return (
    <div className="flex  p-5 ">
      <ShowViewModal />
      <div className="flex flex-col gap-5 w-full divide-y-2 divide-dashed">
        {driverLayout()}
        {ratingAndBalanceLayount()}
        {driverDetailsLayout()}
        {vehicleDetails()}
      </div>
    </div>
  );
}

export default PersonalDetails;
