import React from "react";
import GlobalRequiredField from "../Global/GlobalRequiredField";
import makeAnimated from "react-select/animated";
import Select from "react-select";
export const MultiSelectInput = (props) => {
  const animatedComponents = makeAnimated();
  const { label, fieldType, isEmpty, errorValue, value, subLabel, ...rest } =
    props ?? {};
  return (
    <div className="flex flex-col">
      <label className="text-neutraldarkgray text-xs">
        {label}{" "}
        {subLabel && <span className="text-lightgray">({subLabel})</span>}
        <GlobalRequiredField
          fieldType={fieldType}
          value={errorValue}
          isEmpty={isEmpty}
        />
      </label>
      <Select
        closeMenuOnSelect={true}
        components={animatedComponents}
        classNamePrefix="mult-email-input"
        value={value}
        {...rest}
      />
    </div>
  );
};
