import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import LoadingLottie from "../Lottie/LoadingLottie";

function GlobalModal({ props }) {
  const {
    isOpen,
    width,
    header,
    subHeader,
    body,
    submitFunction,
    submitBtnName,
    closeFunction,
    closeBtnName,
    modalType,
    headerStyle,
    subHeaderStyle,
    hideCloseBtn,
    hideSubmitBtn,
    hideHeader,
    btnLoading,
  } = props;

  const addSvgIcon = () => {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.75941 11C4.75941 10.4822 5.17915 10.0625 5.69691 10.0625H10.0627V5.69671C10.0627 5.17894 10.4824 4.75921 11.0002 4.75921C11.518 4.75921 11.9377 5.17894 11.9377 5.69671V10.0625H16.3035C16.8213 10.0625 17.241 10.4822 17.241 11C17.241 11.5178 16.8213 11.9375 16.3035 11.9375H11.9377V16.3033C11.9377 16.8211 11.518 17.2408 11.0002 17.2408C10.4824 17.2408 10.0627 16.8211 10.0627 16.3033V11.9375H5.69691C5.17915 11.9375 4.75941 11.5178 4.75941 11Z"
          fill="#2D2D2D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.1461 0.710963C9.00554 0.279614 12.9948 0.279614 16.8542 0.710963C19.1372 0.966119 20.9808 2.76442 21.2494 5.06062C21.7109 9.00678 21.7109 12.9933 21.2494 16.9394C20.9808 19.2356 19.1372 21.0339 16.8542 21.2891C12.9948 21.7204 9.00554 21.7204 5.1461 21.2891C2.86312 21.0339 1.0195 19.2356 0.750939 16.9394C0.289401 12.9933 0.289401 9.00678 0.750939 5.06062C1.0195 2.76442 2.86312 0.966119 5.1461 0.710963ZM16.646 2.57436C12.9249 2.15848 9.07539 2.15848 5.35436 2.57436C3.92382 2.73424 2.77876 3.8633 2.61324 5.27844C2.16863 9.07987 2.16863 12.9202 2.61324 16.7216C2.77876 18.1367 3.92382 19.2658 5.35436 19.4257C9.07539 19.8416 12.9249 19.8416 16.646 19.4257C18.0765 19.2658 19.2216 18.1367 19.3871 16.7216C19.8317 12.9202 19.8317 9.07987 19.3871 5.27844C19.2216 3.8633 18.0765 2.73424 16.646 2.57436Z"
          fill="#2D2D2D"
        />
      </svg>
    );
  };

  const updateSvgIcon = () => {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.8184 4.76969C24.0587 5.13358 24.0187 5.62809 23.6983 5.94845L12.2078 17.4389C12.09 17.5567 11.943 17.641 11.7818 17.6831L6.99631 18.9331C6.84022 18.9739 6.67934 18.973 6.52817 18.9346C6.36747 18.8937 6.21775 18.8102 6.09648 18.6889C5.86114 18.4536 5.76821 18.1111 5.85233 17.7891L7.10233 13.0035C7.13916 12.8625 7.20828 12.7233 7.30388 12.614L18.837 1.08709C18.9379 0.986133 19.0595 0.910575 19.1919 0.864539C19.29 0.830405 19.394 0.8125 19.4999 0.8125C19.7485 0.8125 19.987 0.911272 20.1628 1.08709L23.6983 4.62262C23.7441 4.66839 23.7841 4.7177 23.8184 4.76969ZM21.7096 5.28553L19.4999 3.07582L8.85216 13.7235L8.07092 16.7145L11.0619 15.9332L21.7096 5.28553Z"
          fill="#2D2D2D"
        />
        <path
          d="M21.5516 19.4504C21.8933 16.5284 22.0021 13.586 21.8778 10.651C21.8718 10.5105 21.9247 10.3737 22.0241 10.2743L23.2533 9.04511C23.4044 8.89399 23.6626 8.98993 23.6768 9.20318C23.9079 12.6902 23.8203 16.1932 23.4139 19.6682C23.1182 22.1964 21.0878 24.1776 18.5727 24.4587C14.2395 24.943 9.76029 24.943 5.42707 24.4587C2.912 24.1776 0.881586 22.1964 0.585895 19.6682C0.0677225 15.2378 0.0677225 10.7622 0.585895 6.33179C0.881586 3.80363 2.912 1.82236 5.42707 1.54126C8.71426 1.17387 12.0854 1.08519 15.4132 1.2752C15.6276 1.28745 15.7254 1.54697 15.5735 1.69887L14.3328 2.93956C14.2345 3.03793 14.0996 3.09076 13.9605 3.08607C11.1775 2.9922 8.37556 3.0984 5.63534 3.40466C3.9727 3.59049 2.64084 4.9025 2.4482 6.5496C1.94695 10.8353 1.94695 15.1647 2.4482 19.4504C2.64084 21.0975 3.9727 22.4095 5.63534 22.5953C9.83015 23.0642 14.1696 23.0642 18.3644 22.5953C20.0271 22.4095 21.3589 21.0975 21.5516 19.4504Z"
          fill="#2D2D2D"
        />
      </svg>
    );
  };

  const bellSvgIcon = () => {
    return (
      <svg
        width="20"
        height="26"
        viewBox="0 0 20 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2485 1.74994C11.2485 1.05958 10.6889 0.499939 9.99852 0.499939C9.30816 0.499939 8.74852 1.05958 8.74852 1.74994V2.68744H8.05175C5.27041 2.68744 2.96841 4.84995 2.79479 7.62587L2.51854 12.0427C2.41321 13.7267 1.84785 15.3495 0.884025 16.7344C0.0131723 17.9857 0.788448 19.714 2.30211 19.8956L6.56102 20.4067V21.7499C6.56102 23.6484 8.10004 25.1874 9.99852 25.1874C11.897 25.1874 13.436 23.6484 13.436 21.7499V20.4067L17.6949 19.8956C19.2086 19.714 19.9839 17.9857 19.113 16.7344C18.1492 15.3495 17.5838 13.7267 17.4785 12.0427L17.2022 7.62587C17.0286 4.84995 14.7266 2.68744 11.9453 2.68744H11.2485V1.74994ZM8.05175 4.56244C6.26049 4.56244 4.77795 5.95515 4.66613 7.74291L4.38988 12.1597C4.26314 14.1862 3.58282 16.1389 2.42301 17.8055C2.36007 17.8959 2.4161 18.0208 2.52551 18.034L7.19758 18.5946C9.0582 18.8179 10.9388 18.8179 12.7994 18.5946L17.4715 18.034C17.5809 18.0208 17.637 17.8959 17.574 17.8055C16.4142 16.1389 15.7339 14.1862 15.6071 12.1597L15.3309 7.74291C15.2191 5.95515 13.7365 4.56244 11.9453 4.56244H8.05175ZM9.99852 23.3124C9.13557 23.3124 8.43602 22.6129 8.43602 21.7499V20.8124H11.561V21.7499C11.561 22.6129 10.8615 23.3124 9.99852 23.3124Z"
          fill="#32498D"
        />
      </svg>
    );
  };

  const checkModalIcon = (type) => {
    switch (type) {
      case "Add":
        return addSvgIcon();
      case "Update":
        return updateSvgIcon();
      case "View":
        return;
      case "Bell":
        return bellSvgIcon();
      default:
        break;
    }
  };

  const renderHeader = () => {
    return (
      <div
        as="h3"
        className={`flex flex-col ${
          subHeader ? "gap-2" : "gap-0"
        } font-semibold leading-[-0.02em] text-neutralblack py-2 px-6`}
      >
        <div className="flex flex-row gap-2 items-center text-base ">
          {checkModalIcon(modalType)}
          <label className={`${headerStyle} text-xl font-normal`}>
            {header}
          </label>
        </div>
        <div className={subHeaderStyle}>{subHeader}</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={`${hideCloseBtn && header ? "px-6 pb-6" : "p-6"}`}>
        {body}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="flex flex-row gap-2 justify-end  bg-lightyellow pt-4 pr-6 pb-4">
        {!hideCloseBtn && (
          <button
            className="rounded-full text-sm font-medium py-2 text-neutraldarkgray px-10"
            onClick={() => {
              closeFunction();
            }}
          >
            {closeBtnName}
          </button>
        )}
        {!hideSubmitBtn && (
          <button
            id="newNotificationButton"
            className={`rounded-md text-sm font-medium bg-customyellow py-2 px-2 w-32 gradientBg text-creamwhite`}
            onClick={() => {
              submitFunction();
            }}
            disabled={btnLoading}
          >
            {btnLoading ? <LoadingLottie /> : submitBtnName}
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <div className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className={`w-full max-w-${width} transform overflow-hidden rounded-lg bg-white pt-2 text-left align-middle shadow-xl transition-all`}
                >
                  {!hideHeader && renderHeader()}
                  {renderBody()}
                  {renderFooter()}
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default GlobalModal;
