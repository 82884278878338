import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { routesGetApi, routesPostApi } from "Api/api";
import SetPassword from "./SetPassword";
import { ShowSuccessAlert } from "Components/Global/GlobalAlert";
import ForgotPasswordModal from "./ForgotPassword";

function Login() {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [forgotpassword, setForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [storeUserData, setStoreUserData] = useState({});

  useEffect(() => {
    let userid = localStorage.getItem("token");
    if (userid) return navigate(-1);
    else navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLogin = async (e) => {
    e.preventDefault();
    const params = {
      ...formValues,
    };

    await routesPostApi("/auth/web", params).then(async (res) => {
      if (res.status === 200) {
        if (!res.data.redirect) {
          localStorage.setItem("token", res.data.token);
          getUserInfo();
        } else {
          setShowResetPassword(true);
          setStoreUserData(res.data);
          setFormValues({});
        }
      }
    });
  };

  const getUserInfo = async () => {
    await routesGetApi("/users/info").then(({ data, status }) => {
      if (status === 200) {
        setFormValues({});
        return navigate(`/${data.userRoles}/dashboard`);
      }
    });
  };

  const openForgotPassModal = () => {
    setForgotPassword(true);
  };

  const renderLoginUI = (
    <div className="flex flex-col justify-center items-center md:p-0 p-5 gap-10 min-h-screen bg-LoginBG bg-cover bg-center">
      <div className="flex flex-col gap-10   bg-white rounded-2xl max-w-md w-full py-14 px-6 sm:px-8">
        <div className="flex flex-col gap-2">
          <span className="text-base text-neutralblack font-normal">
            Welcome back
          </span>
          <span className="text-xl text-neutralblack font-medium">
            Sign In to your Account
          </span>
        </div>
        <form onSubmit={renderLogin} className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-0.5">
            <span className="text-neutralblack font-normal text-xs px-1">
              Email
            </span>
            <div className="relative">
              <input
                id="emailInput"
                type="email"
                className="customInput"
                value={formValues.username}
                onChange={(e) =>
                  setFormValues({ ...formValues, username: e.target.value })
                }
              />
              <svg
                className="absolute right-0 top-2.5 mx-4"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.336366 3.96098C0.102913 6.13006 0.11332 8.63171 0.438172 10.792C0.617921 11.9873 1.59348 12.9037 2.7977 13.0084L4.05623 13.1178C6.68043 13.3459 9.31949 13.3459 11.9437 13.1178L13.2022 13.0084C14.4064 12.9037 15.382 11.9873 15.5617 10.792C15.8866 8.6317 15.897 6.1302 15.6636 3.96111C15.6334 3.70964 15.5994 3.45851 15.5617 3.2078C15.382 2.01248 14.4064 1.09611 13.2022 0.991427L11.9437 0.882025C9.31948 0.653908 6.68043 0.653908 4.05623 0.882025L2.7977 0.991427C1.59348 1.09611 0.617921 2.01248 0.438172 3.2078C0.400479 3.45847 0.366543 3.70955 0.336366 3.96098ZM4.16448 2.12733C6.71665 1.90547 9.28326 1.90547 11.8354 2.12733L13.094 2.23673C13.7226 2.29137 14.2318 2.76972 14.3256 3.39368C14.3354 3.45848 14.3449 3.52331 14.3541 3.58817L9.71992 6.1627C8.65025 6.75696 7.34961 6.75696 6.27994 6.1627L1.64583 3.5882C1.65505 3.52333 1.66453 3.45849 1.67427 3.39368C1.7681 2.76972 2.27735 2.29137 2.90595 2.23673L4.16448 2.12733ZM14.507 4.93314C14.6687 6.82293 14.6082 8.72697 14.3256 10.6061C14.2318 11.2301 13.7226 11.7084 13.094 11.7631L11.8354 11.8725C9.28327 12.0943 6.71665 12.0943 4.16448 11.8725L2.90595 11.7631C2.27735 11.7084 1.7681 11.2301 1.67427 10.6061C1.3917 8.72698 1.33123 6.82295 1.49287 4.93317L5.67289 7.2554C7.12008 8.0594 8.87978 8.0594 10.327 7.2554L14.507 4.93314Z"
                  fill="#B7B7B7"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <span className="text-neutralblack font-normal text-xs px-1">
              Password
            </span>
            <div className="relative">
              <input
                id="passwordInput"
                type={showPassword ? "text" : "password"}
                className="customInput"
                value={formValues.password}
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
              />
              {showPassword ? (
                <FiIcons.FiEye
                  className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <FiIcons.FiEyeOff
                  className="absolute right-0 top-2.5 mx-4 text-[#B7B7B7] cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-1">
                <input type="checkbox" />
                <span className="text-neutralgray text-xs font-normal">
                  Remember Me
                </span>
              </div>
              <div>
                <span
                  className="text-neutralgray text-xs font-normal cursor-pointer"
                  onClick={() => openForgotPassModal()}
                >
                  Forgot Password?
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 items-center mt-6">
            <button
              id="loginButtonAction"
              className="text-white gradientBg font-normal rounded-lg w-full py-2"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <>
      <ShowSuccessAlert />
      {forgotpassword && (
        <ForgotPasswordModal
          setForgotPassword={setForgotPassword}
          forgotpassword={forgotpassword}
        />
      )}
      {!showResetPassword ? (
        renderLoginUI
      ) : (
        <SetPassword
          token={storeUserData?.token}
          setShowResetPassword={setShowResetPassword}
          showResetPassword={showResetPassword}
        />
      )}
    </>
  );
}

export default Login;
