import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import * as RiIcons from "react-icons/ri";
import { DataTable } from "Components/Global/GlobalTable";
import { creditCol, walletCol } from "./TableCol";
import shallow from "zustand/shallow";
import PersonalDetails from "./PersonalDetails";
import DriverDocuments from "./DriverDocuments";
import StatusStyle from "Components/StatusStyle/StatusStyle";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import { routesGetApi } from "Api/api";
import moment from "moment";
import Feedback from "./Feedback";
import ViewBooking from "Components/Booking/ViewBooking";
import { APIFetch } from "Utils/timeout";
import { formatNumber } from "Utils/formatNumber";
import emptyImage from "Assets/Image/empty.png";
import EmptyPage from "Components/EmptyPage/EmptyPage";

function DriverDetails({ props }) {
  const { setOpenViewModal } = props ?? {};

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openBooking, setViewBooking] = useState(false);
  const [viewBookingInfo, setViewBookingInfo] = useState({});
  const { wallet, credit, booking } = driverStore((state) => state, shallow);

  const tabPanelList = [
    {
      _id: 0,
      tabTitle: "Personal Details",
    },
    {
      _id: 1,
      tabTitle: "Documents",
    },
    {
      _id: 2,
      tabTitle: "Booking History",
    },
    {
      _id: 3,
      tabTitle: "Feedback",
    },
    {
      _id: 4,
      tabTitle: "Wallet",
    },
    {
      _id: 5,
      tabTitle: "Credit",
    },
  ];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const viewBooking = async (id) => {
    APIFetch(
      async () =>
        await routesGetApi(`/bookings/${id}`).then(({ status, data }) => {
          if (status === 200) {
            setViewBookingInfo(data ?? {});
            setViewBooking(true);
          }
        })
    );
  };

  const additionalCol = [
    {
      dataField: "booking_status",
      text: "Status",
      formatter: (data) => StatusStyle(data),
    },
    {
      dataField: "_id",
      text: "",
      formatter: (data) => {
        return (
          <button onClick={() => viewBooking(data)}>
            <RiIcons.RiArrowRightSLine />
          </button>
        );
      },
    },
  ];

  const historyCol = [
    {
      dataField: "dateTime",
      text: "Date and Time",
      formatter: (data) => moment(data).format("LLL"),
    },
    {
      dataField: "bookingNo",
      text: "Booking No.",
      formatter: (data, row) => (
        <button className="text-lightblue" onClick={() => viewBooking(row._id)}>
          {data}
        </button>
      ),
    },
    {
      dataField: "pickupLoc",
      text: "Pick-up Location",
      formatter: (data) => data ?? "--",
    },
    {
      dataField: "dropoffLoc",
      text: "Drop-off Location",
      formatter: (data) => data ?? "--",
    },
    {
      dataField: "bookingFare.earnings",
      text: "Earnings",
      formatter: (data) => (
        <span className="font-sans">
          {data ? `₱${formatNumber(data?.toFixed(2))}` : "--"}
        </span>
      ),
    },
  ];

  const tableCol = () => {
    switch (selectedIndex) {
      case 0:
        return <PersonalDetails />;
      case 1:
        return <DriverDocuments />;
      case 2:
        return [...historyCol, ...additionalCol];
      case 3:
        return <Feedback />;
      case 4:
        return [...walletCol];
      case 5:
        return [...creditCol];
    }
  };

  const tableRow = () => {
    switch (selectedIndex) {
      case 2:
        return booking;
      case 4:
        return wallet;
      case 5:
        return credit;
    }
  };

  const emptyText = (tabIndex) => {
    switch (tabIndex) {
      case 2:
        return "List of booking history";
      case 4:
        return "List of wallet transactions";
      case 5:
        return "List of credit transactions";
    }
  };

  const tabGroup = () => {
    return (
      <div className="flex flex-col bg-white rounded-md h-auto p-2 ">
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(e) => {
            setSelectedIndex(e);
          }}
        >
          <Tab.List
            selected
            className="flex space-x-1 rounded-xl bg-opacity-20 "
          >
            {tabPanelList && tabPanelList.length > 0
              ? tabPanelList.map(({ tabTitle, _id }) => (
                  <Tab
                    key={_id}
                    className={({ selected }) =>
                      classNames(
                        "w-40 py-2 text-sm font-normal antialiased focus:outline-none",
                        selected
                          ? "bg-white text-pickAppRedDefault border-b-2 border-pickAppRedDefault "
                          : "text-lightgray hover:text-neutraldarkgray "
                      )
                    }
                  >
                    {tabTitle ?? null}
                  </Tab>
                ))
              : null}
          </Tab.List>
          <hr />
          <Tab.Panels>
            {selectedIndex >= 2 && selectedIndex !== 3 ? (
              <div>
                {tableRow()?.length > 0 ? (
                  <DataTable
                    rows={tableRow()}
                    columns={tableCol()}
                    sizePerPage={6}
                  />
                ) : (
                  <EmptyPage
                    sublabel={`${emptyText(
                      selectedIndex
                    )} will appear on this page.`}
                  />
                )}
              </div>
            ) : (
              tableCol()
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  const MainPanel = () => {
    return <div className="flex flex-col">{tabGroup()}</div>;
  };

  const backButton = () => {
    return (
      <div>
        <button
          className="flex flex-row gap-1 items-center text-pickAppRedDefault"
          onClick={() =>
            openBooking ? setViewBooking(false) : setOpenViewModal(false)
          }
        >
          <RiIcons.RiArrowLeftLine />
          <span>Back</span>
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-5">
        <div>{backButton()}</div>
        <div className=" bg-white rounded-lg shadow-lg mb-4 ">
          {!openBooking ? (
            <MainPanel />
          ) : (
            <ViewBooking
              viewBookingInfo={viewBookingInfo}
              hideDriver={true}
              infoRow={1}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DriverDetails;
