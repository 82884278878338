import React from "react";
import GlobalRequiredField from "../Global/GlobalRequiredField";

export const CustomInput = (props) => {
  const { label, fieldType, isEmpty, value, subLabel, ...rest } = props ?? {};
  return (
    <div className="flex flex-col w-full">
      {label && (
        <label className="text-neutraldarkgray text-xs">
          {label}{" "}
          {subLabel && <span className="text-neutralgray">({subLabel})</span>}
          <GlobalRequiredField
            fieldType={fieldType}
            value={value}
            isEmpty={isEmpty}
          />
        </label>
      )}

      <input {...rest} value={value} className="customInput" />
    </div>
  );
};
