import React, { useState } from "react";
import StarRating from "Components/Global/StarRating";
import { driverStore } from "../../../Zustand/AdminStore/driverStore";
import shallow from "zustand/shallow";
import moment from "moment";
import * as RiIcons from "react-icons/ri";
import emptyImage from "Assets/Image/empty.png";

function Feedback() {
  const { feedback, driverDetails } = driverStore((state) => state, shallow);
  const { rating } = driverDetails ?? {};
  const [currentPage, setCurrentPage] = useState(1);

  const DriverRating = () => {
    return (
      <div className="flex flex-col gap-1 items-center">
        <span className="text-4xl text-neutralblack font-medium">{rating}</span>
        <StarRating rating={rating ?? 0} size={"2xl"} />
      </div>
    );
  };

  const FeebackList = () => {
    const itemsPerPage = 4;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = feedback?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(feedback.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    const getPageRange = () => {
      const maxPage = Math.ceil(feedback.length / itemsPerPage);
      const middlePage = Math.ceil(itemsPerPage / 2);

      if (currentPage <= middlePage) {
        return pageNumbers.slice(0, itemsPerPage);
      } else if (currentPage > maxPage - middlePage) {
        return pageNumbers.slice(maxPage - itemsPerPage);
      } else {
        return pageNumbers.slice(
          currentPage - middlePage - 1,
          currentPage + middlePage
        );
      }
    };

    return (
      <div className="flex flex-col gap-4 pt-5">
        {currentItems?.length > 0 &&
          currentItems?.map((data) => {
            const {
              comment,
              createdAt,
              star_rating,
              booking_id: { bookingNo },
            } = data ?? {};
            return (
              <div
                className="p-4 bg-dirtywhite rounded-md flex flex-col lg:flex-row gap-2"
                key={bookingNo}
              >
                <div className="basis-3/4">
                  <div className="flex flex-col gap-1">
                    <div className="text-neutralblack font-medium text-lg">
                      BOOKING NO. {bookingNo}
                    </div>
                    {comment && comment !== "" && (
                      <div className="text-sm text-neutraldark">
                        “{comment}”
                      </div>
                    )}

                    <div>
                      <StarRating rating={star_rating} size={"md"} />
                    </div>
                  </div>
                </div>
                <div className="basis-1/4 text-start lg:text-end">
                  {moment(createdAt)?.format("L")} |{" "}
                  {moment(createdAt)?.format("LT")}{" "}
                </div>
              </div>
            );
          })}

        <div className="flex flex-row justify-between text-xs">
          <div className="text-neutraldark2">
            Showing rows {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
            {feedback?.length}
          </div>
          <div className="flex flex-row gap-3 text-orange  items-center">
            <button
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
            >
              <RiIcons.RiArrowLeftSLine />
            </button>

            {getPageRange().map((number) => (
              <button
                key={number}
                className={`${
                  currentPage === number
                    ? "bg-orange text-white h-5 w-5 rounded-full"
                    : ""
                }`}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </button>
            ))}

            <button
              onClick={() =>
                currentPage < pageNumbers.length &&
                handlePageChange(currentPage + 1)
              }
            >
              <RiIcons.RiArrowRightSLine />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {feedback.length > 0 ? (
        <div className="flex flex-col gap-5 p-5 divide-y-2 divide-dashed">
          <DriverRating />
          <FeebackList />
        </div>
      ) : (
        <div className="flex flex-col gap-2 justify-center items-center h-[75vh]">
          <img src={emptyImage} alt="empty" className="h-44 w-44" />
          <div className="flex flex-col items-center">
            <div className="text-neutralblack font-medium text-lg">
              It looks a bit empty here!
            </div>
            <div className="text-neutraldark  text-base">
              Driver’s feedback will appear on this page.
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Feedback;
