import moment from "moment";
import { formatPaymentType } from "Utils/formatPaymentType";
import { formatNumber } from "Utils/formatNumber";

const checkTransactionType = ({ status, type, amount }) => {
  switch (type) {
    case "earnings":
      return (
        <div>
          Earnings{" "}
          <span className="text-successgreen font-sans">
            (+ ₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "transfer":
      return (
        <div>
          Transferred{" "}
          <span className="text-archivered font-sans">
            (- ₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "withdrawal":
      switch (status) {
        case "approved":
          return (
            <div className="capitalize">
              Approved Withdrawal Request{" "}
              <span className="text-archivered font-sans">
                (- ₱{formatNumber(amount?.toFixed(2))})
              </span>
            </div>
          );
        case "pending":
          return (
            <div className="capitalize">
              Pending Withdrawal Request{" "}
              <span className="text-lightgray font-sans">
                (- ₱{formatNumber(amount?.toFixed(2))})
              </span>
            </div>
          );
        case "declined":
          return (
            <div className="capitalize">
              Declined Withdrawal Request{" "}
              <span className="text-successgreen font-sans">
                (+ ₱{formatNumber(amount?.toFixed(2))})
              </span>
            </div>
          );
        case "cancelled":
          return (
            <div className="capitalize">
              Cancelled Withdrawal Request{" "}
              <span className="text-successgreen font-sans">
                (+ ₱{formatNumber(amount.toFixed(2))})
              </span>
            </div>
          );
        default:
          return;
      }
    default:
      return;
  }
};

export const walletCol = [
  {
    dataField: "dateTime",
    text: "Date and Time",
    formatter: (data) => moment(data).format("LLL"),
  },
  {
    dataField: "id",
    text: "ID",
    formatter: (data) => data,
  },
  {
    dataField: "paymentMethod",
    text: "Payment Type",
    formatter: (data) => formatPaymentType(data),
  },
  {
    dataField: "type",
    text: "Transactions",
    formatter: (_data, row) => checkTransactionType(row),
  },
];

const checkTransactionCredit = ({ status, amount }) => {
  switch (status) {
    case "approved":
      return (
        <div>
          Approved Cash In{" "}
          <span className="text-successgreen font-sans">
            (+ ₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "declined":
      return (
        <div>
          Declined Cash In{" "}
          <span className="text-archivered font-sans">
            (₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "cancelled":
      return (
        <div>
          Cancelled{" "}
          <span className="text-archivered font-sans">
            (₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "pending":
      return (
        <div>
          Pending Cash In{" "}
          <span className="text-lightgray font-sans">
            (+ ₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    case "transferred":
      return (
        <div>
          Transferred{" "}
          <span className="text-successgreen font-sans">
            (+ ₱{formatNumber(amount?.toFixed(2))})
          </span>
        </div>
      );
    default:
      break;
  }
};

const commission = ({ amount }) => {
  return (
    <div>
      Commission{" "}
      <span className="text-archivered font-sans">
        (- ₱{formatNumber(amount.toFixed(2))})
      </span>
    </div>
  );
};

export const creditCol = [
  {
    dataField: "dateTime",
    text: "Date and Time",
    formatter: (data) => moment(data).format("LLL"),
  },
  {
    dataField: "transaction_id",
    text: "ID",
    formatter: (data) => data,
  },
  {
    dataField: "amount",
    text: "Transactions",
    formatter: (_data, row) =>
      row.type !== "commission" ? checkTransactionCredit(row) : commission(row),
  },
];
