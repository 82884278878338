import * as React from "react";
import * as RiIcons from "react-icons/ri";

import Passenger from "./Pages/Admin/Passenger/Passenger";
import ServiceType from "./Pages/Admin/ServiceType/ServiceType.js";
import Booking from "./Pages/Admin/Booking/Booking.js";
import Driver from "./Pages/Admin/Driver/Driver.js";
import Credit from "./Pages/Admin/Credit/Credit.js";
import WithdrawRequest from "./Pages/Admin/WithdrawalRequest/WithdrawRequest.js";
import Settings from "./Pages/Admin/Settings/Settings.js";
import CashIn from "./Pages/Admin/CashIn/CashIn.js";
import Map from "./Pages/Admin/Map/Map.js";
import Vehicle from "./Pages/Admin/Vehicle/Vehicle.js";
import Login from "./Pages/Login/Login.js";

import AccountResetPassword from "./Pages/Login/PassReset/AccountResetPassword.js";
import Reports from "./Pages/Admin/Reports/Reports.js";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard.js";
import PassengerActivation from "./Pages/Login/PassengerActivation/PassengerActivation";
import Profile from "./Pages/Admin/Profile/Profile";
import AccountSetPassword from "./Pages/Login/PassReset/AccountSetPassword";
import PromoCode from "./Pages/Admin/PromoCode/PromoCode";

export const PublicRoutes = [
  {
    component: <Login />,
    path: "/login",
  },
  {
    component: <AccountResetPassword />,
    path: "/driver/resetpassword",
  },
  {
    component: <AccountResetPassword />,
    path: "/passenger/resetpassword",
  },
  {
    component: <AccountResetPassword />,
    path: "/admin/resetpassword",
  },
  {
    component: <AccountSetPassword />,
    path: "/driver/setpassword",
  },
  {
    component: <PassengerActivation />,
    path: "/passenger/activate",
  },
];

export const PrivateRoutes = [
  {
    component: <AdminDashboard />,
    title: "Dashboard",
    path: "admin/dashboard",
    svg: <RiIcons.RiFunctionLine size={20} />,
    activesvg: <RiIcons.RiFunctionFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Profile />,
    path: "admin/profile",
    showonsidebar: false,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Passenger />,
    title: "Passenger",
    path: "admin/passenger",
    svg: <RiIcons.RiGroupLine size={20} />,
    activesvg: <RiIcons.RiGroupFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Booking />,
    title: "Booking",
    path: "admin/booking",
    svg: <RiIcons.RiDraftLine size={20} />,
    activesvg: <RiIcons.RiDraftFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Map />,
    title: "Map",
    path: "admin/map",
    svg: <RiIcons.RiMap2Line size={20} />,
    activesvg: <RiIcons.RiMap2Fill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Driver />,
    title: "Driver",
    path: "admin/driver",
    svg: <RiIcons.RiUserLocationLine size={20} />,
    activesvg: <RiIcons.RiUserLocationFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Vehicle />,
    title: "Vehicle",
    path: "admin/vehicle",
    svg: <RiIcons.RiCarLine size={20} />,
    activesvg: <RiIcons.RiCarFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <ServiceType />,
    title: "TODA",
    path: "admin/service-type",
    svg: <RiIcons.RiPriceTag3Line size={20} />,
    activesvg: <RiIcons.RiPriceTag3Fill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <PromoCode />,
    title: "Promo Code",
    path: "admin/promo-code",
    svg: <RiIcons.RiCoupon3Line size={20} />,
    activesvg: <RiIcons.RiCoupon3Fill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <WithdrawRequest />,
    title: "Withdraw Request",
    path: "admin/withdraw-request",
    svg: <RiIcons.RiWallet3Line size={20} />,
    activesvg: <RiIcons.RiWallet3Fill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Credit />,
    title: "Credit",
    path: "admin/credit",
    svg: <RiIcons.RiCopyrightLine size={20} />,
    activesvg: <RiIcons.RiCopyrightFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <CashIn />,
    title: "Cash In",
    subTitle: "(Passenger)",
    path: "admin/cashin",
    svg: <RiIcons.RiCoinsLine size={20} />,
    activesvg: <RiIcons.RiCoinsFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Reports />,
    title: "Reports",
    path: "admin/reports",
    svg: <RiIcons.RiLineChartLine size={20} />,
    activesvg: <RiIcons.RiLineChartFill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
  {
    component: <Settings />,
    title: "Settings",
    path: "admin/settings",
    svg: <RiIcons.RiSettings3Line size={20} />,
    activesvg: <RiIcons.RiSettings3Fill size={20} />,
    showonsidebar: true,
    showonaccountoptions: false,
    isdropdown: false,
    roleAccess: ["admin"],
  },
];
