import create from "zustand";
import { routesGetApi } from "Api/api";
import { APIFetch } from "Utils/timeout";

const serviceObjectDetails = {
  service: [],
};

const apiRequest = async (set) => {
  return await routesGetApi("/servicetype").then((res) => {
    if (res.status === 200) {
      return set({
        service: res.data,
      });
    }
  });
};

const storeService = async (set) => {
  return apiRequest(set);
};

const storeServiceTimeout = async (set) => {
  return APIFetch(() => {
    apiRequest(set);
  });
};

const storeAllService = async (set, data) => {
  return set({
    service: data,
  });
};

const serviceStoreObject = (set) => ({
  ...serviceObjectDetails,
  storeServiceDetails: () => storeService(set),
  fetchServiceType: () => storeServiceTimeout(set),
  getAllService: (data) => storeAllService(set, data),
});

export const serviceStore = create(serviceStoreObject);
