import React from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

function GlobalBoostrapTable(props) {
  const { rows, columns, sizePerPage, search, searchVal, forPrinting } = props;

  return (
    <div>
      <ToolkitProvider
        keyField="dataField"
        data={rows}
        columns={columns}
        search={search}
      >
        {(props) => (
          <div className="">
            {forPrinting ? (
              <BootstrapTable {...props.baseProps} />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                {...props.searchProps.onSearch(searchVal)}
                pagination={paginationFactory({
                  page: 1,
                  sizePerPage: sizePerPage,
                  alwaysShowAllBtns: true,
                  showTotal: true,
                  withFirstAndLast: false,
                  sizePerPageRenderer: ({
                    options,
                    currSizePerPage,
                    onSizePerPageChange,
                  }) => (
                    <div
                      className="dataTables_length"
                      id="datatable-basic_length"
                    ></div>
                  ),
                })}
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default GlobalBoostrapTable;
