import { Routes, Route } from "react-router-dom";
import Sidebar from "./Components/SideBar/Sidebar";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { GetComponentRoutes } from "./Layout/GetComponentRoutes";
import PrivateRoute from "./Api/PrivateRoute";
import withLoading from "./HOC/withLoading";
import React, { memo } from "react";
import Error404Page from "./Layout/Error404Page";
import NoConnnection from "./Layout/NoConnnection";
import Layout from "./Layout/Layout";

const App = () => {
  const isOnline = navigator.onLine ? true : false;

  return (
    <>
      {isOnline ? (
        <Routes>
          <Route path="/" element={<Layout />} />
          {PublicRoutes.map(GetComponentRoutes)}
          <Route element={<PrivateRoute />}>
            <Route element={<Sidebar />}>
              {PrivateRoutes.map(GetComponentRoutes)}
            </Route>
          </Route>
          <Route path="*" element={<Error404Page />} />
        </Routes>
      ) : (
        <NoConnnection />
      )}
    </>
  );
};

export default memo(withLoading(App));
