import { Route } from "react-router-dom";

export const GetComponentRoutes = (item, index) => {
  return (
    <>
      {!item.isdropdown ? (
        <Route path={item.path} key={index} element={item.component} />
      ) : (
        <>
          {item.navigationChild.map((childData, childIndex) => {
            return <>{GetComponentRoutes(childData, childIndex)}</>;
          })}
        </>
      )}
    </>
  );
};
