import React, { useEffect, useState } from "react";
import envelope from "Assets/ActionIcon/envelope.png";
import { routesPostApi, routesPutApi } from "Api/api";
import { alertModalStore } from "../../../Zustand/AlertModalStore/alertModalStore";
import shallow from "zustand/shallow";
function ProfileVerificationLayout({ props, closeOptUI }) {
  const { email, newImage, image, _id } = props ?? {};

  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [emptyFields, setEmptyField] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const { openSuccessModal } = alertModalStore((state) => state, shallow);

  useEffect(() => {
    if (seconds >= 1) {
      setInterval(() => {
        setSeconds((seconds) => (seconds >= 1 ? seconds - 1 : 0));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeOtp = () => {
    setEmptyField([]);
    closeOptUI();
    setOtpValues(["", "", "", "", "", ""]);
  };

  const handleChange = (e, i) => {
    const newValue = e.target.value;
    const newValues = [...otpValues];
    newValues[i] = newValue;
    setOtpValues(newValues);

    if (newValue) {
      const nextInput = e.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    } else {
      const prevInput = e.target.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const validateOTP = () => {
    let errList = [];
    if (otpValues?.length > 0) {
      otpValues?.map((data, index) => {
        const createArr = () => {
          if (data === "" || data === " ") {
            return errList.push(index);
          }
        };

        return createArr();
      });
    }
    setEmptyField(errList);

    if (errList?.length <= 0) return apiFuntion();
  };

  const customBorderOTPField = (value) => {
    if (emptyFields?.length > 0) {
      if (value === "" || value === " ") {
        return "border-red-400";
      } else {
        return "border-gray-400";
      }
    } else {
      return "border-gray-400";
    }
  };

  const apiFuntion = async () => {
    const params = {
      ...props,
      otpCode: otpValues?.join(""),
      image: newImage ?? image,
    };
    await routesPutApi(`/users/${_id}`, params).then(({ status }) => {
      if (status === 201) {
        openSuccessModal({
          title: "Success",
          message: "Your changes has been successfully saved.",
          closeNameBtn: "Back",
        });
        return closeOtp();
      }
    });
  };

  const resendOTP = async () => {
    await routesPostApi("/auth/mailotp", { newEmail: email }).then(() => {
      const alertMessage = {
        title: "Resend code success",
        message: "Please check your email.",
        closeNameBtn: "Back",
      };

      openSuccessModal(alertMessage);
      return setSeconds(60);
    });
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-2xl rounded-lg flex flex-col items-center justify-center p-5 gap-3 lg:gap-10">
        <div className="flex flex-col items-center justify-center lg:gap-2">
          <img src={envelope} alt="envelope" />
          <div className="font-medium text-xl text-neutralblack">
            Verify your Email{" "}
          </div>
          <div className="flex flex-col text-sm items-center">
            <div className="text-darkgray">
              A verification code has been sent
            </div>
            <div className="text-darkgray">
              to <span className="text-customblue">{email}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 relative items-center">
          <div className="flex flex-row gap-1 w-full items-center justify-center">
            {otpValues.map((value, index) => (
              <input
                key={index}
                className={`w-12 h-12  text-center text-gray-700 bg-transparent border-b-2 ${customBorderOTPField(
                  value
                )} focus:outline-none`}
                id={`otp-${index + 1}`}
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </div>
        </div>
        <div>
          <button
            className="gradientBg p-2 text-white rounded-md text-sm"
            onClick={() => validateOTP()}
          >
            Verify Code
          </button>
        </div>
        <div>
          <div className="text-sm">
            Resend code in{" "}
            <span className="text-customblue">
              {seconds !== 0 ? (
                `${seconds}s`
              ) : (
                <span
                  className="underline cursor-pointer"
                  onClick={() => resendOTP()}
                >
                  click here
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileVerificationLayout;
